import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function OnBoardModal(props) {
  const location = useLocation();
  let path = location?.pathname?.split("/");
  const { t } = useTranslation();
  const { onBoardErrors } = useSelector((state) => state.authState);
  const { userlng } = useSelector((state) => state.allCarsState);

  return (
    <Modal
      className="modal2"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <div
        className="d-flex justify-content-end"
        style={{ padding: "21px 21px 0" }}
      >
        <img
          src="../../asset/close-icon.svg"
          onClick={props.close}
          style={{ cursor: "pointer" }}
          alt=""
        ></img>
      </div>
      <div className="popup-content error-modal">
        <Modal.Body>
          <h4 className="text-center">
            {" "}
            {userlng == "de"
              ? onBoardErrors?.message_nl
              : onBoardErrors?.message_en}
          </h4>
          {/* Success messages for users created on EV*/}
          {onBoardErrors?.success_list_ev?.length > 0 && (
            <h5>{t("language.part186")}</h5>
          )}
          <ul>
            {onBoardErrors?.success_list_ev?.map((i) => (
              <li>{i}</li>
            ))}
          </ul>
          {/* Success messages for users created on VS*/}
          {onBoardErrors?.success_list_vs?.length > 0 && (
            <h5>{t("language.part186a")}</h5>
          )}
          <ul>
            {onBoardErrors?.success_list_vs?.map((i) => (
              <li>{i}</li>
            ))}
          </ul>
          {onBoardErrors?.error_list?.length > 0 && (
            <h5>{t("language.part187")}</h5>
          )}
          <ul>
            {onBoardErrors?.error_list?.map((i) => (
              <li>{i}</li>
            ))}
          </ul>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default OnBoardModal;
