import React from "react";
import { useTranslation } from "react-i18next";

export default function LoadMore(props) {
  const {t} = useTranslation()
    return (
    <>
      <div className="col-md-12 load-more text-center">
        <button name="best" onClick={props.loadMore} type="button">
          {t("language.part87")}
        </button>
      </div>
    </>
  );
}
