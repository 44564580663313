import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import auth from "../../helpers/auth";
import { LoginUser } from "../../modules/login";
import { useTranslation } from "react-i18next";
import SHIFT from "../../asset/SHIFT-RGB.png";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

const Login = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let { loginErr } = useSelector((state) => state.authState);

  let { userlng } = useSelector((state) => state.allCarsState);

  const handleChange = (event) => {
    formikRef.current.setFieldValue(event.target.name, event.target.value.trim());
  };

  const validationSchema = yup.object({
    Email: yup
      .string()
      .email(t("validation.v53"))
      .required(t("validation.v52"))
      .max(50, t("validation.v60")),
    Password: yup
      .string()
      .required(t("validation.v54"))
      .max(20, t("validation.v59")),
  });

  const loginDetails = { Email: "", Password: "" };

  const handlePassword = (value) => {
    value = value.replace(" ", "").trim();
    formikRef.current.setFieldValue("Password", value);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      keyboard={false}
      className="login-popup login-modal-white"
      backdrop="static"
    >
      <div className="position-relative popup-inner">
        <div class="text-center">
          <img src={SHIFT} className="shift-img"></img>
        </div>
        <h3>{t("language.part117")}</h3>
        <p>{t("language.part100")}</p>

        <Formik
          innerRef={formikRef}
          initialValues={loginDetails}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await dispatch(LoginUser(values));
            history.push("/all-cars");
            setSubmitting(false);
          }}
        >
          {({ errors, values, touched }) => (
            <Form autoComplete="off">
              <div className="invalid-feedback"></div>
              <div className="form-group">
                <label htmlFor="Username">{t("language.part116")}</label>
                <Input
                  autoComplete="off"
                  placeholder={t("language.part116")}
                  name="Email"
                  id="Email"
                  type="Username"
                  className="form-control mb-0"
                  onChange={(e) => handleChange(e)}
                  value={values.Email}
                  data-testid="email-field"
                />
                {errors.Email && touched.Email && (
                  <small className="text-danger">{errors.Email}</small>
                )}
              </div>
              <div className="form-group mb-0">
                <label htmlFor="Password">{t("language.part1010")}</label>
                <Input
                  autoComplete="off"
                  className="form-control mb-0"
                  name="Password"
                  placeholder={t("language.part1010")}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => handlePassword(e.target.value)}
                  value={values.Password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  data-testid="password-field"
                />
                {errors.Password && touched.Password && (
                  <small className="text-danger">{errors.Password}</small>
                )}
                {loginErr.msg_en && loginErr.msg_en && (
                  <small className="text-danger text-center">
                    {userlng === "de" ? loginErr.msg_nl : loginErr.msg_en}
                  </small>
                )}
              </div>
              <div class="forgot-pass">
                <h3
                  onClick={() => props.showForgotPassword(false)}
                  className="pointer"
                >
                  Wachtwoord vergeten?
                </h3>
              </div>
              <div className="d-flex flex-column align-items-center mt-4">
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  class={
                    values.Email.length && values.Password.length
                      ? `login-btn`
                      : `login-opacity`
                  }
                >
                  {t("language.part129")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="d-flex justify-content-center position-absolute popup-bottom"></div>
      </div>
    </Modal>
  );
};

export default Login;
