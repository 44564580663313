import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import RemoveCar from "./modals/RemoveCar";
import { useTranslation } from "react-i18next";
import AdminCar from "./Common/AdminCar";
import { useDispatch, useSelector } from "react-redux";
import auth from "../helpers/auth";
import { getFiltersOption } from "../modules/allCars";
import { categories_value } from "../utils/commonUtils";
import { fetchMyBestCars, fetchMyStdCars } from "../modules/mycars";
import { parseInt } from "lodash";
import Filter from "./Common/Filter";
import AddNew from "./Common/AddNew";
import LoadMore from "./Common/LoadMore";
import PageSpinner from "./Common/PageSpinner";

const MyCar = (props) => {
  const location = useLocation();

  const [removeCarModal, setRemoveCarModal] = useState(false);
  const [removableCar, setRemovableCar] = useState({});
  let [categories, setCategories] = useState([]);

  // accessing the variables from the state
  let { filterOptions, userlng } = useSelector((state) => state.allCarsState);

  let {
    myBestCars,
    myStdCars,
    myBestCarSpinner,
    mybestPageSpinner,
    myStdPageSpinner,
  } = useSelector((state) => state.myCarState);

  let dispatch = useDispatch();

  // persisting the filter values in state
  const [filters, setFilter] = useState(
    location?.state?.filters || {
      brand: [],
      model: [],
      category: [],
      version: [],
    }
  );

  const { t } = useTranslation();

  // firing mycar api for fething more best cars
  const loadMoreBest = () => {
    let { current_page } = myBestCars;
    current_page = parseInt(current_page) + 1;
    dispatch(fetchMyBestCars({ ...filters, page: current_page }));
  };

  // firing mycar api for fething more standard cars
  const loadMoreStandard = () => {
    let { current_page } = myStdCars;
    current_page = parseInt(current_page) + 1;
    dispatch(fetchMyStdCars({ ...filters, page: current_page }));
  };

  const handleFilterChange = (e, field) => {
    //setting the filter values
    if (field === "category") {
      filters[field] = [e] || [];
      setCategories(e);
    } else if (field === "brand" && !e) {
      filters["model"] = [];
      filters["brand"] = [];
    } else {
      filters[field] = e || [];
    }

    if (!e || e.length === 0) {
      setFilter(filters);
    } else {
      setFilter(filters);
    }

    dispatch(getFiltersOption(filters));
    dispatch(fetchMyBestCars({ ...filters, page: 1 }));
    dispatch(fetchMyStdCars({ ...filters, page: 1 }));
  };

  const handleSearch = (pattern) => {};

  useEffect(async () => {
    // // fetching cars on initial load
    // await dispatch(fetchMyBestCars({ page: 1 }));
    // await dispatch(fetchMyStdCars({ page: 1 }));
  }, []);

  useEffect(async () => {
    await dispatch(fetchMyBestCars({ ...filters, page: 1 }));
    await dispatch(fetchMyStdCars({ ...filters, page: 1 }));
  }, [userlng]);

  return (
    <div className="list-page">
      <div className="main-container">
        <div className="d-flex main1">
          {/* remove car modal */}
          <RemoveCar
            show={removeCarModal}
            handleClose={() => setRemoveCarModal(false)}
            data={removableCar}
          />
          <div className="sidebar">
            <Filter
              categories={categories}
              handleFilterChange={(e, label) => handleFilterChange(e, label)}
              filters={filters}
              filterOptions={filterOptions}
              categories_value={categories_value}
            />
          </div>
          {myBestCarSpinner ? (
            <div
              style={{
                position: "absolute",
                zIndex: "10",
                left: "60%",
                top: "400px",
                height: "400px",
              }}
            >
              <Spinner animation="border" variant="info" />
            </div>
          ) : (
            <div className="car-lists row pb-4">
              <div>
                {" "}
                {myBestCars?.data?.results.length >= 1 && (
                  <h1
                    style={{
                      fontSize: "18px",
                      color: "#00475F",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("language.part48")}
                  </h1>
                )}
              </div>
              {myBestCars?.data?.results?.length == 0 &&
                myStdCars?.data?.results.length === 0 && (
                  <h3 className="text-center m-auto">No cars found</h3>
                )}
              {/* best cars card loop */}
              {myBestCars?.data?.results.map((car, index) => {
                let cover = car?.color?.filter(
                  (i) => i?.images?.length !== 0
                )[0]?.images[0];
                return (
                  <AdminCar
                    car={car}
                    cover={cover}
                    index={index}
                    setRemovableCar={(v) => setRemovableCar(v)}
                    setRemoveCarModal={(e) => setRemoveCarModal(e)}
                  />
                );
              })}
              {/* best car p */}
              {mybestPageSpinner && <PageSpinner />}
              {myBestCars?.current_page < myBestCars?.Total_page &&
                !mybestPageSpinner && <LoadMore loadMore={loadMoreBest} />}

              <div>
                {myStdCars?.data?.results.length >= 1 && (
                  <h1
                    style={{
                      fontSize: "18px",
                      color: "#00475F",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("language.part49")}
                  </h1>
                )}
              </div>
              {/* stnandard car card mapping */}
              {myStdCars?.data?.results.map((car, index) => {
                let cover = car?.color.filter((i) => i?.images.length !== 0)[0]
                  ?.images[0];
                return (
                  <AdminCar
                    car={car}
                    cover={cover}
                    index={index}
                    setRemovableCar={(v) => setRemovableCar(v)}
                    setRemoveCarModal={(e) => setRemoveCarModal(e)}
                  />
                );
              })}
              {/* load more pagination for std cars */}
              {myStdPageSpinner && <PageSpinner />}
              {myStdCars?.current_page < myStdCars?.Total_page &&
                !myStdPageSpinner && (
                  <LoadMore loadMore={() => loadMoreStandard()} />
                )}
              {auth.isAuthenticated && <AddNew />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCar;
