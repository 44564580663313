import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Header } from "../components";
import auth from "../helpers/auth";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  return (
    <>
    <Route
      {...restOfProps}
      render={(props) =>
        auth.isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    /></>
  );
}

export default ProtectedRoute;