import React, { useState } from "react";
import {
  CAR_DETAILS_PATH,
  ALL_CARS_PATH,
  MY_CARS_PATH,
} from "../../constants/routes";
import { Card } from "react-bootstrap";
import { getCategory } from "../../utils/commonUtils";
import { getStringToDisplay } from "../../utils/commonUtils";
import NotFound from "../../asset/image_not_available.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { publishedCar } from "../../modules/mycars";

export default function AdminCar(props) {
  const { car, index, cover } = props;
  const [isMouseInside, setIsMouseInside] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  const showSelectedCarDetails = (selectedCar) => {
    history.push({
      pathname: `${MY_CARS_PATH}${CAR_DETAILS_PATH}/${selectedCar.id}`,
      state: {
        data: selectedCar,
        from: "all-cars",
      },
    });
  };

  const { t } = useTranslation();
  const { userlng } = useSelector((state) => state.allCarsState);

  const mouseEnter = (index) => {
    setIsMouseInside({ [index]: true });
  };

  const mouseLeave = (index) => {
    setIsMouseInside({ [index]: false });
  };

  const publishCar = (selectedCar) => {
    dispatch(publishedCar(selectedCar, { ispublished: true })); // firing publish car api
  };

  const unpublishCar = (selectedCar) => {
    dispatch(publishedCar(selectedCar, { ispublished: false })); // firing publish car api
  };

  return (
    <>
      <div
        key={index}
        className="col-md-4"
        style={{ paddingRight: "5px", paddingLeft: "5px" }}
      >
        <div className="list-card-outer card-list" key={index}>
          <Card
            className={`${car.ispublished ? "" : "not-published"} list-card`}
            onMouseEnter={() => mouseEnter(index)}
            onMouseLeave={() => mouseLeave(index)}
          >
            <div style={{ height: "200px", cursor: "pointer" }}>
              <img
                onClick={() => showSelectedCarDetails(car)}
                lt={car.model}
                className="img-fluid"
                src={cover ? cover : NotFound}
                loading='lazy'
              ></img>
            </div>

            <div className="my-list-card-inner">
              {userlng === "en" ? (
                <h4 style={{ marginBottom: "0px" }}>
                  {getStringToDisplay(
                    car.brand + " " + car.model + "  " + car.version,
                    21,
                    true
                  )}
                </h4>
              ) : (
                <h4 style={{ marginBottom: "0px" }}>
                  {getStringToDisplay(
                    car.brand + " " + car.model + "  " + car.version,
                    21,
                    true
                  )}
                </h4>
              )}

              <div className="price">
                <div className="row mt-0">
                  <div className="col-6">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part4")}
                    </p>
                    <span>
                      {car.wltp_range ? car.wltp_range + " km" : "NA"}
                    </span>
                  </div>
                  <div className="col-6">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part5")}
                    </p>
                    <span>
                      {car.additional_percentage
                        ? car.additional_percentage + " %"
                        : "n.t.b."}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part6")}
                    </p>
                    <span>
                      {car.battery_capacity
                        ? car.battery_capacity + " kWh"
                        : "NA"}
                    </span>
                  </div>

                  <div className={userlng === "en" ? "col-6" : "col-6 "}>
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part7")}
                    </p>
                    <span>{car.tax_value ? "€ " + car.tax_value : "NA"}</span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      userlng === "en"
                        ? "col-6 expected_delivery_time"
                        : "col-6 expected-d-6"
                    }
                  >
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part8")}
                    </p>
                    {car.expected_delivery_time ? (
                      <span>{car.expected_delivery_time} </span>
                    ) : (
                      <span>{"N.N.B"}</span>
                    )}
                  </div>
                  <div className={userlng === "en" ? "col-6" : "col-6 "}>
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part9")}
                    </p>
                    <span>{getCategory(car.category) || "NA"}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pr-0">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part43")}
                    </p>
                    <span>{car.version || "NA"}</span>
                  </div>
                </div>
                {isMouseInside[index] && (
                  <div
                    className="hover-buttons d-flex flex-row justify-content-between "
                    style={{ top: "25px", bottom: "0px" }}
                  >
                    <Link to="#">
                      <button
                        onClick={() => {
                          props.setRemoveCarModal(true);
                          props.setRemovableCar(car);
                        }}
                        className="ask-button mt-2"
                        style={{
                          width: "110px",
                          backgroundColor: "blue",
                          border: "1px solid #00475F",
                        }}
                      >
                        {t("language.part70")}
                      </button>
                    </Link>

                    {!car.ispublished && (
                      <button
                        className="ask-button mt-2"
                        style={{ width: "110px" }}
                        onClick={() => publishCar(car)}
                      >
                        {t("language.part68")}
                      </button>
                    )}
                    {car.ispublished && (
                      <button
                        className="book-button mt-2"
                        style={{ width: "110px" }}
                        onClick={() => unpublishCar(car)}
                      >
                        {t("language.part69")}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
