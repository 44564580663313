import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import icon from "../asset/icon.svg";
import icon1 from "../asset/icon1.svg";
import disableleft from "../asset/disable-left.svg";
import disableright from "../asset/disable-right.svg";
import { fetchMyUsers, fetchUser, exportUsers } from "../modules/myusers";
import AddNew from "./Common/AddNew";
import auth from "../helpers/auth";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MyUsers = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useLocation().search;
  const [searchQuery, setSearchQuery] = useState("");
  let {
    myUsers,
    myUsers: { current_page, total_pages },
    myUsersSpinner,
  } = useSelector((state) => state.myUserState);
  const dispatch = useDispatch();

  async function onSearchSubmit(e) {
    e.preventDefault();
    await dispatch(fetchMyUsers({ value: 1, searchQuery }));
    history.push({
      pathname: "/my-users",
      search: `?page=${1}${searchQuery ? `&search=${searchQuery}` : ""}`,
    });
  }

  async function goToPage(value) {
    await dispatch(fetchMyUsers({ value, searchQuery }));
  }

  function getPages() {
    let pages = [],
      temp = [];
    current_page = parseInt(current_page);
    total_pages = parseInt(total_pages);
    if (total_pages <= 5) {
      pages = Array.from({ length: total_pages }, (_, i) => i + 1);
      return pages;
    }
    for (let i = current_page - 2; i < current_page + 4; i++) {
      pages.push(i);
    }

    if (pages[0] < 1) {
      let lastelemet = pages[pages.length - 1] + (1 - pages[0]);
      pages = [];
      for (let i = 1; i <= lastelemet; i++) {
        pages.push(i);
      }
      return pages;
    }
    if (pages[pages.length - 1] > total_pages) {
      for (
        let i = pages[0] - (pages[pages.length - 1] - total_pages);
        i <= total_pages;
        i++
      ) {
        temp.push(i);
      }
      return temp;
    }
    return pages;
  }

  const getJoinedDate = (date) => {
    let val = new Date(date);
    return (
      monthNames[val.getMonth()] +
      " " +
      val.getUTCDate() +
      ", " +
      val.getUTCFullYear()
    );
  };

  useEffect(() => {
    history.push({
      pathname: "/my-users",
      search: `?page=${current_page}${
        searchQuery ? `&search=${searchQuery}` : ""
      }`,
    });
  }, [current_page]);

  useEffect(async () => {
    const page = new URLSearchParams(params).get("page");
    await dispatch(fetchMyUsers({ value: page }));
  }, []);

  return (
    <>
      {console.log(searchQuery)}
      <div className="myuser_main">
        <h3>{t("language.part144")}</h3>
        <div className="table_main">
          <form
            onSubmit={(e) => onSearchSubmit(e)}
            className="form-inline d-flex justify-content-between"
            style={{ width: "98%" }}
          >
            <div className="form-group  search_bar ">
              <label htmlFor="inputPassword2" className="sr-only">
                {t("language.part146")}
              </label>
              <input
                type="text"
                className="form-control"
                id="inputPassword2"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={`${t("language.part146")}`}
              />
              <button type="submit" className="btn3 mb-2">
                {t("language.part146")}
              </button>
            </div>
            <button
              type="button"
              className="export-btn btn"
              onClick={() => dispatch(exportUsers(searchQuery))}
            >
              {t("language.part188")}
            </button>
          </form>
          {myUsersSpinner ? (
            <div class="text-center myuser-spinner">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : myUsers?.user_list?.results.length ? (
            <div className="table-responsive">
              <table className="table table-borderless table_head ">
                <thead>
                  <tr>
                    <th scope="col">{t("language.part56")}</th>
                    <th scope="col">{t("language.part58")}</th>
                    <th scope="col">{t("language.part147")}</th>
                    <th scope="col">{t("language.part_role")}</th>

                    <th scope="col">{t("language.part153")}</th>
                    <th scope="col">{t("language.part154")}</th>
                    <th scope="col">{t("language.part155")}</th>
                  </tr>
                </thead>
                <tbody>
                  {myUsers?.user_list?.results.map((i) => (
                    <tr>
                      <td>{i?.first_name || i?.username.split("@")[0]}</td>
                      <td className="td-color">
                        <Link to={`/my-users/user/${i?.id}`}>{i?.email}</Link>
                      </td>
                      <td>{i?.entity || "NA"}</td>
                      <td>{i?.user_config[0].role || "NA"}</td>

                      <td>
                        <button
                          style={{ width: "95px" }}
                          type="button"
                          className="contract-btn"
                        >
                          {i?.agreement == "NA"
                            ? "NA"
                            : t(`language.${i?.agreement}`)}
                        </button>
                      </td>
                      <td>{getJoinedDate(i?.created_at)}</td>
                      <td>
                        <button
                          style={{ width: "115px" }}
                          type="button"
                          className={
                            i?.status == "Active"
                              ? "status-btn"
                              : i?.status == "Inactive"
                              ? "status-btn status-btn1"
                              : "contract-btn contract-btn1"
                          }
                        >
                          {i?.status}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div class="text-center myuser-spinner">
              <div class="text-primary" role="status">
                No users found
              </div>
            </div>
          )}

          {myUsers?.user_list?.results && total_pages > 1 && (
            <div className="d-flex justify-content-end pr-2 myuser_page mt-3">
              <nav aria-label="...">
                <ul className="pagination">
                  <li className="page-item btn4">
                    <a
                      className={
                        current_page === "1" ? "page-link disable" : "page-link"
                      }
                      onClick={() => goToPage(parseInt(current_page) - 1)}
                    >
                      {" "}
                      <img
                        src={current_page === "1" ? disableleft : icon}
                        className="pr-2 arrow-icon"
                      ></img>
                      {t("language.part151")}
                    </a>
                  </li>
                  {getPages().map((i) => (
                    <li className="page-item">
                      <li className="page-i tem">
                        <a
                          className={
                            parseInt(myUsers.current_page) !== i
                              ? "page-link"
                              : "current page-link"
                          }
                          onClick={() => goToPage(i)}
                        >
                          {i}
                        </a>
                      </li>
                    </li>
                  ))}
                  <li className="page-item btn4">
                    <a
                      className={
                        current_page == total_pages
                          ? "page-link disable"
                          : "page-link"
                      }
                      onClick={() => goToPage(parseInt(current_page) + 1)}
                    >
                      {t("language.part152")}
                      <img
                        src={current_page == total_pages ? disableright : icon1}
                        className="pl-2 arrow-icon"
                      ></img>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          {auth.isAuthenticated && <AddNew />}
        </div>
      </div>
    </>
  );
};

export default MyUsers;
