import React, { useEffect } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";

// Global component to scroll the user to top on url change
export function ScrollToTop(params) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <></>;
}

export default ScrollToTop;
