import axios from "axios";
import apiservice from "../api/apiInstance";
import { fetchStandardCar, setAlertMessage, setNLMessage } from "./allCars";

export const SET_MY_USERS = "SET_MY_USERS";
export const SET_MY_USER_DETAILS = "SET_MY_USER_DETAILS";
export const SET_MY_USERS_SPINNER_STATUS = "SET_MY_USERS_SPINNER_STATUS";
export const SET_USER_DETAIL_SPINNER_STATUS = "SET_USER_DETAIL_SPINNER_STATUS";
export const SET_FETCH_USERS_SPINNER_STATUS = "SET_FETCH_USERS_SPINNER_STATUS";

export function setUserDetailSpinner(flag) {
  return {
    type: SET_USER_DETAIL_SPINNER_STATUS,
    payload: flag,
  };
}

export function setMyUsersSpinner(flag) {
  return {
    type: SET_MY_USERS_SPINNER_STATUS,
    payload: flag,
  };
}

export function fetchUserSpinner(flag) {
  return {
    type: SET_FETCH_USERS_SPINNER_STATUS,
    payload: flag,
  };
}

export function setMyUsers(params) {
  return {
    type: SET_MY_USERS,
    payload: params,
  };
}

export function setMyUserDetails(params) {
  console.log(params?.year_mileage);
  return {
    type: SET_MY_USER_DETAILS,
    payload: params,
  };
}
// http://ec2-18-197-147-6.eu-central-1.compute.amazonaws.com:8080
export function fetchMyUsers(data) {
  console.log(data);
  return async (dispatch, getState) => {
    await dispatch(setMyUsersSpinner(true));
    try {
      const response = await apiservice.get(`/admin/users`, {
        params: {
          page: data.value,
          search: data?.searchQuery || "",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        await dispatch(setMyUsers(response.data));
        await dispatch(setMyUsersSpinner(false));
      } else {
        await dispatch(setMyUsersSpinner(false));
      }
    } catch (e) {
      await dispatch(setMyUsersSpinner(false));
      console.log(e);
    }
  };
}

export function removeUser(id, history) {
  return async (dispatch, getState) => {
    try {
      const response = await apiservice.delete(`/admin/user_details`, {
        params: {
          id: id,
        },
      });
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setAlertMessage(response.data.message_en, "success"));
        await dispatch(setNLMessage(response.data.message_nl, "success"));
        await history.push("/my-users");
      } else {
        await dispatch(setAlertMessage(response.data.message_en, "error"));
        await dispatch(setNLMessage(response.data.message_nl, "error"));
        await history.push("/my-users");
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Internal server error", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 4000);
  };
}

export function fetchUser(id) {
  return async (dispatch, getState) => {
    await dispatch(setUserDetailSpinner(true));
    try {
      const response = await apiservice.get(`/admin/user_details`, {
        params: {
          user_id: id,
        },
      });
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setMyUserDetails(response.data.data));
        console.log("yes");
      } else {
        await dispatch(setNLMessage(response.data.message, "error"));
        await dispatch(setAlertMessage(response.data.message_en, "error"));
      }
      await dispatch(setUserDetailSpinner(false));
    } catch (e) {
      console.log(e);
      await dispatch(setUserDetailSpinner(false));
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 4000);
  };
}

export function editUser(data, history) {
  data.approval_date = data.approval_date || null;
  data.end_date_lease_contract = data.end_date_lease_contract || null;

  return async (dispatch, getState) => {
    try {
      const response = await apiservice.patch(
        `/admin/user_details`,
        JSON.stringify(data)
      );
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setAlertMessage("User updated successfully", "success"));
        await dispatch(
          setNLMessage("Gebruiker succesvol geüpdatet", "success")
        );
        await history.goBack();
      } else {
        Object.keys(response?.data?.data).map(async (i) => {
          await dispatch(setAlertMessage(response.data.data[i][0], "error"));
          await dispatch(setNLMessage(response.data.data[i][0], "error"));
        });
        await history.goBack();
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Internal server error", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 5000);
  };
}

export function exportUsers(query) {
  return async (dispatch, getState) => {
    let { userlng } = getState().allCarsState;
    try {
      const response = await apiservice.get(`/admin/users/export`, {
        headers: {
          "x-source": "ev",
          language: userlng == "en" ? "en" : "nl",
        },
        params: {
          search: query,
        },
      });
      console.log(response.data);
      const anchor = document.createElement("a");
      anchor.href = response.data.file;
      anchor.download = "users";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Internal server error", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 5000);
  };
}

export const initialState = {
  myUsers: {current_page:1},
  myUserDetails: {},
  myUserDetailSpinner: false,
  myUsersSpinner: false,
};

const ACTION_HANDLERS = {
  [SET_MY_USER_DETAILS]: (state, action) => {
    action.payload["personal_number"] = parseInt(
      action.payload["personal_number"]
    );
    action.payload["file"] = parseInt(action.payload["file"]);
    return {
      ...state,
      myUserDetails: action.payload,
    };
  },
  [SET_FETCH_USERS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      fetchUserSpinner: action.payload,
    };
  },
  [SET_USER_DETAIL_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      myUserDetailSpinner: action.payload,
    };
  },
  [SET_MY_USERS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      myUsersSpinner: action.payload,
    };
  },
  [SET_MY_USERS]: (state, action) => {
    return {
      ...state,
      myUsers: action.payload,
    };
  },
};

export default function myUsersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
