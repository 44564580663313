import auth from "../helpers/auth";
import {
  setAlertMessage,
  setChangePwdModal,
  setImportUsers,
  setLoginModal,
  setUploadSpinner,
  setNLMessage,
  fetchBestCar,
  fetchStandardCar,
} from "./allCars";
import apiservice from "../api/apiInstance";
import axios from "axios";

export const AUTH_VIEW_SET_USER_DATA = "AUTH_VIEW_SET_USER_DATA";
export const AUTH_SET_LOGIN_SPINNER_STATUS = "AUTH_SET_LOGIN_SPINNER_STATUS";
export const AUTH_SET_LOGIN_DATA = "AUTH_SET_LOGIN_DATA";
export const AUTH_SET_LOGIN_ERR_MSG = "AUTH_SET_LOGIN_ERR_MSG";
export const SET_CHANGE_PASSWORD_ERROR = "SET_CHANGE_PASSWORD_ERROR";
export const SET_FORGOT_PASSWORD_ERROR = "SET_FORGOT_PASSWORD_ERROR";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_NEW_PASSWORD_ERROR = "SET_NEW_PASSWORD_ERROR";
export const SET_ONBOARD_ERRORS_MODAL = "SET_ONBOARD_ERRORS_MODAL";

export const SET_ON_BOARD_ERRORS = "SET_ON_BOARD_ERRORS";

export function setLoginErr(msg_en, msg_nl) {
  return {
    type: AUTH_SET_LOGIN_ERR_MSG,
    payload: { msg_en, msg_nl },
  };
}

export function setUserData(data) {
  return {
    type: AUTH_VIEW_SET_USER_DATA,
    payload: data,
  };
}

export function setLoginSpinner(value) {
  return {
    type: AUTH_SET_LOGIN_SPINNER_STATUS,
    payload: value,
  };
}

export function setLoginData(data) {
  return {
    type: AUTH_SET_LOGIN_DATA,
    payload: data,
  };
}

export function setPasswordErr(msg_en, msg_nl) {
  return {
    type: SET_CHANGE_PASSWORD_ERROR,
    payload: { msg_en, msg_nl },
  };
}

export function setForgotPasswordErr(msg_en, msg_nl) {
  return {
    type: SET_FORGOT_PASSWORD_ERROR,
    payload: { msg_en, msg_nl },
  };
}

export function setNewPasswordErr(msg_nl) {
  return {
    type: SET_NEW_PASSWORD_ERROR,
    payload: msg_nl,
  };
}

export function setOnBoardErrModal(params) {
  return {
    type: SET_ONBOARD_ERRORS_MODAL,
    payload: params,
  };
}

export function setOnBoardErrors(params) {
  return {
    type: SET_ON_BOARD_ERRORS,
    payload: params,
  };
}

export function LoginUser(data) {
  return async (dispatch, getState) => {
    await dispatch(setLoginSpinner(true));
    try {
      const result = await fetch(
        `${process.env.REACT_APP_GATEWAY_URL__}/login`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      let response = await result.json();
      if (response.code == 200) {
        await dispatch(setLoginSpinner(false));
        await auth.setTokens(response.AccessToken);
        await auth.setRefreshToken(response.RefreshToken);
        await dispatch(setLoginModal(false));
        await dispatch(fetchBestCar({ page: 1 }));
        await dispatch(fetchStandardCar({ page: 1 }));
        await dispatch(getUserDetails());
      } else {
        await dispatch(setLoginErr(response.message_en, response.message_nl));
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Interne Server Fout", "error"));
      await dispatch(setLoginSpinner(false));
      console.log(e);
    }
    setTimeout(() => {
      dispatch(setLoginErr("", ""));
    }, 3000);
  };
}

export function ChangePassword(data) {
  return async (dispatch, getState) => {
    await dispatch(setLoginErr(true));
    try {
      const response = await apiservice.post(
        `/change-password/`,
        JSON.stringify(data)
      );
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setLoginSpinner(false));
        await dispatch(setChangePwdModal(false));
        await dispatch(setAlertMessage(response.data.message_en, "success"));
        await dispatch(setNLMessage(response.data.message_nl, "success"));
      } else {
        await dispatch(setLoginSpinner(false));
        await dispatch(
          setPasswordErr(response.data.message_en, response.data.message_nl)
        );
      }
    } catch (e) {
      await dispatch(setChangePwdModal(false));
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Interne Server Fout", "error"));
      await dispatch(setLoginSpinner(false));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setPasswordErr("", ""));
      await dispatch(setNLMessage("", ""));
    }, 4000);
  };
}

export function CreateUsers(file) {
  let form_data = new FormData();
  form_data.append("file", file);
  return async (dispatch, getState) => {
    await dispatch(setUploadSpinner(true));
    try {
      const response = await apiservice.post(`/onboard-users/`, form_data, {
        headers: {
          Accept: "*/*",
        },
      });
      if (response.status == 201 && response.data.code == 201) {
        await dispatch(setUploadSpinner(false));
        await dispatch(setImportUsers(false));
      } else {
        await dispatch(setUploadSpinner(false));
        await dispatch(setImportUsers(false));
        // await dispatch(setAlertMessage(response.data.message_en, "error"));
        // await dispatch(setNLMessage(response.data.message_nl, "error"));
      }
      if (response.data.success_list_ev || response.data.error_list) {
        await dispatch(setOnBoardErrors(response?.data));
        await dispatch(setOnBoardErrModal(true));
      }
    } catch (e) {
      await dispatch(setImportUsers(false));
      await dispatch(setUploadSpinner(false));
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Interne Server Fout", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 4000);
  };
}

export function forgotPassword(data) {
  return async (dispatch, getState) => {
    await dispatch(setForgotPasswordErr(true));
    try {
      const response = await apiservice.post(
        `/forgot-password`,
        JSON.stringify(data),
        {
          headers: { "x-source": "ev" },
        }
      );
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setAlertMessage(response.data.message_en, "success"));
        await dispatch(setNLMessage(response.data.message_nl, "success"));
      } else {
        await dispatch(
          setForgotPasswordErr(
            response.data.message_en,
            response.data.message_nl
          )
        );
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Interne Server Fout", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
      await dispatch(setForgotPasswordErr("", ""));
    }, 4000);
  };
}

export function setNewPassword(data) {
  return async (dispatch, getState) => {
    await dispatch(setNewPasswordErr(true));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GATEWAY_URL__}/forgot-password/confirm-password`,
        JSON.stringify(data.values),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setAlertMessage(response.data.message_en, "success"));
        await dispatch(setNLMessage(response.data.message_nl, "success"));
        await dispatch(setLoginModal(true));
        data.history.push("/all-cars");
      } else {
        await dispatch(setNewPasswordErr(response.data.message_nl));
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Interne Server Fout", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
      await dispatch(setNewPasswordErr(""));
    }, 4000);
  };
}

export function getUserDetails(data) {
  return async (dispatch, getState) => {
    try {
      const response = await apiservice.post(
        `/user-details`,
        JSON.stringify(data)
      );
      if (response.status === 200 && response.data.code === 200) {
        if (response?.data?.data?.status==='Out of process') {
          await auth.resetLoginStore();
        }
        await auth.setUserType(response.data?.data?.role);
        await auth.setUsername(response?.data?.data?.full_name);
        await dispatch(setUserData(response.data.data));
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Interne Server Fout", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 4000);
  };
}

export const initialState = {
  user_data: {},
  loginSpinner: false,
  loginData: {
    username: "",
    password: "",
  },
  loginErr: {},
  passwordErr: {},
  forgotPasswordErr: {},
  newPasswordErr: "",
  onBoardErrModal: false,
  onBoardErrors: {},
};

const ACTION_HANDLERS = {
  [AUTH_VIEW_SET_USER_DATA]: (state, action) => {
    return {
      ...state,
      user_data: action.payload,
    };
  },
  [AUTH_SET_LOGIN_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      loginSpinner: action.payload,
    };
  },
  [AUTH_SET_LOGIN_DATA]: (state, action) => {
    return {
      ...state,
      loginData: action.payload,
    };
  },
  [AUTH_SET_LOGIN_ERR_MSG]: (state, action) => {
    return {
      ...state,
      loginErr: action.payload,
    };
  },
  [SET_CHANGE_PASSWORD_ERROR]: (state, action) => {
    return {
      ...state,
      passwordErr: action.payload,
    };
  },
  [SET_FORGOT_PASSWORD_ERROR]: (state, action) => {
    return {
      ...state,
      forgotPasswordErr: action.payload,
    };
  },
  [SET_NEW_PASSWORD_ERROR]: (state, action) => {
    return {
      ...state,
      newPasswordErr: action.payload,
    };
  },
  [SET_ONBOARD_ERRORS_MODAL]: (state, action) => {
    return {
      ...state,
      onBoardErrModal: action.payload,
    };
  },
  [SET_ON_BOARD_ERRORS]: (state, action) => {
    return {
      ...state,
      onBoardErrors: action.payload,
    };
  },
};

export default function loginReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
