import React, { useState, useRef, useEffect } from "react";
import "../styles/agreement.css";
import headerImg from "../asset/header.png";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseModal from "./modals/CloseModal";
import {
  sendEmail,
  setChangePwdModal,
  setThankyouModal,
  getUserContribution,
} from "../modules/allCars";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import { getUserDetails } from "../modules/login";
import { Toast } from "react-bootstrap";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function Agreement() {
  const location = useLocation();
  let { data } = location?.state || {};
  const sigCanvas = useRef({});
  const [sign, setSignURL] = useState("");
  const [showpad, setShowPad] = useState(true);
  const [signErr, setSignErr] = useState("");
  const [plaats, setPlaats] = useState("");

  let { user_data } = useSelector((state) => state.authState);
  let {
    thankyoumodal,
    userlng,
    user_contribution,
    alertMessage,
    nl_message,
    contribution_spinner,
    ageement_spinner,
  } = useSelector((state) => state.allCarsState);

  let history = useHistory();
  let dispatch = useDispatch();
  const { t } = useTranslation();

  let date = new Date();

  // func for get back to previous page
  function goBack() {
    history.goBack();
  }

  // onSubmit func for sending values to the api
  function onSubmit() {
    let payload = {
      // creating the payload
      car_id: data?.car_id,
      address: user_data?.address || "NA",
      additional_percentage:
        (data.additional_percentage && data.additional_percentage.toString()) ||
        "NA",
      body: "Metalic",
      bettery_capacity: data?.battery_capacity || "NA",
      expected_delivery_time: data?.expected_delivery_time || "",
      brand: data.brand,
      model: data.model,
      category: data?.category.toString() || "NA",
      charging: data?.charging.toString()
        ? t(`language.${data?.charging.toString()}`)
        : "No",
      choosed_option_sum: getTotal(),
      total: getTotal() + user_contribution,
      color: data.color,
      description: "",
      version: data.version || "NA",
      wltp_range: (data.wltp_range && data.wltp_range.toString()) || "NA",
      tax_value: (data.tax_value && data.tax_value.toString()) || "NA",
      name: data?.name,
      email: data?.email,
      mobile_number: data?.mobile_number,
      iservice_job_number: data?.iservice_job_number,
      personnel_number: data?.personnel_number.toString(),
      pincode: user_data?.postcode || "",
      fuel: "Elektrisch",
      annual_mileage: data?.wltp_range,
      choosable_options: data.choosable_options,
      signature: sign,
      contribution: user_contribution,
      lng: userlng,
      plaats: user_data?.city || "NA",
      client_date: `${date.getUTCDate()}${" "}
      ${monthNames[date.getMonth()]}${" "}
      ${date.getUTCFullYear()}`,
    };
    console.log(payload);
    dispatch(sendEmail(payload)); // firing the api
  }

  // closing the thankyu modal
  function closeModal() {
    localStorage.setItem("temp-lng", userlng);
    dispatch(setThankyouModal(false));
    history.push("/all-cars");
  }

  // total of choosen options by user
  function getTotal() {
    let sum = 0;
    data?.options.map((i) => (sum = sum + i.option_value));
    return sum;
  }

  const clear = () => sigCanvas.current.clear();

  // saving the sign image
  const saveSign = () => {
    if (sigCanvas.current.isEmpty()) {
      setSignErr("Please sign in above area");
    } else {
      setSignURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
      setShowPad(false);
    }
    setTimeout(() => {
      setSignErr("");
    }, 4000);
  };

  useEffect(() => {
    dispatch(getUserDetails());
    return async () => {
      await dispatch(setThankyouModal(false));
    };
  }, []);

  if (!location?.state) {
    console.log("yes");
    return history.goBack();
  }

  const getFloat = (value) => {
    return parseFloat(value).toFixed(2);
  };

  console.log(data);

  if (location?.state) {
    return (
      <>
        {thankyoumodal && (
          <CloseModal
            show={thankyoumodal}
            handleClose={() => {
              closeModal();
            }}
          />
        )}
        {alertMessage.message && userlng === "en" && (
          <Toast
            className={`toast ${alertMessage.status}-toast`}
            // onClose={}
            show={true}
            delay={3000}
            autohide
          >
            <Toast.Body>{alertMessage.message}</Toast.Body>
          </Toast>
        )}
        {nl_message.message && userlng === "de" && (
          <Toast
            className={`toast ${nl_message.status}-toast`}
            // onClose={}
            show={true}
            delay={3000}
            autohide
          >
            <Toast.Body>{nl_message.message}</Toast.Body>
          </Toast>
        )}
        {contribution_spinner ? (
          "Loading"
        ) : (
          <table
            width="100%"
            max-width="100%"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            bgcolor="#ffffff"
            style={{
              borderCollapse: "collapse",
              msoTableLspace: "0pt",
              msoTableRspace: "0pt",
              background: "#ffffff",
            }}
          >
            <tbody>
              <tr>
                <td>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      msoTableLspace: "0pt",
                      msoTableRspace: "0pt",
                      border: "1px solid #bcb5b9",
                    }}
                    className="full_width"
                    width="100%"
                    max-width="100%"
                    border={0}
                    cellPadding={0}
                    align="left"
                    cellSpacing={0}
                    bgcolor="#ffffff"
                  >
                    <tbody>
                      <tr>
                        <td
                          bgcolor="#6700e4"
                          style={{ backgroundColor: "white" }}
                        >
                          <table
                            width="100%"
                            max-width="100%"
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            align="left"
                            style={{
                              borderCollapse: "collapse",
                              msoTableLspace: "0pt",
                              msoTableRspace: "0pt",
                              border: 0,
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  height={20}
                                  width="100%"
                                  max-width="100%"
                                  style={{ height: "20px" }}
                                />
                              </tr>
                              <tr>
                                <td
                                  align="center"
                                  style={{
                                    textAlign: "center",
                                    padding: "0px 20px 0px 20px",
                                  }}
                                >
                                  <table
                                    max-width="100%"
                                    border={0}
                                    cellPadding={0}
                                    cellSpacing={0}
                                    align="center"
                                    style={{
                                      borderCollapse: "collapse",
                                      msoTableLspace: "0pt",
                                      msoTableRspace: "0pt",
                                      border: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          align="left"
                                          style={{
                                            maxWidth: "100%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <img
                                            src={headerImg}
                                            alt="logo"
                                            title="logo"
                                            border={0}
                                            style={{
                                              color: "#ffffff",
                                              maxWidth: "100%",
                                              width: "288px",
                                              height: "auto",
                                              fontFamily: "Arial",
                                              fontSize: "22px",
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  height={20}
                                  width="100%"
                                  max-width="100%"
                                  style={{ height: "20px" }}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          bgcolor="#1b2250"
                          style={{ backgroundColor: "white" }}
                        >
                          <table
                            width="100%"
                            max-width="100%"
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            align="center"
                            style={{
                              borderCollapse: "collapse",
                              msoTableLspace: "0pt",
                              msoTableRspace: "0pt",
                              border: 0,
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  height={25}
                                  width="100%"
                                  max-width="100%"
                                  style={{ height: "25px" }}
                                />
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  className="table-padding"
                                  style={{ textAlign: "center" }}
                                >
                                  <table
                                    border={0}
                                    cellPadding={0}
                                    cellSpacing={0}
                                    align="center"
                                    style={{
                                      borderCollapse: "collapse",
                                      msoTableLspace: "0pt",
                                      msoTableRspace: "0pt",
                                      border: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          align="left"
                                          className=" head"
                                          style={{
                                            fontFamily:
                                              "AzoSans-Regular !important",
                                            fontWeight: "bold",

                                            color: "#00475F",
                                            fontSize: "38px",
                                            textAlign: "center",
                                            maxWidth: "100%",
                                            width: "100% !important",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          GEBRUIKERSOVEREENKOMST LEASE AUTO{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  height={25}
                                  width="100%"
                                  style={{ height: "25px" }}
                                  max-width="100%"
                                />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height={16} style={{ height: "16px" }} />
                      </tr>
                      <tr>
                        <td
                          align="left"
                          className="table-padding"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            color: "#231f20",
                            fontSize: "16px",
                            lineHeight: "normal",
                            textAlign: "left",
                            fontWeight: "normal",
                          }}
                        >
                          <table
                            width="100%"
                            max-width="100%"
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "0px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "20px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Ondergetekenden :{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "10px 60px",
                                    fontFamily: "Arial !important",
                                    lineHeight: "24px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "AzoSans-Regular !important",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    1. Werkgever:
                                  </span>{" "}
                                  Randstad Groep Nederland B.V., hierna te
                                  noemen “werkgever”{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 0px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "20px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  En{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "10px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  2. Werknemer:{" "}
                                </td>
                              </tr>
                              <tr
                                style={{ display: "flex", paddingTop: "25px" }}
                              >
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {" "}
                                  Naam:
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {user_data?.full_name || "NA"}
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Adres:
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                    textAlign: "left",
                                  }}
                                >
                                  {user_data?.address || "NA"}
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Woonplaats en Postcode:
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {user_data?.city + " " + user_data?.postcode}
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Personeelsnummer:
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {user_data?.personal_number || "NA"}{" "}
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Categorie:{" "}
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {user_data?.category || "NA"}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "25px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Hierna te noemen “werknemer”{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "20px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Verklaren{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "10px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  a. Aan werknemer wordt door werkgever voor
                                  uitoefening van zijn voormelde functie de
                                  volgende auto tot wederopzegging ter
                                  beschikking gesteld:{" "}
                                </td>
                              </tr>
                              <tr
                                style={{ display: "flex", marginTop: "20px" }}
                              >
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Merk / Type:{" "}
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {data?.brand + " " + data?.model}{" "}
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Versie:{" "}
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {data?.version || "NA"}
                                </td>
                              </tr>{" "}
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Kleur:
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {data?.color?.name}
                                </td>
                              </tr>{" "}
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Brandstof :{" "}
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Elektrisch{" "}
                                </td>
                              </tr>{" "}
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Jaarkilometrage:
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {user_data?.year_mileage || "NA"}
                                  {user_data?.year_mileage && " km"}
                                </td>
                              </tr>{" "}
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Fiscale waarde:
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {data?.fiscal_waarde && "€"}{" "}
                                  {data?.fiscal_waarde || "NA"}
                                  {data?.fiscal_waarde && ",-"}{" "}
                                </td>
                              </tr>{" "}
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Lease category:
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {data?.category}{" "}
                                </td>
                              </tr>
                              {!data?.options?.length && (
                                <tr style={{ display: "flex" }}>
                                  <td
                                    align="left"
                                    className="td-padding"
                                    style={{
                                      padding: "5px 20px",
                                      fontFamily: "AzoSans-Regular !important",
                                      lineHeight: "24px",
                                      wordBreak: "break-word",
                                      fontSize: "24px",
                                      color: "#00475F",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    Gekozen opties:
                                  </td>
                                  <td
                                    align="left"
                                    style={{
                                      padding: "5px 20px",
                                      fontFamily: "AzoSans-Regular !important",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      wordBreak: "break-word",
                                      fontSize: "24px",
                                      color: "#00475F",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {"NA"}
                                  </td>
                                </tr>
                              )}
                              {data?.options.map((i, j) => (
                                <tr style={{ display: "flex" }}>
                                  <td
                                    align="left"
                                    className="td-padding"
                                    style={{
                                      padding: "5px 20px",
                                      fontFamily: "AzoSans-Regular !important",
                                      lineHeight: "24px",
                                      wordBreak: "break-word",
                                      fontSize: "24px",
                                      color: "#00475F",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {j === 0 && "Gekozen opties:"}{" "}
                                  </td>
                                  <td
                                    align="left"
                                    style={{
                                      padding: "5px 20px",
                                      fontFamily: "AzoSans-Regular !important",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      wordBreak: "break-word",
                                      fontSize: "24px",
                                      color: "#00475F",
                                      maxWidth: "100%",
                                      width: "45%",
                                    }}
                                  >
                                    <div className="d-flex">
                                      <span style={{ width: "40%" }}>
                                        {i?.option_name_nl}
                                      </span>{" "}
                                      <div
                                        className="d-flex"
                                        style={{ width: "50%" }}
                                      >
                                        <span
                                          style={{
                                            width: "10%",
                                            textAlign: "left",
                                          }}
                                        >
                                          €
                                        </span>
                                        <span
                                          style={{
                                            width: "40%",
                                            textAlign: "right",
                                          }}
                                        >
                                          {getFloat(i?.option_value)},-
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "60px 20px 20px  60px ",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  b. Werknemer is bekend en gaat akkoord met de
                                  op deze gebruikersovereenkomst van toepassing
                                  zijnde Randstad Groep Nederland autoregeling
                                  en verklaart deze gebruikersovereenkomst te
                                  hebben gesloten met inbegrip van de huidige
                                  van toepassing zijnde autoregeling.{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  c. Voor het gebruik van de genoemde auto is
                                  werknemer conform de autoregeling, aan
                                  werkgever de volgende bedragen verschuldigd,
                                  welke netto op het salaris in mindering zullen
                                  worden gebracht in de eerste 48 maanden.{" "}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding1"
                                  style={{
                                    padding: "5px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  De eigen bijdrage in verband met
                                  parttimebijdrage :
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    padding: "5px",
                                    fontFamily: "OpenSans-Regular",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    width: "150px",
                                    textAlign: "right",
                                  }}
                                >
                                  <div
                                    className="d-flex"
                                    style={{ width: "135px" }}
                                  >
                                    <span
                                      style={{
                                        width: "20%",
                                        textAlign: "right",
                                      }}
                                    >
                                      €
                                    </span>
                                    <span
                                      style={{
                                        width: "80%",
                                        textAlign: "right",
                                      }}
                                    >
                                      {getFloat(user_contribution)},-{" "}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding1"
                                  style={{
                                    padding: "5px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  De luxe eigen bijdrage in verband met gekozen
                                  opties :
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    padding: "5px",
                                    fontFamily: "OpenSans-Regular",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    width: "150px",
                                    textAlign: "right",
                                  }}
                                >
                                  <div
                                    className="d-flex"
                                    style={{ width: "135px" }}
                                  >
                                    <span
                                      style={{
                                        width: "20%",
                                        textAlign: "right",
                                      }}
                                    >
                                      €
                                    </span>
                                    <span
                                      style={{
                                        width: "80%",
                                        textAlign: "right",
                                      }}
                                    >
                                      {getFloat(getTotal())},-{" "}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  height={30}
                                  style={{
                                    height: "30px",
                                    padding: "0px 20px",
                                  }}
                                >
                                  <hr
                                    style={{
                                      border: "none",
                                      backgroundColor: "#b8baba",
                                      height: "1px",
                                      margin: "25px 0px",
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding1"
                                  style={{
                                    padding: "10px 60px",
                                    fontFamily: "Arial !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Totaal maandelijks te verrekenen bedrag incl.
                                  BTW :
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    padding: "5px",
                                    fontFamily: "OpenSans-Regular",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    width: "150px",
                                    textAlign: "right",
                                  }}
                                >
                                  <div
                                    className="d-flex"
                                    style={{ width: "135px" }}
                                  >
                                    <span
                                      style={{
                                        width: "20%",
                                        textAlign: "right",
                                      }}
                                    >
                                      €
                                    </span>
                                    <span
                                      style={{
                                        width: "80%",
                                        textAlign: "right",
                                      }}
                                    >
                                      {getFloat(getTotal() + user_contribution)}
                                      ,-{" "}
                                    </span>
                                  </div>
                                  {/* € {getTotal() + user_contribution},-{" "} */}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  d. Werknemer machtigt werkgever alle krachtens
                                  de lease-autoregeling door werknemer aan
                                  werkgever verschuldigde bedragen in te houden
                                  op de netto salarisbetalingen.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  e. Verplichting van werknemer:
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 10px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  a. Werknemer verklaart in het bezit te zijn
                                  van een in Nederland geldig rijbewijs en zorgt
                                  ervoor dat het rijbewijs zijn geldigheid niet
                                  verliest zolang hij/zij de beschikking heeft
                                  over een auto;
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 10px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  b. Werknemer is verplicht de instructies en de
                                  adviezen van de leasemaatschappij en dealer
                                  betreffende het gebruik en onderhoud van de
                                  auto op te volgen;
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 10px 60px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  c. Werknemer ziet erop toe dat de auto niet
                                  wordt bestuurd door hiertoe onbevoegde
                                  personen of door personen, hemzelf inbegrepen,
                                  die, om welke reden dan ook onbekwaam zijn de
                                  auto te besturen en/of waarvan het rijbewijs
                                  is ingenomen.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Het voorgaande laat onverlet het bepaalde in
                                  de Randstad Groep Nederland geldende
                                  lease-autoregeling, alsmede de wettelijke
                                  bepalingen verband houden met het gebruik van
                                  de auto.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "20px 20px 10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "20px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Aldus overeengekomen,
                                </td>
                              </tr>
                              <tr
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <td
                                  align="left"
                                  className="td-padding-20"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Werknemer:{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "OpenSans-Regular",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {user_data?.full_name || "NA"}{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Namens Randstad :{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding-30"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "OpenSans-Regular",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Wagenparkbeheer Randstad{" "}
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding-20"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Plaats :{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {user_data?.city || "NA"}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Plaats :{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Arnhem{" "}
                                </td>
                              </tr>
                              <tr style={{ display: "flex" }}>
                                <td
                                  align="left"
                                  className="td-padding-20"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Datum :{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {date.getUTCDate()}{" "}
                                  {monthNames[date.getMonth()]}{" "}
                                  {date.getUTCFullYear()}{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Datum :{" "}
                                </td>
                                <td
                                  align="left"
                                  className="td-padding2"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {date.getUTCDate()}{" "}
                                  {monthNames[date.getMonth()]}{" "}
                                  {date.getUTCFullYear()}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Voor Akkoord :{" "}
                                </td>
                              </tr>
                              <tr className="d-grid">
                                <td
                                  style={{
                                    padding: "10px 20px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {showpad && (
                                    <>
                                      <SignatureCanvas
                                        ref={sigCanvas}
                                        backgroundColor="rgb(248, 249, 243)"
                                        canvasProps={{
                                          width: 450,
                                          height: 200,
                                          className: "sigCanvas",
                                        }}
                                      />
                                      <small className="text-danger">
                                        {signErr && signErr}
                                      </small>
                                      <div className="d-flex">
                                        <button
                                          onClick={() => {
                                            saveSign();
                                            // close();
                                          }}
                                        >
                                          Save
                                        </button>
                                        <button onClick={clear}>Clear</button>
                                      </div>
                                    </>
                                  )}
                                  {!showpad && (
                                    <>
                                      <img src={sign} />
                                      <div className="col-2">
                                        <button
                                          onClick={() => setShowPad(true)}
                                        >
                                          Erase
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="btn1"
                              >
                                <td
                                  align="left"
                                  className=" btn"
                                  height={45}
                                  style={{
                                    padding: "10px 68px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    color: "#1F9CDD",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    border: "1px solid #1F9CDD",
                                    borderRadius: "40px",
                                    maxWidth: "100%",
                                    marginRight: "15px",
                                  }}
                                  onClick={() => goBack()}
                                >
                                  Cancel{" "}
                                </td>
                                <td
                                  align="left"
                                  className="btn opa"
                                  height={45}
                                  style={{
                                    opacity:
                                      (showpad || ageement_spinner) && 0.5,
                                    pointerEvents:
                                      (showpad || ageement_spinner) && "none",
                                    padding: "10px 68px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "24px",
                                    wordBreak: "break-word",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "white",
                                    maxWidth: "100%",
                                    border: "1px solid #1F9CDD",
                                    borderRadius: "40px",
                                    backgroundColor: "#1F9CDD",
                                  }}
                                  onClick={() => onSubmit()}
                                >
                                  {ageement_spinner ? "Submitting" : "Submit"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    padding: "50px 20px 10px 20px",
                                    fontFamily: "AzoSans-Regular !important",
                                    lineHeight: "38px",
                                    width: "100% !important",
                                    wordBreak: "break-word",
                                    fontSize: "24px",
                                    color: "#00475F",
                                    maxWidth: "100%",
                                  }}
                                >
                                  Fleet Support besteedt veel zorg aan de
                                  correctheid van de door haar verstrekte
                                  informatie. Deze is deels afkomstig vanderden.
                                  Fleet Support is dan ook niet aansprakelijk
                                  voor schade die wordt geleden als gevolg van
                                  de gehele of gedeeltelijkeonjuistheid van door
                                  haar verstrekte informatie zoals: fiscale
                                  bijtellingspercentages, CO2-uitstoot,
                                  (catalogus)prijzen
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            className="full_width_col"
                            width="100%"
                            max-width="100%"
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            align="left"
                            bgcolor="#ffffff"
                            style={{
                              borderCollapse: "collapse",
                              msoTableLspace: "0pt",
                              msoTableRspace: "0pt",
                              border: 0,
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="left"
                                  style={{
                                    textAlign: "center",
                                    padding: "0px 20px",
                                  }}
                                >
                                  <table
                                    className="full_width"
                                    max-width="100%"
                                    cellSpacing={0}
                                    cellPadding={0}
                                    border={0}
                                    align="left"
                                    style={{ maxWidth: "100%" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          height={16}
                                          style={{ height: "16px" }}
                                        />
                                      </tr>
                                      <tr>
                                        <td>
                                          <table
                                            style={{
                                              fontSize: "17px",
                                              color: "black",
                                              margin: "25px 0px 0px 0px",
                                              fontFamily: "Arial",
                                              lineHeight: "24px",
                                            }}
                                          ></table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          height={20}
                                          style={{ height: "20px" }}
                                        />
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </>
    );
  } else {
    <Redirect to="/" />;
  }
}
