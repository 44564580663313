import React, { useState } from "react";
import Button from '@material-ui/core/Button';

function Home() {

  return (
    <div className="row home">
      <div className="col-md-6 d-flex justify-content-center">
        <input
          className="csv-input"
          type="file"
          name="file"
          // onChange={(event) => setFile(event.target.files[0])}
        />

      </div>
      <div className="col-md-6">
        <Button
          variant="contained"
          color="primary"
        >
          Upload now!
        </Button>
      </div>
    </div>
  );
}

export default Home;
