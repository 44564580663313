import React from "react";
import { Agreement, Header } from "../components";
import { ProtectedRoute, AdminRoute } from "../Routes";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

export default function CoreLayout({ children }) {
  console.log("CoreLayout");
  return <>{children}</>;
}
