import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { CreateUsers } from "../../modules/login";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ImportUsers = (props) => {
  let [error, setError] = useState("");
  let [file, setFile] = useState("");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let fileData = new FormData();

  const handleFile = async (file) => {
    setFile(file);
    fileData.append("file", file);
  };

  const handleSubmit = () => {
    if (!file) {
      setError(t("validation.v62")); 
    } else {
      dispatch(CreateUsers(file));
    }
    setTimeout(() => {
      setError("");
    }, 4000);
  };

  const handleClick = () => {
    let inputfile = document.getElementById("excel-files");
    inputfile.click();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("language.part119")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="import-modal">
        <div className="row mx-1">
          <div className="col-md-8">
            <button className="select-excel" onClick={handleClick}>
              {t("language.part120")}
            </button>
            <label className="mx-1">
              {file ? file.name.slice(0, 18) : t("language.part121")}
              {file && file.name.length > 18 && "..."}
            </label>
            <input
              id="excel-files"
              className="csv-input"
              type="file"
              name="file"
              onChange={(event) => handleFile(event.target.files[0])}
            />
          </div>

          <div className="col-md-4 blue-button">
            <Button onClick={() => handleSubmit()}>
              {t("language.part122")}
            </Button>
          </div>
          {error && <small className="text-danger">{error}</small>}
          {/* {console.log(error)} */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImportUsers;
