import React, { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useLocation,
} from "react-router-dom";
import { ScrollToTop } from "./components";
import MainLayout from "./Layouts/MainLayout";
import CoreLayout from "./Layouts/CoreLayout";

require("dotenv").config();

// definign all the routes using layouts approach
function App() {
  return (
    <>
      <ScrollToTop />
      <Route path="/voorwaarden" component={CoreLayout} />
      <Route path="/" component={MainLayout} />
    </>
  );
}

export default App;
