import React from "react";
import { Link } from "react-router-dom";

// Component for showing 404 page
export default function NotFound(params) {
  return (
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="error-template text-center">
            <h1>404 | Not Found</h1>
            {/* <h2>UH OH! You're lost.</h2>
            <p></p>
            <div class="error-actions">
              <Link
                to="/"
                class="btn takemehome btn-lg"
              >
                Take Me Home{" "}
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
