import apiservice from "../api/apiInstance";
import { getFiltersOption, setAlertMessage, setNLMessage } from "./allCars";

export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";

export function setDashboardData(data) {
  const d_1 = [
    { name: "New", fill: "#00B4FF", label: "new_user" },
    { name: "Active", fill: "#FF8A34", label: "active_user" },
    { name: "Complete", fill: "#FF5252", label: "complete_user" },
  ];

  const d_2 = [
    { name: "New", fill: "#726EFF", label: "new_car" },
    { name: "Published", fill: "#FF8888", label: "published_car" },
    { name: "Unpublished", fill: "#F07BFF", label: "unpublished_car" },
  ];

  d_1[0].value = data.layer_2.user_data.new_user;
  d_1[1].value = data.layer_2.user_data.active;
  d_1[2].value = data.layer_2.user_data.complete;

  d_2[0].value = data.layer_2.car_data.new_car;
  d_2[1].value = data.layer_2.car_data.published;
  d_2[2].value = data.layer_2.car_data.unpublished;

  data.layer_2.user_data = d_1;
  data.layer_2.car_data = d_2;

  return {
    type: SET_DASHBOARD_DATA,
    payload: data,
  };
}

export function saveData(data, params) {
  return async (dispatch, getState) => {
    if (params?.user_report_type == "monthly") {
      let user_monthly = [];
      console.log("1");
      let { user_monthly_data } = data;
      Object.keys(user_monthly_data).map((i) => {
        user_monthly_data[i]["value"] = i;
        user_monthly.push(user_monthly_data[i]);
      });
      data.user_monthly_data = user_monthly;
      // user_monthly[12].value='Decem...'
    } else if (params?.user_report_type == "weekly") {
      console.log("2");
      let user_weekly = [];
      let { user_weekly_data } = data;
      Object.keys(user_weekly_data).map((i) => {
        user_weekly_data[i]["value"] = i;
        user_weekly.push(user_weekly_data[i]);
      });
      data.user_weekly_data = user_weekly;
    }

    if (params?.car_report_type == "monthly") {
      console.log("3");
      let car_monthly = [];
      let { car_monthly_data } = data;
      Object.keys(car_monthly_data).map((i) => {
        car_monthly_data[i]["value"] = i;
        car_monthly.push(car_monthly_data[i]);
      });
      data.car_monthly_data = car_monthly;
    } else if (params?.car_report_type == "weekly") {
      let car_weekly = [];
      console.log("4");
      let { car_weekly_data } = data;
      Object.keys(car_weekly_data).map((i) => {
        car_weekly_data[i]["value"] = i;
        car_weekly.push(car_weekly_data[i]);
      });
      data.car_weekly_data = car_weekly;
    }

    let user_current_week = [];
    let { user_current_week_data } = data;

    Object.keys(user_current_week_data).map((i) => {
      user_current_week_data[i]["label"] = i;
      user_current_week.push(user_current_week_data[i]);
    });
    data.user_current_week_data = user_current_week;
    console.log();

    await dispatch(setDashboardData(data));
  };
}

export function getData(params) {
  return async (dispatch, getState) => {
    params.user_preference = JSON.stringify(params?.user_preference);
    params.car_preference = JSON.stringify(params?.car_preference);

    const { userlng } = getState().allCarsState;
    try {
      let response = await apiservice.get("/admin/dashboard/users", {
        params: params,
        headers: {
          "x-source": "ev",
          language: userlng == "de" ? "nl" : 'en',
        },
      });
      if (response.status === 200) {
        await dispatch(saveData(response?.data, params));
      }
    } catch (e) {}
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 5000);
  };
}

export const initialState = {
  all_data: {},
  doughnut_1: [
    {
      label: "new_user",
      color: "#00B4FF",
      value: "new",
    },
    {
      label: "active_user",
      color: "#FF8A34",
      value: "active",
    },
    {
      label: "complete_user",
      color: "#FF5252",
      value: "complete",
    },
  ],
  doughnut_2: [
    {
      label: "new_car",
      color: "#726EFF",
      value: "new",
    },
    {
      label: "published_car",
      color: "#FF8888",
      value: "published",
    },
    {
      label: "unpublished_car",
      color: "#F07BFF",
      value: "unpublished",
    },
  ],
  user_monthly_legend: [
    {
      label: "new_user",
      color: "#F07BFF",
      value: "new",
    },
    {
      label: "active_user",
      color: "#726EFF",
      value: "active",
    },
    {
      label: "complete_user",
      color: "#FF8888",
      value: "complete",
    },
  ],
  car_monthly_legend: [
    {
      label: "new_car",
      color: "#FF8888",
      value: "new",
    },
    {
      label: "published_car",
      color: "#F07BFF",
      value: "published",
    },
    {
      label: "unpublished_car",
      color: "#726EFF",
      value: "unpublished",
    },
  ],
  months: [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ],
};

const ACTION_HANDLERS = {
  [SET_DASHBOARD_DATA]: (state, action) => {
    return {
      ...state,
      all_data: action.payload,
    };
  },
};

export default function dashboardReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
