import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import SHIFT from "../../asset/SHIFT-RGB.png";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword, setNewPassword } from "../../modules/login";
import Close from "../../asset/close-icon.svg";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { setLoginModal } from "../../modules/allCars";

const SetNewPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory()
  const { passwordErr, newPasswordErr } = useSelector(
    (state) => state.authState
  );
  const { userlng } = useSelector((state) => state.allCarsState);

  let location = useLocation();
  let query = new URLSearchParams(location.search);

  const { t } = useTranslation();
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const initialValues = {
    new_password: "",
    confirm_password: "",
    token: query.get("token"),
    email: query.get("email"),
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    formikRef.current.setFieldValue(name, value.replace(" ", ""));
  };

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .required(t("validation.v56"))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t("validation.v61")
      ),
    confirm_password: yup
      .string()
      .required(t("validation.v57"))
      .oneOf([yup.ref("new_password"), null], t("validation.v58")),
  });

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Modal
      show={show}
      onHide={props.handleClose}
      keyboard={false}
      className="login-popup setnew"
      backdrop="static"
    >
      <div className="position-relative popup-inner">
        <div class="text-center">
          <img src={SHIFT} className="shift-img"></img>
        </div>
        <h3>{t('language.part137')} </h3>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(setNewPassword({ values:values, history: history }));
          }}
        >
          {({ errors, values, touched }) => (
            <Form>
              <div className="form-group">
                <Input
                  className="form-control"
                  type="text"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  value={values.email}
                  disabled
                />
                <Input
                  placeholder={t('language.part1013')}
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  name="new_password"
                  onChange={(e) => handleChange(e)}
                  value={values.new_password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.new_password && touched.new_password && (
                  <small className="text-danger">{errors.new_password}</small>
                )}
                <Input
                  className="form-control  mt-2"
                  placeholder="bevestig wachtwoord"
                  type={showNewPassword ? "text" : "password"}
                  onChange={(e) => handleChange(e)}
                  name="confirm_password"
                  value={values.confirm_password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={handleMouseDownNewPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.confirm_password && touched.confirm_password && (
                  <small className="text-danger">
                    {errors.confirm_password}
                  </small>
                )}
              </div>
              {newPasswordErr && (
                <small className="text-danger">{newPasswordErr}</small>
              )}
              <div className="d-flex flex-column align-items-center  mt-2">
                <div className="invalid-feedback"></div>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  class="login-btn btn-margin mb-4"
                >
                  {t('language.part143')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="d-flex justify-content-center position-absolute popup-bottom bottom-popup modal-head">
          <h3>
            <span>Onthoud je je laatste wachtwoord?</span>
            <Link
              to="/all-cars/auth"
              onClick={() => dispatch(setLoginModal(true))}
            >
              Inloggen
            </Link>
          </h3>
        </div>
      </div>
    </Modal>
  );
};

export default SetNewPassword;
