import React, { useEffect, useState, Fragment, memo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Spinner, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { categories_value, getCategory } from "../utils/commonUtils";
import {
  getFiltersOption,
  fetchBestCar,
  fetchStandardCar,
} from "../modules/allCars";
import auth from "../helpers/auth";
import { useDispatch, useSelector } from "react-redux";
import Filter from "./Common/Filter";
import AddNew from "./Common/AddNew";
import PageSpinner from "./Common/PageSpinner";
import LoadMore from "./Common/LoadMore";
import UserCar from "./Common/UserCar";

// main program start
const CarList = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMouseInside, setIsMouseInside] = useState({});

  const [chargingErr, setChargingErr] = useState("");

  let [categories, setCategories] = useState([]);

  const { user_data } = useSelector((state) => state.authState);

  const {
    importusers,
    userlng,
    bestCars,
    stdCars,
    bestSpinner,
    stdSpinner,
    stdPageSpinner,
    bestPageSpinner,
    filterOptions,
  } = useSelector((state) => state.allCarsState);

  const [filters, setFilter] = useState({
    // brand: [],
    // model: [],
    // version: [],
    // category: [],
  });

  const { t } = useTranslation();

  const loadMoreBest = () => {
    let { current_page } = bestCars;
    current_page = parseInt(current_page) + 1;
    dispatch(fetchBestCar({ ...filters, page: current_page }));
  };

  const loadMoreStandard = () => {
    let { current_page } = stdCars;
    current_page = parseInt(current_page) + 1;
    dispatch(fetchStandardCar({ ...filters, page: current_page }));
  };

  // filter functionality
  const handleFilterChange = (e, field) => {
    if (field === "category") {
      filters[field] = [e] || [];
      setCategories(e);
    } else if (field === "brand" && (!e || e?.length == 0)) {
      filters["brand"] = [];
      filters["model"] = [];
    } else {
      filters[field] = e || [];
    }

    if (!e || e.length === 0) {
      setFilter(filters);
    } else {
      setFilter(filters);
    }

    dispatch(fetchBestCar({ ...filters, page: 1 }));
    dispatch(fetchStandardCar({ ...filters, page: 1 }));
    dispatch(getFiltersOption(filters));
    localStorage.setItem("categories", JSON.stringify(categories));
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(fetchBestCar({ page: 1 }));
      dispatch(fetchStandardCar({ page: 1 }));
    }
  }, [auth.isAuthenticated, userlng]);

  return (
    <div className="list-page">
      <div className="main-container">
        <div className="d-flex list">
          <div className="sidebar">
            <Filter
              handleFilterChange={(e, label) => handleFilterChange(e, label)}
              filters={filters}
              filterOptions={filterOptions}
              categories={props.categories}
              categories_value={categories_value.filter(
                (i) => parseInt(i.label) <= user_data?.category
              )}
            />
          </div>
          {bestSpinner ? (
            <div
              style={{
                position: "absolute",
                zIndex: "10",
                left: "60%",
                top: "400px",
                height: "400px",
              }}
            >
              <Spinner animation="border" variant="info" />
            </div>
          ) : (
            <>
              <div className="car-lists row pb-4 ">
                {bestCars?.data?.results.length >= 1 && (
                  <h1
                    style={{
                      fontSize: "18px",
                      color: "#00475F",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("language.part48")}
                  </h1>
                )}
                {bestCars?.data?.results.length == 0 &&
                  stdCars?.data?.results.length === 0 && (
                    <h3 className="text-center m-auto">No cars found</h3>
                  )}
                {bestCars?.data?.results.map((car, index) => {
                  let cover = car?.color.filter(
                    (i) => i?.images && i?.images.length !== 0
                  )[0]?.images[0];
                  return <UserCar car={car} cover={cover} index={index} />;
                })}
                {bestPageSpinner && <PageSpinner />}
                {bestCars?.current_page < bestCars?.Total_page &&
                  !bestSpinner && <LoadMore loadMore={loadMoreBest} />}

                <div>
                  {stdCars?.data?.results.length >= 1 && (
                    <h1
                      style={{
                        fontSize: "18px",
                        color: "#00475F",
                        marginTop: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("language.part49")}
                    </h1>
                  )}
                </div>
                {stdCars?.data?.results.map((car, index) => {
                  let cover = car?.color.filter(
                    (i) => i?.images && i?.images.length !== 0
                  )[0]?.images[0];
                  return <UserCar car={car} cover={cover} index={index} />;
                })}
                {stdPageSpinner && <PageSpinner />}
                {stdCars?.current_page < stdCars?.Total_page && !stdSpinner && (
                  <LoadMore loadMore={loadMoreStandard} />
                )}

                {auth.isAuthenticated && auth.getUserType() === "Admin" && (
                  <AddNew />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarList;
