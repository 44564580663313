import apiservice from "../api/apiInstance";
import { fetchStandardCar, setAlertMessage, setNLMessage } from "./allCars";

export const SET_MY_BEST_CARS_SPINNER_STATUS = "SET_MY_BEST_CARS_SPINNER_STATUS";
export const SET_MY_STD_CARS_SPINNER_STATUS = "SET_MY_STD_CARS_SPINNER_STATUS";
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const SET_DATA_UPLOAD_SPINNER = "SET_DATA_UPLOAD_SPINNER";
export const SET_MY_STANDARD_CARS = "SET_MY_STANDARD_CARS";
export const SET_MY_BEST_CARS = "SET_MY_BEST_CARS";
export const SET_MY_CAR_DATA = "SET_MY_CAR_DATA";
export const SET_MY_CAR_SPINNER_STATUS = "SET_MY_CAR_SPINNER_STATUS";
export const SET_MY_BEST_CARS_PAGE_SPINNER = "SET_MY_BEST_CARS_PAGE_SPINNER";
export const SET_MY_STANDARD_CARS_PAGE_SPINNER = "SET_MY_STANDARD_CARS_PAGE_SPINNER";

export function setMyBestCars(params) {
  return {
    type: SET_MY_BEST_CARS,
    payload: params,
  };
}

export function setMyStdCars(params) {
  return {
    type: SET_MY_STANDARD_CARS,
    payload: params,
  };
}

export function setMyBestCarSpinner(flag) {
  return {
    type: SET_MY_BEST_CARS_SPINNER_STATUS,
    payload: flag,
  };
}

export function setMyCarSpinner(flag) {
  return {
    type: SET_MY_CAR_SPINNER_STATUS,
    payload: flag,
  };
}

export function setMyStdCarSpinner(flag) {
  return {
    type: SET_MY_STD_CARS_SPINNER_STATUS,
    payload: flag,
  };
}

export function setMyCarData(params) {
  return {
    type: SET_MY_CAR_DATA,
    payload: params,
  };
}

export function setMyBestPageSpinner(params) {
  return {
    type: SET_MY_BEST_CARS_PAGE_SPINNER,
    payload: params,
  };
}

export function setMyStdPageSpinner(params) {
  return {
    type: SET_MY_STANDARD_CARS_PAGE_SPINNER,
    payload: params,
  };
}

export function fetchMyBestCars(data) {
  return async (dispatch, getState) => {
    let cat = [],
      brand = [],
      model = [],
      version = [];
    if (data) {
      data.category && data.category.map((i) => cat.push(i["label"]));
      data.brand && data.brand.map((i) => brand.push(i["brand"]));
      data.model && data.model.map((i) => model.push(i["model"]));
      data.version && data.version.map((i) => version.push(i["version"]));
    }
    let { userlng } = getState().allCarsState;
    if (userlng === "de") userlng = "nl";
    data.page > 1
      ? await dispatch(setMyBestPageSpinner(true))
      : await dispatch(setMyBestCarSpinner(true));

    try {
      const response = await apiservice.get(`/ev/all_best_car`, {
        params: {
          category: JSON.stringify(cat),
          model: JSON.stringify(model),
          version: JSON.stringify(version),
          brand: JSON.stringify(brand),
          page: data.page,
        },
        headers: { language: userlng },
      });
      if (response.status === 200) {
        let { myBestCars } = getState().myCarState;
        if (data.page > 1) {
          response.data.data.results = [
            ...myBestCars.data.results,
            ...response.data.data.results,
          ];
          await dispatch(setMyBestCars(response.data));
        } else {
          await dispatch(setMyBestCars(response.data));
        }
        await dispatch(setMyBestPageSpinner(false));
        await dispatch(setMyBestCarSpinner(false));
      } else {
        await dispatch(setMyBestCarSpinner(false));
        await dispatch(setMyBestPageSpinner(false));
      }
    } catch (e) {
      await dispatch(setMyBestCarSpinner(false));
      await dispatch(setMyBestPageSpinner(false));
      console.log(e);
    }
  };
}

export function fetchMyStdCars(data) {
  return async (dispatch, getState) => {
    let { userlng } = getState().allCarsState;
    if (userlng === "de") userlng = "nl";
    let cat = [],
      brand = [],
      model = [],
      version = [];
    if (data) {
      data?.category?.map((i) => cat.push(i["label"]));
      data?.brand?.map((i) => brand.push(i["brand"]));
      data?.model?.map((i) => model.push(i["model"]));
      data?.version?.map((i) => version.push(i["version"]));
    }
    data.page > 1
      ? await dispatch(setMyStdPageSpinner(true))
      : await dispatch(setMyStdCarSpinner(true));
    try {
      const response = await apiservice.get(`/ev/all_standard_car`, {
        params: {
          category: JSON.stringify(cat),
          model: JSON.stringify(model),
          version: JSON.stringify(version),
          brand: JSON.stringify(brand),
          page: data.page,
        },
        headers: { language: userlng },
      });
      if (response.status === 200) {
        let { myStdCars } = getState().myCarState;
        if (data.page > 1) {
          response.data.data.results = [
            ...myStdCars.data.results,
            ...response.data.data.results,
          ];
          await dispatch(setMyStdCars(response.data));
        } else {
          await dispatch(setMyStdCars(response.data));
        }
        await dispatch(setMyStdCarSpinner(false));
        await dispatch(setMyStdPageSpinner(false));
      } else {
        await dispatch(setMyStdCarSpinner(false));
        await dispatch(setMyStdPageSpinner(false));
      }
    } catch (e) {
      await dispatch(setMyStdCarSpinner(false));
      await dispatch(setMyStdPageSpinner(false));
      console.log(e);
    }
  };
}

export function removeCar(id, history) {
  return async (dispatch, getState) => {
    try {
      const response = await apiservice.delete(`/ev/${id}`);
      if (response.status === 200) {
        await dispatch(setAlertMessage(response.data.message, "success"));
        await dispatch(setNLMessage(response.data.message, "success"));
        await history.push("/my-cars");
        dispatch(fetchMyBestCars({ page: 1 }));
        dispatch(fetchMyStdCars({ page: 1 }));
      } else {
        await dispatch(setAlertMessage(response.data.message, "error"));
        await dispatch(setNLMessage(response.data.message, "error"));
        await history.push("/my-cars");
        dispatch(fetchStandardCar({ page: 1 }));
        dispatch(fetchMyBestCars({ page: 1 }));
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Internal server error", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 4000);
  };
}

export function publishedCar(selectedCar, data) {
  console.log();
  return async (dispatch, getState) => {
    try {
      const response = await apiservice.patch(`/ev/${selectedCar.id}`, data);
      console.log(response);
      if (response.status === 200 && response.data.code === 200) {
        console.log(selectedCar?.car_type);
        if (selectedCar?.car_type === "Best") {
          let { myBestCars } = getState().myCarState;
          let index = myBestCars?.data?.results.findIndex(
            (i) => i.id === selectedCar?.id
          );
          myBestCars.data.results[index]["ispublished"] = data.ispublished;
          setMyBestCars(myBestCars);
        } else {
          console.log("hi");
          let { myStdCars } = getState().myCarState;
          let index = myStdCars?.data?.results.findIndex(
            (i) => i.id === selectedCar?.id
          );
          console.log(index);
          myStdCars.data.results[index]["ispublished"] = data.ispublished;
          setMyStdCars(myStdCars);
        }
        await dispatch(
          setAlertMessage(
            data.ispublished
              ? "Car Published successfully"
              : "Car UnPublished successfully",
            "success"
          )
        );
        await dispatch(
          setNLMessage(
            data.ispublished
              ? "Auto succesvol gepubliceerd"
              : "Auto succesvol gedepubliceerd",
            "success"
          )
        );  
      } else {
        console.log("hi");

        await dispatch(setNLMessage(response.data.message, "error"));
        await dispatch(setAlertMessage(response.data.message_en, "error"));
      }
    } catch (e) {
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 4000);
  };
}

export function fetchMyCarDetail(id) {
  return async (dispatch, getState) => {
    await dispatch(setMyCarSpinner(true));
    try {
      const response = await apiservice.get(`/ev/${id}`);
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setMyCarData(response.data.data));
        await dispatch(setMyCarSpinner(false));
      } else {
        await dispatch(setMyCarSpinner(false));
        await dispatch(setNLMessage(response.data.message_nl, "error"));
        await dispatch(setAlertMessage(response.data.message_en, "error"));
      }
    } catch (e) {
      await dispatch(setMyCarSpinner(false));
      await dispatch(setAlertMessage("Internal server error", "error"));
      console.log(e);
    }
  };
}

export const initialState = {
  myBestCars: {
    current_page: 1,
  },
  myStdCars: {},
  changePwdModal: false,
  myBestCarSpinner: false,
  myStdCarSpinner: false,
  myCarSpinner: false,
  myCar: {},
  nl_message: {},
  mybestPageSpinner: false,
  myStdPageSpinner: false,
};

const ACTION_HANDLERS = {
  [SET_MY_BEST_CARS]: (state, action) => {
    return {
      ...state,
      myBestCars: action.payload,
    };
  },
  [SET_MY_STANDARD_CARS]: (state, action) => {
    return {
      ...state,
      myStdCars: action.payload,
    };
  },
  [SET_MY_BEST_CARS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      myBestCarSpinner: action.payload,
    };
  },
  [SET_MY_STD_CARS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      myStdCarSpinner: action.payload,
    };
  },
  [SET_MY_CAR_DATA]: (state, action) => {
    return {
      ...state,
      myCar: action.payload,
    };
  },
  [SET_MY_CAR_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      myCarSpinner: action.payload,
    };
  },
  [SET_MY_BEST_CARS_PAGE_SPINNER]: (state, action) => {
    return {
      ...state,
      mybestPageSpinner: action.payload,
    };
  },
  [SET_MY_STANDARD_CARS_PAGE_SPINNER]: (state, action) => {
    return {
      ...state,
      myStdPageSpinner: action.payload,
    };
  },
};

export default function myCarsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
