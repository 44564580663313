import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "de",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          language: {
            part1: "TO OVERVIEW",
            part2: "Options",
            part3: "Color",
            part4: "Range",
            part5: "Addition Percentage",
            part6: "Battery Capacity",
            part7: "Tax Value",
            part8: "Expected Delivery Time",
            part9: "Category",
            part9a: "EV Category",
            part10: "A charging point at home",
            part11: "Choosable Option",
            part12: "Towbar",
            part13: "Trailer hitch ",
            part14: "Heat pump",
            part15: "Total personal contribution",
            part16: "This is definitely my car",
            part17: "Per month",
            part18: "Auto pilot stage 3",
            part19: "19 inch lichtmetalen wielen (type ultraturbine)",
            part20: "Lane assist level 4",
            part21: "Climate control",
            part22: "etc",
            part23: "Description",
            part24: "Brand",
            part25: "Model",
            part26: "Car Type",
            part27: "Car Description",
            part28: "Charging Point Charges",
            part29: "Towbar charges",
            part30: "Trailer Hitch charges per month",
            part31: "Heat pump charges per month",
            part32: "Total personal contribution per month",
            part33: "Best Buys",
            part34: "Standard Car",
            part35: "Performance",
            part36: "Save",
            part37: "Publish",
            part38: "Add Vehicle Manually",
            part39: "Basic data",
            part40: "Drag your images here",
            part41: "Upload image min 1 and max 5",
            part42:
              "Recommended image resolution 600*450 and size less than 1MB",
            part43: "Version",
            part44: "View",
            part45: "Version",
            part46: "Contribution",
            part47: "How does it work?",
            part48: "Best Buys",
            part49: "Standard Car",
            part50: "Remove",
            part51: "Un Publish",
            part52: "No. of doors",
            part53: "Filters",
            part54: "Yes",
            part55: "No",
            part56: "Name",
            part57: "Personnel number",
            part58: "Email address",
            part59: "Mobile number",
            part60: "Iservice job number",
            part61: `Are you in doubt between yes or no: we are happy
             to help you with your choice, Click`,
            part62: `After you click on the button below we will send you a user agreement. Confirming that you agree with the cost and configuration of this cool electric car.`,
            part63: `I would like to order an electric car`,
            part64: `Thank you for confirming your order.`,
            part64a: `We will start drawing up a user agreement that we will send to you by email. 
            If you want to return it with Agree, we will actually place your car on order.`,
            part65: `  As soon as there is an indication of the expected delivery
            date, the lease company that will deliver your car will share
            this with you and us. You can expect this message within the
            next week.`,
            part66: `Thank you for your part in realizing the sustainability
            ambition of Randstad Groep Nederland. You can now close the
            page, your configuration has been received in good order.`,
            part67: `Thank you for confirming your order`,
            part68: "Publish",
            part69: "Unpublish",
            part70: "Remove",
            part71: "Close",
            part72: "Search",
            part73: "Advance Search",
            part74: "Remove",
            part75: "Edit",
            part76: "Please select a brand",
            part77: "Add Car",
            part78: "Add Color",
            part79: "Color name",
            part80: "Delete",
            part81: "Add Option",
            part82: "Add Choosable Option",
            part83: "Value",
            part84: "or Browse",
            part85: "Option Name",
            part86: "Are you sure you want to delete this car?",
            part86a: "Are you sure you want to delete this User?",

            part87: "LOAD MORE",
            part88: "Own Contribution",
            part89: "Choosen options",
            part90: "no chosen options",
            part91: "UPDATE",
            part92: "Edit Vehicle Manually",
            part93: "Employee Name",
            part94: "Employee Iservice Number",
            part95: "Employee Personnel Number",
            part96: "Employee Mobile Number",
            part97: "Chargepoint",
            part98:
              "Hi, You have a new query for a Car.Here are the car details:",
            part99: "Guest",
            part100: "Log in and benefit from your employer's options",
            part101: "New to Fleet support?",
            part102: "Create Account",
            part103: "User Details",
            part104: "Car details",
            part105: "Month",
            part106: "You already have added this option.",
            part107: "We will respond you soon",
            part108:
              "Fleet Support carries out fleet management on behalf of Randstad Groep Nederland. In view of the developments within Randstad to only order EV cars, it has been decided to make this portal available for Randstad employees to choose their next lease car.",
            part109:
              "In this portal you can choose which electric car you want to order. It works simply:",
            part110:
              "You choose the car and model and color of your preference.",
            part111:
              "The car indicates whether you want a charging facility or not",
            part112: "Select any option that can be provided within the offer",
            part113:
              "We will check the order for completeness and correctness and together we ensure that your new electric car is ordered and that a charging facility is placed in time if you have chosen this.",
            part114: "Do I have to select a charging facility",
            part115:
              "The question of whether you select a charging facility depends on your living situation. If you have the option to install a charging facility at your home and are willing to bear any additional costs for adjusting the meter box or extra excavation work, choose YES here. If you live in an apartment or if you do not have the option of mounting a charging facility to your house, choose NO here. If you do not have your own charging facility, you can of course charge at public charging facilities (often nearby) and at the office or at customer locations",
            part116: "Username",
            part117: "Login",
            part118: "Continue",
            part1000:
              "The tax-addition depends on the moment the car gets its license plates,",
            part1001: "click here",
            part1002: "for more info",
            part1010: "Password",
            part1011: "Change Password",
            part1012: "Old Password",
            part1013: "New Password",
            part1014: "Retype New Password",
            part1015: "Don’t want to change password?",
            part1016: "Log In ",
            part119: "Import Users",
            part120: "Choose file",
            part121: "No file choosen",
            part122: "Import",
            part123: "Tax Addition",
            part124:
              "You can use the overview below to determine the tax addition of your company electric car. This allows you to determine which amount you should add to your gross income. The tax addition is determined when the car is registered. Not when the car is ordered.",
            part125:
              "The addition for all lease cars has been gradually increased to 22 percent in recent years and only fully electric cars will receive an extra incentive in 2021 by an addition of 12 percent (ed. to a list value of 40,000 euros, above that the general addition rate of 22 percent applies). ).The addition is fixed for five years after the car has been registered for the first time. After that, the applicable legislation will apply with regard to the addition",
            part126:
              "Tax addition electric leasing cars (fuel cars remain 22%)",
            part127: "till",
            part128: "above that",
            part129: "Login",
            part130: "Welcome",
            part131: "Addition Electric cars?",
            part132: "Do I have to select a charging facility?",
            part133:
              "Whether you select a charging facility depends on your living situation. Do you have the option of a charging facility? to your house and are you willing to pay any additional costs? carry for adjusting the meter box or extra excavation work, choose YES here. Do you live in a apartment or do you not have the option of a charging facility? to be mounted on your house, choose NO here. If you don't own If you have a charging facility, you can of course charge at public charging facilities (often nearby) and at the office or at customer locations",
            part134: "Privacy policy",
            part135: "Shift. All rights Reserved.",
            part136: "Long range category",
            part137: "Set new password",
            part138: "Remember your last password?",
            part139: "Forgot Password",
            part140: "Please enter the email you use to sign in to shift.",
            part141: "Reset Password",
            paty142: "back to Sign In",
            part143: "Set Password",
            part144: "My Users",
            part145: "My Cars",
            part146: "Search",
            part147: "Organization name",
            part148: "Users created at",
            part149: "User type",
            part150: "User updated at",
            part151: "previous",
            part152: "next",
            part153: "Signed contract",
            part154: "Created on",
            part155: "Status",
            part156: "Personnel number",
            part157: "Task number",
            part158: "Rider's First Name",
            part159: "Rider name",
            part160: "iService header",
            part161: "Entity",
            part162: "Business Unit",
            part163: "Cost center",
            part164: "File closed date",
            part165: "Driver's email address",
            part166: "Address",
            part167: "Postal code",
            part168: "Place",
            part169: "Phone Number",
            part170: "License plate current lease car",
            part171: "Lease category employee",
            part172: "Lease category employee new",
            part173: "Year mileage",
            part174: "End date lease contract",
            part175: "Reason replacement",
            part176: "License plate type",
            part177: "Fuel type",
            part178: "Email address manager",
            part179: "Date of receipt of PDF with Randstad approval",
            part180: "File",
            part181: "Full name",
            part182: "Last name",
            part183: "Edit user",
            part184: "City",
            part185: "Language",
            part186: "Successfully saved users for EV:",
            part186a: "Successfully saved users for VS:",
            part187: "Error list:",
            part188: "Export to CSV",
            part_role: "Role",
            Automatic: "Automatic",
            Manual: "Manual",
          },
          validation: {
            v1: "WLTP is required",
            v2: "Brand is required",
            v3: "Model is required",
            v4: "Only alphabets are allowed",
            v6: "Model should not contain more than 32 chars",
            v7: "Car Type is required",
            v8: "Version is required",
            v9: "Special characters are not allowed",
            v10: "Please enter atleast 1 character",
            v11: "Version should not contain more than 32 characters",
            v12: "Enter a valid WLTP",
            v13: "Additional percentage is required",
            v14: "Enter a valid Additional percentage",
            v15: "Battery Capacity is required",
            v16: "Enter a valid Battery Capacity",
            v17: "Tax value is required",
            v18: "Enter a valid Tax value",
            v19: "Expected delivery time are required",
            v20: "Expected delivery time must be positive",
            v21: "Category is required",
            v22: "Only digits are allowed",
            v23: "Category should not contain more than 5 digits",
            v24: "Please enter the description",
            v25: "Description should not contain more than 500 characters",
            v26: "Description must contain atleast 2 characters",
            v27: "Please enter the color name",
            v28: "Please enter atleast 2 characters",
            v29: "Please upload atleast 1 image",
            v30: "You can only upload upto 5 images",
            v31: "Image size should be less than 1 MB",
            v32: "Invalid email address",
            v33: "Email is required",
            v34: "Name is required",
            v35: "Name should not contain more than 32 chars",
            v36: "Personnel is required",
            v37: "Personnel must be positive",
            v38: "Enter a valid Telephone number",
            v39: "Mobile number is required",
            v40: "Invalid Mobile number",
            v41: "Mobile number is too long",
            v42: "Iservice job number is required",
            v43: "Label name is required",
            v44: "Label name should not contain more than 32 characters",
            v45: "Value is required",
            v46: "Value must be positive",
            v47: "Enter the valid Value",
            v48: "Color name is required",
            v49: "Color name should not contain more than 32 chars",
            v50: "You can only add 6 choosable options",
            v51: "Maximum 32 Characters are allowed",
            v51a: "The Entered value is too long",
            v52: "Email is required",
            v53: "Invalid email address",
            v54: "Password is required",
            v55: "Old password is required",
            v56: "New password is required",
            v57: "Retry password is required",
            v58: "Password must match",
            v59: "Password cannot be larger than 20 characters",
            v60: "Email address cannot be larger than 50 characters",
            v61: "Password must contain at least 8 characters, one uppercase, one number and one special case character",
            v62: "No file choosen!",
            v63: "Please select either ev category or long range category",
            v64: "Maximum 32 characters are allowed",
            v65: "Full name is required",
            v66: "Entity is required",
            v67: "Task number is required",
            v68: "Iservice header is required",
            v69: "Address is required",
            v70: "Part time % is required",
            v71: "Maximum 10 digits are allowed",
            v72: "Please select a color",
            v73: "Please add one color",
            v74: "City is required",
            v75: "Maximum allowed value is 100",
            v76: "City is required",
            v100: "Maximum length exceeded",
            charging_err: "Make a choice",
          },
          dashboard: {
            my: "My ",
            new_user: "New User",
            active_user: "Active User",
            complete_user: "Completed User",
            total_users: "Total Users",
            total_cars: "Total cars",
            outstanding_choices: "Outstanding Choices",
            successful_choices: "Successful Choices",
            new_car: "New Car",
            published_car: "Published Car",
            unpublished_car: "Unpublished Car",
            user_data: "User Data",
            weekly_report: "Weekly Report",
            month: "Month",
            year: "Year",
            car_data: "Car Data",
            users_weekly_report: "Users Weekly Report",
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December",
            new: "New",
            active: "Active",
            complete: "Completed",
            published: "Published",
            unpublished: "Unpublished",
            users: "Users",
            days: " days",
            day: " day",
          },
        },
      },
      de: {
        translation: {
          language: {
            part1: "Naar Overzicht",
            part2: "Opties",
            part3: "Kleur",
            part4: "Range",
            part5: "Bijtelling",
            part6: "Batterij Capaciteit",
            part7: "Fiscale Waarde",
            part8: "Verwachte levertijd",
            part9: "Lease Categorie",
            part9a: "EV Categorie",
            part10: "Laadvoorziening thuis",
            part11: "Kiesbare Optie",
            part12: "Trekhaak",
            part13: "Trekhaak",
            part14: "Warmtepomp",
            part15: "Totale eigen bijdrage",
            part16: "Dit wordt mijn elektrische auto",
            part17: "Per maand",
            part18: "Automatische piloot fase 3",
            part19: "19 inch lichtmetalen wielen (type ultraturbine)",
            part20: "Rijbaanassistent niveau 4",
            part21: "Klimaatcontrole",
            part22: "enz",
            part23: "Beschrijving",
            part24: "Merk",
            part25: "Model",
            part26: "Uitvoering",
            part27: "Beschrijving Auto",
            part28: "Oplaadpunten charge",
            part29: "Laadpunt kosten",
            part30: "Trekhaakkosten per maand",
            part31: "Warmtepompkosten per maand",
            part32: "Totale eigen bijdrage per maand",
            part33: "Best Buys",
            part34: "Standaard Aankopen",
            part35: "Prestatie",
            part36: "Opslaan",
            part37: "Publiceren",
            part38: "Voertuig Handmatig Toevoegen",
            part39: "Basisgegevens",
            part40: "Sleep je afbeeldingen hierheen",
            part41: "Upload afbeelding min 1 en max 5",
            part42:
              "Aanbevolen beeldresolutie 600*450 en grootte kleiner dan 1 MB",
            part43: "Versie",
            part44: "Weergave",
            part45: "Versie",
            part46: "Eigen bijdrage",
            part47: "Hoe werkt het?",
            part48: "Best Buys",
            part49: "Standaard Auto",
            part50: "Verwijderen",
            part51: "Depubliceren",
            part52: "Aantal deuren",
            part54: "Ja",
            part55: "Nee",
            part56: "Naam",
            part57: "Personeelsnummer",
            part58: "E-mailadres",
            part59: "Mobiel nummer",
            part60: "Iservice-taaknummer",
            part61: `De vraag of je een laadvoorziening selecteert hangt af van je woonsituatie. Heb je de mogelijkheid om een laadvoorziening te monteren aan je huis en ben je bereid eventuele extra kosten te dragen voor het aanpassen van de meterkast of extra graafwerkzaamheden dan kies je hier JA. 
            Woon je in een appartement of heb je geen mogelijkheid om een laadvoorziening te monteren aan je huis dan kies je hier NEE. Als je geen eigen laadvoorziening hebt kan je natuurlijk laden bij openbare laadvoorzieningen (vaak dichtbij) en op kantoor of bij klantlocaties.`,
            part62: `Nadat u op onderstaande knop heeft geklikt sturen wij u een gebruikersovereenkomst. Daarmee geeft u aan akkoord te gaan met de keuze en eventuele kosten van deze elektrische auto.`,
            part63: `Ik wil graag deze elektrische auto bestellen`,
            part64: `Bedankt voor de bevestiging van je bestelling. `,
            part64a: `We gaan aan de slag om een gebruikersovereenkomst op te stellen die we per mail naar je toesturen. Als je deze wil terugsturen met Akkoord dan gaan we je auto daadwerkelijk in bestelling plaatsen.`,
            part65: `
            Zodra er een indicatie is van de te verwachte afleverdatum zal de leasemaatschappij die jouw auto zal leveren dit met jou en ons delen. Dit bericht mag je binnen nu en een week verwachten. `,
            part66: `Bedankt voor jouw aandeel in het realiseren van de duurzaamheidsambitie van Randstad Groep Nederland.  Je kunt de pagina nu sluiten, je configuratie is in goede orde ontvangen.`,
            part67: `Bedankt voor het bevestigen van je bestelling`,
            part68: `Publiceren`,
            part69: `Depubliceren`,
            part70: "Verwijderen",
            part71: "Afsluiten",
            part72: "Zoeken",
            part73: "Uitgebreid zoeken",
            part74: "Verwijderen",
            part75: "Bewerking",
            part76: "Selecteer een merk",
            part77: "Auto toevoegen",
            part78: "Kleur Toevoegen",
            part79: "Kleur Naam",
            part80: "Verwijderen",
            part81: "Optie Toevoegen",
            part82: "Kiesbare Optie Toevoegen",
            part83: "Waarde",
            part84: "Bladeren",
            part85: "Optie Naam",
            part86: "Weet u het zeker, u wilt deze auto verwijderen?",
            part86a: "Weet u zeker dat u deze gebruiker wilt verwijderen?",
            part87: "Meer Laden",
            part88: "Eigen Bijdrage",
            part89: "Gekozen Opties",
            part90: "Geen gekozen opties",
            part91: "Bijwerken",
            part92: "Voertuig Handmatig Bewerken",
            part93: "Naam Werknemer",
            part94: "Iservicenummer Werknemer",
            part95: "Personeelsnummer Werknemer",
            part96: "Mobiel Nummer van Werknemer",
            part97: "Oplaadpunt",
            part98:
              "Hallo, U heeft een nieuwe vraag voor een auto.Hier zijn de autodetails:",
            part99: "Gast",
            part100:
              "Log in en maak gebruik van de keuzeopties van jouw werkgever",
            part101: "Nieuw bij Fleet support?",
            part102: "Maak een account aan",
            part103: "Gebruikersdetails",
            part104: "Autodetails",
            part105: "Maanden",
            part106: "U heeft deze optie al toegevoegd.",
            part107: "We zullen u spoedig antwoorden",
            part108:
              "Fleet Support voert namens Randstad Groep Nederland het wagenparkbeheer uit. Gelet op de ontwikkelingen binnen Randstad om enkel EV auto’s te bestellen is gekozen om deze portal beschikbaar te stellen voor medewerkers van Randstad om hun volgende leaseauto te kiezen.",
            part109:
              "Je kunt in deze portal kiezen welke elektrische auto je wilt bestellen. Het werkt eenvoudig:",
            part110:
              "Je kiest de auto en het model van je voorkeur Je kiest de kleur van de auto",
            part111:
              "Je geeft aan of je een laadvoorziening wenst of niet",
            part112:
              "Je selecteert een eventuele optie die geboden kan worden binnen het aanbod",
            part113:
              "Vervolgens controleren wij de bestelling op volledigheid en juistheid en zorgen we er samen voor dat jouw nieuwe elektrische auto besteld wordt en er tijdig een laadvoorziening wordt geplaatst als je hier voor hebt gekozen.",
            part114: "Moet ik een laadvoorziening selecteren",
            part115: "",
            part116: "Gebruikersnaam",
            part117: "Log in",
            part118: "Doorgaan met",
            part1000:
              "De bijtelling wordt vastgesteld op het moment dat de auto op kenteken wordt gezet, lees ",
            part1001: "hier",
            part1002: "meer",
            part1010: "Wachtwoord",
            part1011: "Wijzig wachtwoord",
            part1012: "Oud Wachtwoord ",
            part1013: "Nieuw Wachtwoord",
            part1014: "Herhaal nieuw wachtwoord",
            part1015: "Wil je je wachtwoord niet wijzigen?",
            part1016: "log dan in",
            part119: "Gebruikers Importeren",
            part120: "Kies Bestand",
            part121: "Geen bestand gekozen",
            part122: "Importeren",
            part123: "Bijtelling",
            part124:
              "Via onderstaand overzicht kunt u de fiscale bijtelling van uw elektrische auto van de zaak vaststellen. Hiermee kunt vaststellen welk bedrag u bij uw bruto inkomen moet optellen. De fiscale bijtelling wordt vastgesteld op het moment dat de auto op kenteken wordt gezet. Dus niet wanneer de auto wordt besteld.",
            part125:
              "De bijtelling voor alle leaseauto's is de afgelopen jaren geleidelijk verhoogd naar 22 procent en enkel volledig elektrische auto's krijgen in 2021 een extra stimulans door een bijtelling van 12 procent (red. tot een cataloguswaarde van 40.000 euro, daarboven geldt het algemene bijtellingspercentage van 22 procent). De bijtelling staat nadat de auto voor het eerst op kenteken is gezet voor vijf jaar vast. Daarna is voor wat betreft de bijtelling de dan van toepassing zijnde wetgeving van kracht",
            part126:
              "Bijtelling elektrische leaseauto's in 2021 (brandstofvoertuigen blijven 22%)",
            part127: "tot",
            part128: "daarboven",
            part129: "Inloggen",
            part130: "Welkom",
            part131: "Bijtelling Elektrische auto’s?",
            part132: "Moet ik een laadvoorziening selecteren?",
            part133:
              "De vraag of je een laadvoorziening selecteert hangt af van je woonsituatie. Heb je de mogelijkheid om een laadvoorziening te monteren aan je huis en ben je bereid eventuele extra kosten te dragen voor het aanpassen van de meterkast of extra graafwerkzaamheden dan kies je hier JA. Woon je in een appartement of heb je geen mogelijkheid om een laadvoorziening te monteren aan je huis dan kies je hier NEE. Als je geen eigen laadvoorziening hebt kan je natuurlijk laden bij openbare laadvoorzieningen (vaak dichtbij) en op kantoor of bij klantlocaties",
            part134: "Privacybeleid",
            part135: "Shift. Alle rechten voorbehouden.",
            part136: "Lange afstand categorie",
            part137: "Nieuw wachtwoord instellen",
            part138: "Onthoud je je laatste wachtwoord?",
            part139: "Wachtwoord vergeten",
            part140: "Voer het e-mailadres in waar je mee inlogt bij Shift.",
            part141: "Wachtwoord opnieuw instellen",
            part142: "terug naar Inloggen",
            part143: "Stel een wachtwoord in",
            part144: "Mijn gebruikers",
            part145: "Mijn Auto's",
            part146: "Zoeken",
            part147: "Organisatie naam",
            part148: "Gebruiker aangemaakt op",
            part149: "Gebruikerstype",
            part150: "Gebruiker geüpdatet op",
            part151: "Vorig",
            part152: "Volgende",
            part153: "Getekend contract",
            part154: "Gemaakt op",
            part155: "Toestand",
            part156: "Personeels nummer",
            part157: "Tasknummer",
            part158: "Voornaam berijder",
            part159: "Berijdernaam",
            part160: "iService kop",
            part161: "Entiteit",
            part162: "Bedrijfs onderdeel",
            part163: "Kostenplaats",
            part164: "Datum dossier afgesloten",
            part165: "Emailadres berijder",
            part166: "Adres",
            part167: "Postcode",
            part168: "Plaats",
            part169: "Telefoonnummer berijder",
            part170: "Kenteken huidige leaseauto",
            part171: "Leasecategorie medewerker huidig",
            part172: "Leasecategorie medewerker nieuw",
            part173: "Jaar kilometrage",
            part174: "Einddatum lease contract",
            part175: "Reden vervanging",
            part176: "Type kenteken",
            part177: "Type brandstof",
            part178: "Emailadres manager",
            part179: "Datum ontvangst pdf met akkoord Randstad",
            part180: "Dossier",
            part181: "Voor-en achternaam",
            part182: "Achternaam",
            part183: "Bewerk gebruiker",
            part184: "Stad",
            part185: "Taal",
            part186: "Gebruikers voor EV zijn opgeslagen:",
            part186a: "Gebruikers voor VS zijn opgeslagen:",
            part187: "Foutenlijst",
            part188: "Exporteren to CSV",
            part_role: "Rol",
            Automatic: "Automatisch",
            Manual: "Handmatig",
          },
          validation: {
            v1: "Range is vereist",
            v2: "Merk is vereist",
            v3: "Model is vereist",
            v4: "Alleen alfabetten zijn toegestaan",
            v5: "Merk mag niet meer dan 32 tekens bevatten",
            v6: "Het model mag niet meer dan 32 tekens bevatten",
            v7: "Autotype is vereist",
            v8: "Versie is vereist",
            v9: "Speciale karakters zijn niet toegestaan",
            v10: "Voer minimaal 1 teken in",
            v11: "Versie mag niet meer dan 32 tekens bevatten",
            v12: "Voer een geldig Range in",
            v13: "Extra percentage is vereist",
            v14: "Voer een geldig Extra percentage in",
            v15: "Batterijcapaciteit is vereist",
            v16: "Voer een geldige batterijcapaciteit in",
            v17: "Belastingwaarde is vereist",
            v18: "Voer een geldige belastingwaarde in",
            v19: "Verwachte levertijd is vereist",
            v20: "Verwachte levertijd moet positief zijn",
            v21: "Categorie is verplicht",
            v22: "Alleen cijfers zijn toegestaan",
            v23: "Categorie mag niet meer dan 5 cijfers bevatten",
            v24: "Voer de beschrijving in",
            v25: "Beschrijving mag niet meer dan 500 tekens bevatten",
            v26: "Beschrijving moet minimaal 2 tekens bevatten",
            v27: "Voer de kleurnaam in",
            v28: "Vul a.u.b. minimaal 2 tekens in",
            v29: "Upload a.u.b. minimaal 1 afbeelding",
            v30: "U kunt maximaal 5 afbeeldingen uploaden",
            v31: "De afbeeldingsgrootte moet kleiner zijn dan 1 MB",
            v32: "Ongeldig e-mailadres",
            v33: "E-mail is vereist",
            v34: "Naam is vereist",
            v35: "Naam mag niet meer dan 32 tekens bevatten",
            v36: "Personeel is vereist",
            v37: "Personeel moet positief zijn",
            v38: "Voer een geldig telefoonnummer in",
            v39: "Mobiel nummer is vereist",
            v40: "Ongeldig telefoonnummer",
            v41: "Mobiel nummer is te lang",
            v42: "Iservice-taaknummer is vereist",
            v43: "Labelnaam is vereist",
            v44: "Labelnaam mag niet meer dan 32 tekens bevatten",
            v45: "Waarde is vereist",
            v46: "Waarde moet positief zijn",
            v47: "Voer de geldige waarde in",
            v48: "Kleurnaam is vereist",
            v49: "Kleurnaam mag niet meer dan 32 tekens bevatten",
            v50: "U kunt slechts 6 kiesbare opties toevoegen",
            v51: "Maximaal 32 tekens zijn toegestaan",
            v51a: "Ingevoerde waarde is te lang",
            v52: "E-mail is vereist",
            v53: "Ongeldig e-mailadres",
            v54: "Een wachtwoord is verplicht",
            v55: "Oud wachtwoord is vereist",
            v56: "nieuw wachtwoord is vereist",
            v57: "Wachtwoord opnieuw proberen is vereist",
            v58: "Wachtwoorden moeten overeenkomen",
            v59: "Wachtwoord mag niet groter zijn dan 20 tekens",
            v60: "E-mailadres mag niet groter zijn dan 50 tekens",
            v61: "Wachtwoord moet minimaal 8 tekens bevatten, één hoofdletter, één cijfer en één speciaal teken",
            v62: "Geen bestand gekozen",
            v63: "Selecteer a.u.b. elke categorie of lange afstand categorie.",
            v64: "Maximaal 32 tekens zijn toegestaan",
            v65: "Volledige naam is vereist",
            v66: "Entiteit is vereist",
            v67: "Taaknummer is vereist",
            v68: "iService-kop is vereist",
            v69: "Adres is vereist",
            v70: "Parttime % is vereist",
            v71: "Maximaal 10 cijfers zijn toegestaan",
            v72: "Selecteer een kleur a.u.b.",
            v73: "Voeg één kleur toe..",
            v74: "Stad is vereist",
            v75: "Maximaal toegestane waarde is 100",
            v76: "Stad is vereist",
            v100: "Maximale lengte overschreden",
            charging_err: "Maak een keuze",
          },
          dashboard: {
            my: "Mijn",
            new_user: "Nieuwe gebruiker",
            active_user: "Actief gebruiker",
            complete_user: "Volledige gebruikers",
            total_users: "Totaal gebruikers",
            total_cars: "Totaal auto's",
            outstanding_choices: "Uitstekende keuzes",
            successful_choices: "Succesvolle keuzes",
            new_car: "Nieuwe auto",
            published_car: "Gepubliceerde auto",
            unpublished_car: "Degepubliceerde auto",
            user_data: "Gebruikers gegevens",
            weekly_report: "Wekelijks verslag",
            month: "Maand",
            year: "Jaar",
            car_data: "Auto gegevens",
            users_weekly_report: "Wekelijks gebruikers rapport",
            January: "Januari",
            February: "Februari",
            March: "Maart",
            April: "April",
            May: "Mei",
            June: "Juni",
            July: "Juli",
            August: "Augustus",
            September: "September",
            October: "Oktober",
            November: "November",
            December: "December",
            new: "Nieuwe",
            active: "Actief",
            complete: "Volledige",
            published: "Gepubliceerd",
            unpublished: "Degepubliceerd",
            users: "Gebruikers",
            days: " dagen",
            day: " dag",
          },
        },
      },
    },
  });

export default i18n;
