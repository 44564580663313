const ACCESS_TOKEN = "ACCESS_TOKEN";
const VS_USER_TYPE = "VS_USER_TYPE";
const EV_LOGIN = "EV_LOGIN";
const REFRESH_TOKEN = "REFRESH_TOKEN";


// getters and setter methods for getitng and saving the data into localstorage
export default {
  getUserType() {
    return localStorage.getItem(VS_USER_TYPE);
  },

  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  },

  getUsername() {
    return localStorage.getItem("u_name");
  },

  getDateLogin() {
    return localStorage.getItem(EV_LOGIN);
  },

  setDateLogin() {
    localStorage.setItem(EV_LOGIN, new Date().getTime());
  },

  setTokens(access) {
    localStorage.setItem(ACCESS_TOKEN, access);
  },

  setUserType(role) {
    localStorage.setItem(VS_USER_TYPE, role);
  },

  setRefreshToken(Token) {
    localStorage.setItem(REFRESH_TOKEN, Token);
  },

  setUsername(value) {
    localStorage.setItem("u_name", value);
  },

  async resetLoginStore() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(EV_LOGIN);
    localStorage.removeItem(VS_USER_TYPE);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem("u_cat");
    localStorage.removeItem("persist:lsd");
    window.location.reload()
  },

  get isAuthenticated() {
    try {
      return !!this.getAccessToken();
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};
