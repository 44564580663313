import React, { useEffect, useState, Fragment } from "react";
import Add_new_expand from "../../asset/add-new-expand.svg";
import Add_new_collapsed from "../../asset/add-new-collapsed.svg";
import import1 from "../../asset/import1.svg";
import uploadcar from "../../asset/uploadcar.svg";
import import1_nl from "../../asset/import1_nl.svg";
import uploadcar_nl from "../../asset/uploadcar_nl.svg";
import { useHistory, useLocation } from "react-router-dom";

import ImportUsers from ".././modals/ImportUsers";
import { ADD_NEW_VEHICLE } from "../../constants/routes";
import { setImportUsers } from "../../modules/allCars";
import { useDispatch, useSelector } from "react-redux";

export default function AddNew(params) {
  const [showAddNew, setShowAddNew] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    importusers,
    userlng,
    } = useSelector((state) => state.allCarsState);

  const closeImport = () => {
    setShowAddNew(false);
    dispatch(setImportUsers(false));
  };

  return (
    <>
      <div className="upload-addNew">
        <img
          src={showAddNew ? Add_new_expand : Add_new_collapsed}
          onClick={() => setShowAddNew((show) => !show)}
        ></img>
      </div>

      {showAddNew && (
        <>
          <div className="import1">
            <img
              src={userlng === "de" ? import1_nl : import1}
              onClick={() => {
                setShowAddNew(false);
                dispatch(setImportUsers(true));
              }}
            ></img>
          </div>
          <div className="add-manually">
            <img
              src={userlng === "de" ? uploadcar_nl : uploadcar}
              onClick={() => history.push(ADD_NEW_VEHICLE)}
            ></img>
          </div>
        </>
      )}
      {importusers && (
        <ImportUsers show={importusers} handleClose={closeImport} />
      )}
    </>
  );
}
