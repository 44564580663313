import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Close from "../../asset/close-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../utils/commonUtils";
import { useHistory } from "react-router-dom";
import { getUserContribution, sendEmail } from "../../modules/allCars";

const Ask = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const { t } = useTranslation();

  const { myCar } = useSelector((state) => state.myCarState);
  const { userlng,contribution_spinner } = useSelector((state) => state.allCarsState);
  const { user_data } = useSelector((state) => state.authState);

  const initialValues = {
    email: user_data?.email,
    name: user_data?.full_name,
    personnel_number: "",
    mobile_number: "",
    iservice_job_number: "",
  };

  const validationSchema = yup.object({
    // email: yup
    //   .string()
    //   .email(t("validation.v32"))
    //   .required(t("validation.v33")),
    // name: yup
    //   .string()
    //   .required(t("validation.v34"))
    //   .max(32, t("validation.v35")),
    // personnel_number: yup
    //   .string()
    //   .required(t("validation.v36"))
    //   .max(32, t("validation.v38")),
    // mobile_number: yup
    //   .string()
    //   .required(t("validation.v39"))
    //   .matches(phoneRegex, t("validation.v40"))
    //   .max(14, t("validation.v41")),
    // iservice_job_number: yup
    //   .string()
    //   .required(t("validation.v42"))
    //   .max(32, t("validation.v51")),
  });

  const [showCloseModal, setShowCloseModal] = useState(false);

  const getOwnContribution = () => {
    let sum = 0;
    props.filters.map(
      (i) => (sum = parseFloat(sum) + parseFloat(i.option_value))
    );
    return sum.toFixed(2);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <div
        className="d-flex justify-content-end"
        style={{ padding: "21px 30px 0" }}
      >
        <img
          src={Close}
          onClick={props.handleClose}
          style={{ cursor: "pointer" }}
          alt=""
        ></img>
      </div>
      <div className="popup-content">
        <div className="d-flex justify-content-between my-2">
          <h2 className="popup-title">{`${myCar.brand_en} ${myCar.model_en}`}</h2>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            let payload = {
              car_id: myCar.id,
              brand: myCar.brand_en,
              model: myCar.model_en,
              version: myCar?.version || "NA",
              wltp_range:
                (myCar?.wltp_range && myCar?.wltp_range.toString()) || "NA",
              additional_percentage:
                (myCar?.additional_percentage &&
                  myCar?.additional_percentage.toString()) ||
                "NA",
              tax_value:
                (myCar?.tax_value && myCar?.tax_value.toString()) || "NA",
              category: (myCar?.category && myCar?.category.toString()) || "NA",
              charging: props?.charging.toString()
                ? t(`language.${props?.charging.toString()}`)
                : t(`language.part55`),
              expected_delivery_time: myCar?.expected_delivery_time || "NA",
              bettery_capacity: myCar?.battery_capacity || "NA",
              description: myCar?.description_en || "NA",
              color: props.color
                ? {
                    code:
                      props.color &&
                      myCar?.color.filter(
                        (i) => i.color_name === props.color
                      )[0].hex_code,
                    name: props.color,
                  }
                : { code: "NA", name: "NA" },
              choosable_options: props.filters.length ? props.filters : "NA",
              choosed_option_sum: getOwnContribution(),
              fuel: "Elektrisch",
              body: "Metalic",
              name: values.name,
              email: values.email,
              mobile_number: values.mobile_number,
              iservice_job_number: values.iservice_job_number,
              address: user_data?.address || "NA",
              personnel_number: values.personnel_number.toString(),
              lng: userlng,
            };
            console.log(props.color);
            dispatch(getUserContribution({ payload: payload, history, props }));
            
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div>
                <div className="form-group">
                  <p className="car-info">
                    <div className="row car-detail1">
                      <div className="col-md-4">
                        <div>
                          <span>{t("language.part4")}</span>
                          <p style={{ marginBottom: "0px" }}>
                            {(myCar.wltp_range && myCar.wltp_range + " km") ||
                              "NA"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <span>{t("language.part7")}</span>
                          <p style={{ marginBottom: "0px" }}>
                            {("€ " + (Number(myCar.tax_value) + Number(getOwnContribution()) * 48.0)) || "NA"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <span>{t("language.part6")}</span>
                          <p style={{ marginBottom: "0px" }}>
                            {(myCar.battery_capacity &&
                              myCar.battery_capacity + " kWh") ||
                              "NA"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </p>
                  <p className="car-info">
                    <div className="row car-detail1">
                      <div className="col-md-4">
                        <div>
                          <span>{t("language.part5")}</span>
                          <p style={{ marginBottom: "0px" }}>
                            {myCar.additional_percentage
                              ? myCar.additional_percentage + " %"
                              : "n.t.b."}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <span>{t("language.part10")}</span>
                          <p style={{ marginBottom: "0px" }}>
                            {props.charging.toString()
                              ? t(`language.${props.charging.toString()}`)
                              : t("language.part55")}
                            {/* {props.color ? props.color : "NA"} */}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <span>{t("language.part3")}</span>
                          <p style={{ marginBottom: "0px" }}>
                            {props.color ? props.color : "NA"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </p>
                  <p className="car-info">
                    <div className="row car-detail1">
                      <div className="col-md-4">
                        <div>
                          <span>{t("language.part9")}</span>
                          <p style={{ marginBottom: "0px" }}>
                            {getCategory(myCar.category) || "NA"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </p>
                  <div className="d-flex row justify-content-between">
                    <p className="car-info col-md-7">
                      <div className="row car-detail ask-details">
                        <div className="col-md-12">
                          <div>
                            <span style={{ fontFamily: "OpenSans-SemiBold" }}>
                              {t("language.part89")}
                            </span>
                            {props.filters.length ? (
                              props.filters.map((i) => (
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    marginTop: "10px",
                                  }}
                                >
                                  {userlng === "en"
                                    ? i.option_name_en
                                    : i.option_name_nl}
                                  : € {parseFloat(i.option_value).toFixed(2)}
                                </p>
                              ))
                            ) : (
                              <p
                                className="font-italic"
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "10px",
                                }}
                              >
                                {t("language.part90")}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </p>
                    <div className="coin col-md-5">
                      <span>{t("language.part88")}</span>
                      <h2
                        style={{
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                      >
                        € {getOwnContribution()}
                      </h2>
                      <span>{t("language.part17")}</span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center form-group">
                  <label
                    style={{
                      fontSize: "13px",
                      paddingRight: "17px",
                      lineHeight: "30px",
                      // fontFamily: "Open Sans,Semibold",
                    }}
                    htmlFor="terms"
                  >
                    {t("language.part62")}
                  </label>
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" class={contribution_spinner && "button-opacity"} onClick={() => setShowCloseModal(true)}>
                    {t("language.part63")}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default Ask;
