import React, { useEffect, useState } from "react";

import Banner from "../asset/banner-tint.png";

const AboutUs = (props) => {
  
  return (
    <div>
      <div className="position-relative page-banner about">
        <img src={Banner} alt=""></img>
        <div className="position-absolute banner-content">
          <div>
            <h2>
              Volledig elektrisch rijden binnen Randstad Groep Nederland. Lees
              hier hoe het werkt.
            </h2>
            <button disabled={true} className="mt-4">
              <span>Hoe werkt het?</span>
            </button>
          </div>
        </div>
      </div>
      <div className="main-container">
        <p className="about-content">
          Fleet Support voert namens Randstad Groep Nederland het
          wagenparkbeheer uit. Gelet op de ontwikkelingen binnen Randstad om
          enkel EV auto’s te bestellen is gekozen om deze portal beschikbaar te
          stellen voor medewerkers van Randstad om hun volgende leaseauto te
          kiezen.
        </p>
        <div className="about-graphic">
          <p className="about-content">
            Je kunt in deze portal kiezen welke elektrische auto je wilt
            bestellen. Het werkt eenvoudig:
          </p>
          <p className="about-content">
            1. Je kiest de auto, het model en de kleur van je voorkeur<br />
            2. Je geeft aan of je een laadvoorziening wenst of niet {" "}
            <br />
            3. Je selecteert een eventuele optie die geboden kan worden binnen het{" "}
            <br />
            aanbod
          </p>
          <p className="about-content">
            Vervolgens controleren wij de bestelling op volledigheid en
            juistheid.<br />We zorgen we er samen voor dat jouw nieuwe elektrische
            auto wordt besteld, en er tijdig een laadvoorziening wordt geplaatst
            indien je hiervoor hebt gekozen.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
