export const ROOT_PATH = "/";

export const AUTH_PATH = "/auth";
export const LOGIN_PATH = `${AUTH_PATH}/login`;
export const SIGNUP_PATH = `${AUTH_PATH}/signup`;

export const APP_PATH = "/app";
export const ALL_CARS_PATH = `/all-cars`;
export const MY_CARS_PATH = `/my-cars`;
export const CAR_DETAILS_PATH = `/car`;
export const ADD_NEW_VEHICLE = `/add-new`;
export const ALL_CARS_ACTION_PATH = "/all-cars/auth";
export const FAQ_PATH = `/faq`;
export const CONTACT_PATH = `/contact`;
export const ABOUT_US_PATH = `/how-we-work`;
export const ADMIN_CAR = `/admincar`;
export const CLOSE_MODAL = `/closemodal`;
export const MY_USERS = `/my-users`;
export const ACTIVE_USER = `/my-users/user/:id`;
export const INACTIVE_USER = `/inactive-user`;
export const EDIT_USER = `/my-user/user/edit`;
export const MY_DASHBOARD_PATH = "/dashboard";
