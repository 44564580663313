import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { useTranslation, Trans } from "react-i18next";

import Banner from "../asset/banner-tint.png";
const useStyles = makeStyles({
  faqHead: { fontSize: "24px", color: "#00475F", fontWeight: "bold" },
  faqContent: { fontSize: "24px", color: "#00475F" },
  shadowNone: {
    boxShadow: "none",
    marginBottom: "30px",
    "&::before": { height: "0" },
  },
  expandFaq: {
    height: "33px",
    width: "33px",
    borderRadius: "50%",
    backgroundColor: "#ffffff",
    border: "1px solid #009CDD",
    color: "#009CDD",
  },
});

const Faq = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    window.location.href = process.env.REACT_APP_FAQ_PAGE__;
  }, []);

  return (
    <div>
      <div className="position-relative page-banner faq">
        <img src={Banner}></img>
        <div className="position-absolute banner-content">
          <h2>Veelgestelde vragen</h2>
        </div>
      </div>
      <div className="main-container">
        <div className="text-center faq-head">
          <h1>Vind uw antwoord hier</h1>
        </div>

        <div className="faq-container">
          <Accordion className={classes.shadowNone}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandFaq} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faqHead}>
                Q. {t('language.part132')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqContent}>
                A. {t('language.part133')}.{" "}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.shadowNone}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandFaq} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faqHead}>
                Q. {t('language.part131')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqContent}>
                <h4 className="FAQ-2">
                  {t("language.part123")}
                  <br />
                  {/* You can use the overview below to determine the tax addition
                  of your company electric car. This allows you to determine
                  which amount you should add to your gross income. The tax
                  addition is determined when the car is registered. Not when
                  the car is ordered. */}
                  {t("language.part124")}
                  <br />
                  <br />
                  {t("language.part123")}
                </h4>
                {/* The addition for all lease cars has been gradually increased to
                22 percent in recent years and only fully electric cars will
                receive an extra incentive in 2021 by an addition of 12 percent
                (ed. to a list value of 40,000 euros, above that the general
                addition rate of 22 percent applies). ).The addition is fixed
                for five years after the car has been registered for the first
                time. After that, the applicable legislation will apply with
                regard to the addition */}
                {t("language.part125")}

                <br />
                <br />
                <h4 className="FAQ-2">{t("language.part126")}</h4>
                <table className="FAQ-table">
                  <tr>
                    <td>2021</td>
                    <td>12%</td>
                    <td>
                      {" "}
                      {t("language.part127")}
                      40.000
                    </td>
                    <td>
                      {" "}
                      {t("language.part128")}
                      22%
                    </td>
                  </tr>
                  <tr>
                    <td>2022</td>
                    <td>16%</td>
                    <td>
                      {" "}
                      {t("language.part127")}
                      35.000
                    </td>
                    <td>
                      {" "}
                      {t("language.part128")}
                      22%
                    </td>
                  </tr>
                  <tr>
                    <td>2021</td>
                    <td>16%</td>
                    <td>
                      {" "}
                      {t("language.part127")}
                      30.000
                    </td>
                    <td>
                      {" "}
                      {t("language.part128")}
                      22%
                    </td>
                  </tr>
                </table>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion className={classes.shadowNone}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandFaq} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faqHead}>Q. Wordt de auto thuisbezorgd?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqContent}>
                A. Ja, de auto wordt bij je thuis gebracht. Mocht je een auto in je bezit hebben waarvan het contract ten einde is dan nemen we die auto voor je mee. Zo heb je maximaal comfort van de autowissel.
                </Typography>
            </AccordionDetails>
          </Accordion> */}
        </div>
      </div>
    </div>
  );
};

export default Faq;
