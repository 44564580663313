import React from "react";
import ISO from "../asset/ISO_background.png";
import LinkedIn from "../asset/linkedin.png";
import Youtube from "../asset/youtube.png";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <footer>
        <div className="main-container">
          <div className="d-flex justify-content-between align-items-center footer-main">
            <div>
              <img src={ISO} height="55" alt="ISO"></img>
            </div>
            <div className="social-icons d-flex">
              {/* <div><a href="https://www.facebook.com/Fleet-Support-1713808672256910/" target="_blank"><img src="../../asset/facebook.png" alt="Facebook"></img></a></div> */}
              {/* <div><a href="#" target="_blank"><img src="../../asset/twitter.png" alt="Twitter"></img></a></div> */}
              <div>
                <a
                  href="https://www.linkedin.com/company/fleet-support/"
                  target="_blank"
                >
                  <img src={LinkedIn} alt="LinkedIn"></img>
                </a>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/channel/UCPxZ99PwMp9zD8-c49dG-6Q/featured"
                  target="_blank"
                >
                  <img src={Youtube} alt="YouTube"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-bar">
          <div className="d-flex justify-content-between main-container">
            <p>
              © {new Date().getFullYear()} {t("language.part135")}
            </p>
            <p>{t("language.part134")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Header;
