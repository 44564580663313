import apiservice from "../api/apiInstance";
import { getFiltersOption, setAlertMessage, setNLMessage } from "./allCars";
import { long_range } from "../utils/commonUtils";
import { parseInt } from "lodash";

export const ALL_CARS_DATA = "ALL_CARS_DATA";
export const SET_ALL_CARS = "SET_ALL_CARS";
export const SET_SAVE_CAR_SPINNER = "SET_SAVE_CAR_SPINNER";

export function setAllCars(params) {
  return {
    type: SET_ALL_CARS,
    payload: params,
  };
}

export function setSaveCarSpinner(flag) {
  return {
    type: SET_SAVE_CAR_SPINNER,
    payload: flag,
  };
}

export function saveCar(data) {
  return async (dispatch, getState) => {
    let { values, history } = data;
    values.wltp_range = parseInt(values.wltp_range);
    values.tax_value = parseInt(values.tax_value);
    values.battery_capacity = parseInt(values.battery_capacity);
    values.category = long_range.filter(
      (i) => i.label === values?.category
    )[0]?.value;
    values.long_range_vehicles_category = long_range.filter(
      (i) => i.label === values?.long_range_vehicles_category
    )[0]?.value;
    if (values.additional_percentage) {
      values.additional_percentage = parseInt(values.additional_percentage);
    } else if (values.additional_percentage === "") {
      values.additional_percentage = null;
    }
    let raw = [];
    raw.push(values);
    try {
      const response = await apiservice.post(`/ev`, JSON.stringify(raw));
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setAlertMessage(response.data.message, "success"));
        await dispatch(setNLMessage(response.data.message, "success"));
        await dispatch(getFiltersOption({ brand: [] }));
        await history.push("/my-cars");
        await data.resetForm({})
      } else {
        await history.push("/my-cars");
        response.data.message.map((item) => {
          Object.keys(item).map(async (i) => {
            await dispatch(setAlertMessage(item[i][0], "error"));
            await dispatch(setNLMessage(item[i][0], "error"));
          });
        });
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Interne Server Fout", "error"));
      // await dispatch(setCarsSpinner(false));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 5000);
  };
}

export function editCar(data) {
  return async (dispatch, getState) => {
    let { values, history } = data;
    values.color.map((item) => {
      item[`img1`] = null;
      item[`img2`] = null;
      item[`img3`] = null;
      item[`img4`] = null;
      item[`img5`] = null;
    });
    values.wltp_range = parseInt(values.wltp_range);
    values.tax_value = parseInt(values.tax_value);
    values.category = long_range.filter(
      (i) => i.label === values?.category
    )[0]?.value;
    values.battery_capacity = parseInt(values.battery_capacity);
    values.long_range_vehicles_category = long_range.filter(
      (i) => i.label === values?.long_range_vehicles_category
    )[0]?.value;
    if (values.additional_percentage) {
      values.additional_percentage = parseInt(values.additional_percentage);
    } else if (values.additional_percentage === "") {
      values.additional_percentage = null;
    }
    values.color.map((item) => {
      item.images.map((image, i) => {
        item[`img${i + 1}`] = image;
      });
    });

    try {
      const response = await apiservice.patch(
        `/ev/${values.id}`,
        JSON.stringify(values)
      );
      if (response.status === 200 && response.data.code === 200) {
        await dispatch(setAlertMessage("Car updated successfully", "success"));
        await dispatch(setNLMessage("Auto succesvol geüpdatet", "success"));
        await history.push("/my-cars");
        await data.resetForm()

        // await dispatch(setCarsSpinner(false));
      } else {
        await history.push("/my-cars");
        response.data.message.map((item) => {
          Object.keys(item).map(async (i) => {
            await dispatch(setAlertMessage(item[i][0], "error"));
            await dispatch(setNLMessage(item[i][0], "error"));
          });
        });
      }
    } catch (e) {
      // await dispatch(setCarsSpinner(false));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setNLMessage(""));
      await dispatch(setAlertMessage(""));
    }, 4000);
  };
}

export const initialState = {
  allcars: [],
  loginModal: false,
  changePwdModal: false,
  allCarsSpinner: false,
  alertMessage: {},
  uploadSpinner: false,
  userlng: "",
};

const ACTION_HANDLERS = {
  // [SET_DATA_UPLOAD_SPINNER]: (state, action) => {
  //   return {
  //     ...state,
  //     uploadSpinner: action.payload,
  //   };
  // },
  // [SET_USER_PREFFERED_LANGUAGE]: (state, action) => {
  //   return {
  //     ...state,
  //     userlng: action.payload,
  //   };
  // },
};

export default function allCarsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
