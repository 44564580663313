import { param } from "jquery";
import apiservice from "../api/apiInstance";

// definigng the types for
export const ALL_CARS_DATA = "ALL_CARS_DATA";
export const SET_LOGIN_MODAL = "SET_LOGIN_MODAL";
export const SET_CHANGE_PASSWORD_MODAL = "SET_CHANGE_PASSWORD_MODAL";
export const SET_ALL_CARS = "SET_ALL_CARS";
export const SET_BEST_SPINNER = "SET_BEST_SPINNER";
export const SET_STD_SPINNER = "SET_STD_SPINNER";
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const SET_DATA_UPLOAD_SPINNER = "SET_DATA_UPLOAD_SPINNER";
export const SET_USER_PREFFERED_LANGUAGE = "SET_USER_PREFFERED_LANGUAGE";
export const SET_IMPORT_USERS_MODAL = "SET_IMPORT_USERS_MODAL";
export const SET_STANDARD_CARS = "SET_STANDARD_CARS";
export const SET_BEST_CARS = "SET_BEST_CARS";
export const SET_FILTERS_OPTIONS = "SET_FILTERS_OPTIONS";
export const SET_FETCH_FILTERS_SPINNER_STATUS =
  "SET_FETCH_FILTERS_SPINNER_STATUS";
export const SET_FILTERS_VALUE = "SET_FILTERS_VALUE";
export const SET_ALERT_NL_MESSAGE = "SET_ALERT_NL_MESSAGE";
export const SET_THANK_YOU_MODAL = "SET_THANK_YOU_MODAL";
export const SET_RESERVE_CAR_MODAL = "SET_RESERVE_CAR_MODAL";
export const SET_BEST_PAGE_SPINNER = "SET_BEST_PAGE_SPINNER";
export const SET_STANDARD_PAGE_SPINNER = "SET_STANDARD_PAGE_SPINNER";
export const SET_USER_CONTRIBUTION = "SET_USER_CONTRIBUTION";
export const SET_AGREEMENT_SPINNER = "SET_AGREEMENT_SPINNER";
export const SET_USER_CONTRIBUTION_SPINNER_STATUS =
  "SET_USER_CONTRIBUTION_SPINNER_STATUS";
// defining the actions for setting the values to redux state
export function setLng(data) {
  return {
    type: SET_USER_PREFFERED_LANGUAGE,
    payload: data,
  };
}

export function setLoginModal(data) {
  return {
    type: SET_LOGIN_MODAL,
    payload: data,
  };
}

export function setChangePwdModal(data) {
  return {
    type: SET_CHANGE_PASSWORD_MODAL,
    payload: data,
  };
}

export function setBestCars(params) {
  return {
    type: SET_BEST_CARS,
    payload: params,
  };
}

export function setStandardCars(params) {
  return {
    type: SET_STANDARD_CARS,
    payload: params,
  };
}

export function setBestSpinner(flag) {
  return {
    type: SET_BEST_SPINNER,
    payload: flag,
  };
}

export function setStdSpinner(flag) {
  return {
    type: SET_STD_SPINNER,
    payload: flag,
  };
}

export function setReserveCarModal(params) {
  return {
    type: SET_RESERVE_CAR_MODAL,
    payload: params,
  };
}

export function setUploadSpinner(flag) {
  return {
    type: SET_DATA_UPLOAD_SPINNER,
    payload: flag,
  };
}

export function setAlertMessage(message, status) {
  return {
    type: SET_ALERT_MESSAGE,
    payload: { message, status },
  };
}

export function setNLMessage(message, status) {
  return {
    type: SET_ALERT_NL_MESSAGE,
    payload: { message, status },
  };
}

export function setImportUsers(params) {
  return {
    type: SET_IMPORT_USERS_MODAL,
    payload: params,
  };
}

export function setFilterSpinner(params) {
  return {
    type: SET_FETCH_FILTERS_SPINNER_STATUS,
    payload: params,
  };
}

export function setFilterOptions(data) {
  return {
    type: SET_FILTERS_OPTIONS,
    payload: data,
  };
}

export function setFilters(data) {
  return {
    type: SET_FILTERS_VALUE,
    payload: data,
  };
}

export function setThankyouModal(value) {
  return {
    type: SET_THANK_YOU_MODAL,
    payload: value,
  };
}

export function setBestPageSpinner(value) {
  return {
    type: SET_BEST_PAGE_SPINNER,
    payload: value,
  };
}

export function setStdPageSpinner(value) {
  return {
    type: SET_STANDARD_PAGE_SPINNER,
    payload: value,
  };
}

export function setUserContribution(params) {
  return {
    type: SET_USER_CONTRIBUTION,
    payload: params,
  };
}

export function setContributionSpinner(params) {
  return {
    type: SET_USER_CONTRIBUTION_SPINNER_STATUS,
    payload: params,
  };
}

export function setAgreementSpinner(params) {
  return {
    type: SET_AGREEMENT_SPINNER,
    payload: params,
  };
}

//  fetching dropdown filters data
export function getFiltersOption(data) {
  return async (dispatch, getState) => {
    await dispatch(setFilterSpinner(true));
    let str = [];
    if (data.brand && data.brand.length) {
      data.brand.map((i) => str.push(i["brand"]));
    }
    let { userlng } = getState().allCarsState;
    if (userlng === "de") userlng = "nl";
    try {
      const response = await apiservice.get(`/ev/dropdown`, {
        params: { brand: JSON.stringify(str) },
        headers: { language: userlng },
      });
      if (response.status === 200) {
        await dispatch(setFilterOptions(response.data));
        await dispatch(setFilterSpinner(false));
      } else {
        await dispatch(setFilterSpinner(false));
      }
    } catch (e) {
      await dispatch(setFilterSpinner(false));
      console.log(e);
    }
  };
}

// fetching all best cars
export function fetchBestCar(data) {
  return async (dispatch, getState) => {
    let cat = [],
      brand = [],
      model = [],
      version = [];
    if (data) {
      data?.category?.map((i) => cat.push(i["label"]));
      data?.brand?.map((i) => brand.push(i["brand"]));
      data?.model?.map((i) => model.push(i["model"]));
      data?.version?.map((i) => version.push(i["version"]));
    }
    let { userlng } = getState().allCarsState;
    if (userlng === "de") userlng = "nl";
    await dispatch(setBestSpinner(true));
    data.page > 1 && (await dispatch(setBestPageSpinner(true)));
    try {
      const response = await apiservice.get(`/ev/best_car`, {
        params: {
          category: JSON.stringify(cat),
          model: JSON.stringify(model),
          version: JSON.stringify(version),
          brand: JSON.stringify(brand),
          page: data.page,
        },
        headers: { language: userlng },
      });
      if (response.status === 200) {
        let { bestCars } = getState().allCarsState;
        if (data.page > 1) {
          response.data.data.results = [
            ...bestCars.data.results,
            ...response.data.data.results,
          ];
          await dispatch(setBestCars(response.data));
        } else {
          await dispatch(setBestCars(response.data));
        }
        await dispatch(setBestSpinner(false));
        await dispatch(setBestPageSpinner(false));
      } else {
        await dispatch(setBestSpinner(false));
        await dispatch(setBestPageSpinner(false));
      }
    } catch (e) {
      await dispatch(setBestSpinner(false));
      await dispatch(setBestPageSpinner(false));
      console.log(e);
    }
  };
}

// fetching all standard cars
export function fetchStandardCar(data) {
  return async (dispatch, getState) => {
    let cat = [],
      brand = [],
      model = [],
      version = [];
    if (data) {
      data?.category?.map((i) => cat.push(i["label"]));
      data?.brand?.map((i) => brand.push(i["brand"]));
      data?.model?.map((i) => model.push(i["model"]));
      data?.version?.map((i) => version.push(i["version"]));
    }
    await dispatch(setStdSpinner(true));
    data.page > 1 && (await dispatch(setStdPageSpinner(true)));
    let { userlng } = getState().allCarsState;
    if (userlng === "de") userlng = "nl";
    try {
      const response = await apiservice.get(`/ev/standard_car`, {
        params: {
          category: JSON.stringify(cat),
          model: JSON.stringify(model),
          version: JSON.stringify(version),
          brand: JSON.stringify(brand),
          page: data.page,
        },
        headers: { language: userlng },
      });

      if (response.status === 200) {
        let { stdCars } = getState().allCarsState;
        if (data.page > 1) {
          response.data.data.results = [
            ...stdCars.data.results,
            ...response.data.data.results,
          ];
          await dispatch(setStandardCars(response.data));
        } else {
          await dispatch(setStandardCars(response.data));
        }
        await dispatch(setStdSpinner(false));
        await dispatch(setStdPageSpinner(false));
      } else {
        await dispatch(setStdSpinner(false));
        await dispatch(setStdPageSpinner(false));
      }
    } catch (e) {
      await dispatch(setStdSpinner(false));
      await dispatch(setStdPageSpinner(false));
      console.log(e);
    }
  };
}

// sending definitely my car email
export function sendEmail(params) {
  console.log(params);
  return async (dispatch, getState) => {
    let { userlng } = getState().allCarsState;
    if (userlng === "de") userlng = "nl";
    await dispatch(setAgreementSpinner(true));

    try {
      const response = await apiservice.post(
        `/ev/agreement`,
        JSON.stringify(params),
        {
          headers: {
            language: userlng,
          },
        }
      );
      await dispatch(setAgreementSpinner(false));
      if (response.status === 200 && response.data.code == 200) {
        await dispatch(setAlertMessage(response.data.message, "success"));
        await dispatch(setNLMessage(response.data.message, "success"));
        await dispatch(setThankyouModal(true));
        await dispatch(setAgreementSpinner(false));
      } else {
        await dispatch(setAgreementSpinner(false));
      }
    } catch (e) {
      await dispatch(setAgreementSpinner(false));
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Internal server error", "error"));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 7000);
  };
}

// fetching the part time contribution for user
export function getUserContribution(data) {
  console.log(data);
  return async (dispatch, getState) => {
    const { userlng } = getState().allCarsState;
    await dispatch(setContributionSpinner(true));
    try {
      const response = await apiservice.post(
        `/ev/part_time_contributions`,
        JSON.stringify({ ...data.payload }),
        {
          headers: {
            language: userlng,
          },
        }
      );
      console.log(response);
      await dispatch(setContributionSpinner(false));
      if (response?.data?.code == 200) {
        await dispatch(setUserContribution(response?.data?.contribution));
        data.history.push({
          pathname: `/all-cars/car/${data.payload.car_id}/agreement`,
          state: {
            data: {
              ...data.props.data,
              ...data.payload,
              options: data.props.filters,
              colorChosen: data.props.color,
              charging: data.props.charging,
              range: data?.props?.data.wltp_range,
              fiscal_waarde: data?.props?.data.tax_value,
            },
            from: "all-cars",
          },
        });
      } else if (
        response?.data?.code != 200 &&
        response?.data?.contribution === null
      ) {
        await dispatch(setNLMessage(response?.data?.message_nl, "success"));
        await dispatch(setAlertMessage(response?.data?.message_en, "success"));
        await dispatch(setReserveCarModal(false));
        await dispatch(setThankyouModal(true));
      } else {
        await dispatch(setNLMessage(response?.data?.message, "error"));
        await dispatch(setAlertMessage(response?.data?.message, "error"));
      }
    } catch (e) {
      await dispatch(setAlertMessage("Internal server error", "error"));
      await dispatch(setNLMessage("Internal server error", "error"));
      await dispatch(setContributionSpinner(false));
      console.log(e);
    }
    setTimeout(async () => {
      await dispatch(setAlertMessage("", ""));
      await dispatch(setNLMessage("", ""));
    }, 7000);
  };
}

// current reducers's initial State
export const initialState = {
  bestCars: { current_page: 1 },
  stdCars: { current_page: 1 },
  loginModal: false,
  changePwdModal: false,
  bestSpinner: false,
  stdSpinner: false,
  alertMessage: {},
  uploadSpinner: false,
  userlng: "",
  importusers: false,
  filterSpinner: false,
  filterOptions: {},
  filters: {},
  nl_message: {},
  thankyoumodal: false,
  bestPageSpinner: false,
  stdPageSpinner: false,
  user_contribution: 0,
  contribution_spinner: false,
  reserve_modal: false,
  ageement_spinner: false,
};

// mapping the actions with initialstate
const ACTION_HANDLERS = {
  [SET_ALERT_MESSAGE]: (state, action) => {
    return {
      ...state,
      alertMessage: action.payload,
    };
  },
  [SET_ALERT_NL_MESSAGE]: (state, action) => {
    return {
      ...state,
      nl_message: action.payload,
    };
  },
  [SET_CHANGE_PASSWORD_MODAL]: (state, action) => {
    return {
      ...state,
      changePwdModal: action.payload,
    };
  },
  [SET_LOGIN_MODAL]: (state, action) => {
    return {
      ...state,
      loginModal: action.payload,
    };
  },
  [SET_BEST_CARS]: (state, action) => {
    return {
      ...state,
      bestCars: action.payload,
    };
  },
  [SET_STANDARD_CARS]: (state, action) => {
    return {
      ...state,
      stdCars: action.payload,
    };
  },
  [SET_BEST_SPINNER]: (state, action) => {
    return {
      ...state,
      bestSpinner: action.payload,
    };
  },
  [SET_STD_SPINNER]: (state, action) => {
    return {
      ...state,
      stdSpinner: action.payload,
    };
  },
  [SET_DATA_UPLOAD_SPINNER]: (state, action) => {
    return {
      ...state,
      uploadSpinner: action.payload,
    };
  },
  [SET_USER_PREFFERED_LANGUAGE]: (state, action) => {
    return {
      ...state,
      userlng: action.payload,
    };
  },
  [SET_IMPORT_USERS_MODAL]: (state, action) => {
    return {
      ...state,
      importusers: action.payload,
    };
  },
  [SET_FETCH_FILTERS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      filterSpinner: action.payload,
    };
  },
  [SET_FILTERS_OPTIONS]: (state, action) => {
    return {
      ...state,
      filterOptions: action.payload,
    };
  },
  [SET_FILTERS_VALUE]: (state, action) => {
    return {
      ...state,
      filters: action.payload,
    };
  },
  [SET_THANK_YOU_MODAL]: (state, action) => {
    return {
      ...state,
      thankyoumodal: action.payload,
    };
  },
  [SET_BEST_PAGE_SPINNER]: (state, action) => {
    return {
      ...state,
      bestPageSpinner: action.payload,
    };
  },
  [SET_STANDARD_PAGE_SPINNER]: (state, action) => {
    return {
      ...state,
      stdPageSpinner: action.payload,
    };
  },
  [SET_USER_CONTRIBUTION]: (state, action) => {
    return {
      ...state,
      user_contribution: action.payload,
    };
  },
  [SET_USER_CONTRIBUTION_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      contribution_spinner: action.payload,
    };
  },
  [SET_RESERVE_CAR_MODAL]: (state, action) => {
    return {
      ...state,
      reserve_modal: action.payload,
    };
  },
  [SET_AGREEMENT_SPINNER]: (state, action) => {
    return {
      ...state,
      ageement_spinner: action.payload,
    };
  },
};

//  exporting the reducer with updated data
export default function allCarsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
