import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import SHIFT from "../../asset/SHIFT-RGB.png";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword } from "../../modules/login";
import Close from "../../asset/close-icon.svg";

const Changepassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { passwordErr } = useSelector((state) => state.authState);
  const { userlng } = useSelector((state) => state.allCarsState);
  
  const { t } = useTranslation();
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    formikRef.current.setFieldValue(name, value.replace(" ", ""));
  };

  const handleMouseDownRetypePassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = yup.object({
    old_password: yup.string().required(t("validation.v55")),
    new_password: yup
      .string()
      .required(t("validation.v56"))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t("validation.v61")
      ),
    confirm_password: yup
      .string()
      .required(t("validation.v57"))
      .oneOf([yup.ref("new_password"), null], t("validation.v58")),
  });

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      keyboard={false}
      className="login-popup"
      backdrop="static"
    >
      <div className="position-relative popup-inner">
        <div className="text-right">
          <img
            src={Close}
            onClick={() => props.handleClose()}
            style={{ cursor: "pointer" }}
            alt=""
          ></img>
        </div>

        <div class="text-center">
          <img src={SHIFT} className="shift-img"></img>
        </div>
        <h3>{t("language.part1011")} </h3>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(ChangePassword(values));
          }}
        >
          {({ errors, values, touched }) => (
            <Form>
              <div className="form-group">
                <Input
                  placeholder={t("language.part1012")}
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  name="old_password"
                  onChange={(e) => handleChange(e)}
                  value={values.old_password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.old_password && touched.old_password && (
                  <small className="text-danger">{errors.old_password}</small>
                )}
                <Input
                  className="form-control"
                  placeholder={t("language.part1013")}
                  type={showNewPassword ? "text" : "password"}
                  onChange={(e) => handleChange(e)}
                  name="new_password"
                  value={values.new_password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={handleMouseDownNewPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.new_password && touched.new_password && (
                  <small className="text-danger">{errors.new_password}</small>
                )}
                <Input
                  className="form-control mb-2"
                  placeholder={t("language.part1014")}
                  type={showCPassword ? "text" : "password"}
                  name="confirm_password"
                  onChange={(e) => handleChange(e)}
                  value={values.confirm_password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowCPassword(!showCPassword)}
                        onMouseDown={handleMouseDownRetypePassword}
                      >
                        {showCPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.confirm_password && touched.confirm_password && (
                  <small className="text-danger">
                    {errors.confirm_password}
                  </small>
                )}
              </div>
               {passwordErr.msg_en && passwordErr.msg_nl &&  
                  <small className="text-danger">
                  {userlng==='de' ? passwordErr.msg_nl : passwordErr.msg_en}
                </small>
               }   
              <div className="d-flex flex-column align-items-center ">
                <div className="invalid-feedback"></div>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  class="login-btn btn-margin"
                >
                  {t("language.part1011")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default Changepassword;
