import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";



const CloseModal = (props) => {
  const { t } = useTranslation();
  const location = useLocation()
  let path = location?.pathname?.split('/')
  console.log(path.length);
  return (
    <Modal
      className="modal2"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <div
        className="d-flex justify-content-end"
        style={{ padding: "21px 21px 0" }}
      >
        <img
          src="../../asset/close-icon.svg"
          onClick={props.handleClose}
          style={{ cursor: "pointer" }}
          alt=""
        ></img>
      </div>
      <div className="popup-content">
        <Formik>
          
          {() => (
            <Form>
              <Modal.Body>
                <h4> {t("language.part67")}</h4>
                <p style={{ paddingRight: "75px" }}>
                  {t("language.part64")}{path.length==4 && t("language.part64a")}
                  <br></br>
                  <br></br>
                  {t("language.part65")}
                  <br></br>
                  <br></br>
                  {t("language.part66")}
                </p>
              </Modal.Body>
              <Modal.Footer style={{ padding: "0px" }}>
                <Button onClick={props.handleClose} style={{ margin: "0px" }}>
                  {t("language.part71")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CloseModal;
