import React, { Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import * as routes from "../constants/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import auth from "../helpers/auth";
import { Spinner } from "react-bootstrap";

import { CarList } from "../components";
// const CarList = React.lazy(() => import("../components/CarList"));
const Header = React.lazy(() => import("../components/Header"));
const Footer = React.lazy(() => import("../components/Footer"));
const CarDetails = React.lazy(() => import("../components/CarDetails"));
const AddNewVehicle = React.lazy(() => import("../components/AddNewVehicle"));
const Faq = React.lazy(() => import("../components/Faq"));
const ContactUs = React.lazy(() => import("../components/ContactUs"));
const AboutUs = React.lazy(() => import("../components/AboutUs"));
const MyCars = React.lazy(() => import("../components/MyCars"));
const MyUsers = React.lazy(() => import("../components/MyUsers"));
const User = React.lazy(() => import("../components/User"));
const EditUser = React.lazy(() => import("../components/EditUser"));
const Agreement = React.lazy(() => import("../components/Agreement"));
const Dashboard = React.lazy(() => import("../components/Agreement"));
const ScrollToTop = React.lazy(() => import("../components/ScrollToTop"));
const CloseModal = React.lazy(() => import("../components/modals/CloseModal"));
const NotFound = React.lazy(() => import("../components/modals/CloseModal"));
const AdminRoute = React.lazy(() => import("../Routes/AdminRoute"));
const ProtectedRoute = React.lazy(() => import("../Routes/ProtectedRoute"));
const SetNewPassword = React.lazy(() => import("../components/modals/SetNewPassword"));

require("dotenv").config();

function MainLayout() {
  let location = useLocation();
  let path = location.pathname.split("/")[4];
  // defining all the routes for components with catch-all-route in the end
  return (
    <Suspense fallback={<Spinner/>}>
      <ScrollToTop />
      {path != "agreement" && <Header />}
      <Switch>
        <Route
          exact
          path={routes.ROOT_PATH}
          render={(props) => {
            return <Redirect to={routes.ALL_CARS_PATH} />;
          }}
        />
        <Route
          exact
          path={routes.ALL_CARS_PATH}
          render={(props) =>
            auth.isAuthenticated ? (
              <CarList />
            ) : (
              <Redirect to={routes.ALL_CARS_ACTION_PATH} />
            )
          }
        />
        <Route
          path={routes.ALL_CARS_ACTION_PATH}
          render={(props) => {
            if (auth.isAuthenticated) {
              return <Redirect to={routes.ALL_CARS_PATH} />;
            } else {
              return <CarList />;
            }
          }}
        />

        <AdminRoute exact path={routes.MY_CARS_PATH} component={MyCars} />

        <AdminRoute
          exact
          path={routes.ADD_NEW_VEHICLE}
          component={AddNewVehicle}
        />

        <Route exact path={routes.FAQ_PATH} component={Faq} />
        <Route exact path={routes.CONTACT_PATH} component={ContactUs} />
        <Route exact path={routes.ABOUT_US_PATH} component={AboutUs} />
        <Route exact path={routes.CLOSE_MODAL} component={CloseModal} />
        <AdminRoute exact path={routes.MY_USERS} component={MyUsers} />
        <AdminRoute exact path={routes.ACTIVE_USER} component={User} />
        <AdminRoute exact path={routes.EDIT_USER} component={EditUser} />
        <AdminRoute exact path={"/dashboard"} component={Dashboard} />
        <Route
          exact
          path="/forgot-password"
          render={(props) => {
            if (auth.isAuthenticated) {
              return <Redirect to="/" />;
            } else {
              return <SetNewPassword />;
            }
          }}
        />

        <ProtectedRoute
          exact
          path={`${routes.ALL_CARS_PATH}${routes.CAR_DETAILS_PATH}/:car`}
          component={CarDetails}
        />
        <AdminRoute
          exact
          path={`${routes.MY_CARS_PATH}${routes.CAR_DETAILS_PATH}/:car`}
          component={CarDetails}
        />

        <AdminRoute
          path={`${routes.MY_CARS_PATH}/edit/:car`}
          component={AddNewVehicle}
        />
        <ProtectedRoute
          exact
          path="/all-cars/car/:car/agreement"
          component={Agreement}
        />
        {/*Catch all route  */}
        <Route render={() => <NotFound />} />
      </Switch>
      {path != "agreement" && <Footer />}
    </Suspense>
  );
}

export default MainLayout;
