import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { CAR_DETAILS_PATH, ALL_CARS_PATH } from "../../constants/routes";
import { Card, Spinner, Toast } from "react-bootstrap";
import { categories_value, getCategory } from "../../utils/commonUtils";
import { getStringToDisplay } from "../../utils/commonUtils";
import Notfound from "../../asset/image_not_available.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";


export default function UserCar(props) {
  const [isMouseInside, setIsMouseInside] = useState({});
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { car, index } = props;

  const showSelectedCarDetails = (selectedCar) => {
    history.push({
      pathname: `${ALL_CARS_PATH}${CAR_DETAILS_PATH}/${selectedCar.id}`,
      state: {
        data: selectedCar,
        from: "all-cars",
      },
    });
  };

  const { t } = useTranslation();
  const { userlng } = useSelector((state) => state.allCarsState);

  const mouseEnter = (index) => {
    setIsMouseInside({ [index]: true });
  };

  const mouseLeave = (index) => {
    setIsMouseInside({ [index]: false });
  };

  return (
    <>
      <div
        className="col-md-4"
        style={{ paddingRight: "5px", paddingLeft: "5px" }}
      >
        <div className=" list-card-outer">
          <Card
            className="list-card"
            onMouseEnter={() => mouseEnter(index)}
            onMouseLeave={() => mouseLeave(index)}
          >
            <div style={{ height: "200px" }}>
              <img
                alt={car.model}
                className="img-fluid"
                style={{ objectFit: "cover" }}
                src={props?.cover || Notfound}
                loading='lazy'

              />
            </div>

            <div className="list-card-inner">
              <h4 style={{ marginBottom: "0px" }}>
                {getStringToDisplay(
                  car.brand + " " + car.model + "  " + car.version,
                  21,
                  true
                )}
              </h4>
              <div className="price">
                <div className="row mt-0">
                  <div className="col-6">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part4")}
                    </p>
                    <span>
                      {car.wltp_range || "NA"}
                      {car.wltp_range && " km"}
                    </span>
                  </div>
                  <div
                    className={
                      userlng === "en"
                        ? "col-6 col-6 add_percentage "
                        : "col-6 "
                    }
                  >
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part5")}
                    </p>
                    <span>
                      {car.additional_percentage
                        ? car.additional_percentage + " %"
                        : "n.t.b."}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part6")}
                    </p>
                    <span>
                      {car.battery_capacity || "NA"}{" "}
                      {car.battery_capacity && "kWh"}
                    </span>
                  </div>
                  <div
                    className={userlng === "en" ? "col-6" : "col-6 add-col-6"}
                  >
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part7")}
                    </p>
                    <span>
                      {car.tax_value && "€"} {car.tax_value || "NA"}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      userlng === "en"
                        ? "col-6 expected_delivery_time"
                        : "col-6 expected-d-6"
                    }
                  >
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part8")}
                    </p>
                    {car.expected_delivery_time ? (
                      <span>{car.expected_delivery_time} </span>
                    ) : (
                      <span>{"N.N.B"}</span>
                    )}
                  </div>
                  <div className="col-6 ">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part9")}
                    </p>
                    <span>{getCategory(car.category) || "NA"}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p style={{ fontWeight: "normal" }}>
                      {t("language.part45")}
                    </p>
                    <span>{car.version || "NA"}</span>
                  </div>
                </div>
                <div className="row btn-row">
                  <div className="col-12 text-center">
                    {isMouseInside[index] && (
                      <button
                        className="ask-button mt-2"
                        onClick={() => showSelectedCarDetails(car)}
                      >
                        {t("language.part44")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
