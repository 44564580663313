import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Header } from "../components";
import auth from "../helpers/auth";


// defining admin route with condition and props
function AdminRoute({ component: Component, ...restOfProps }) {
  return (
    <>
    
    <Route
      {...restOfProps}
      render={(props) =>
        auth.isAuthenticated && auth.getUserType()=='Admin' ? <><Component {...props} /></> : <Redirect to="/" />
      }
      />
      </>
  );
}

export default AdminRoute;