import React, { useState, useRef, Fragment, useEffect } from "react";
import { useHistory, useLocation, Link, Redirect } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import * as yup from "yup";
import { Card } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import Backarrow from "../asset/back-arrow.svg";
import "./i18n";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "../modules/myusers";
import {
  active_status,
  categories_value,
  employee_types,
  inactive_status,
  language_types,
  user_role,
  user_status,
} from "../utils/commonUtils";
import Select from "react-select";
import $ from "jquery";

const EditUser = (props) => {
  const formikRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { data } = location?.state || {};
  const { userlng } = useSelector((state) => state.allCarsState);
  const dispatch = useDispatch();
  const [showStatus, setShowStatus] = useState(data?.status);

  // defining the initialstate of all the form and setting values from state
  // when edit mode is true
  const { t, i18n } = useTranslation();

  const initialValues = {
    id: data?.id || "",
    username: data?.username || "",
    full_name: data?.full_name || "",
    email: data?.email || "",
    entity: data?.entity || "",
    employee_type: data?.employee_type || null,
    task_number: data?.task_number || "",
    user_type: data?.user_type || "",
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    iservice_header: data?.iservice_header || "",
    business_unit: data?.business_unit || "",
    cost_center: data?.cost_center || "",
    file_closed_date: data?.file_closed_date || "",
    address: data?.address || "",
    postcode: data?.postcode || "",
    file: data?.file || "",
    city: data?.city || "",
    telephone: data?.telephone || "",
    personal_number: data?.personal_number || "",
    status: data?.status || "",
    license_plate_current_lease_car:
      data?.license_plate_current_lease_car || "",
    lease_category_current: data?.lease_category_current || "",
    lease_category_new: data?.lease_category_new || "",
    year_mileage: data?.year_mileage || "",
    part_time_percentage: data?.part_time_percentage || "",
    end_date_lease_contract: data?.end_date_lease_contract || "",
    reason_replacement: data?.reason_replacement || "",
    license_plate_type: data?.license_plate_type || "",
    email_id_manager: data?.email_id_manager || "",
    approval_date: data?.approval_date || "",
    category: data?.user_config[0]?.category || "",
    role: data?.role || "",
    fuel_type: data?.fuel_type || "",
    user_config: data?.user_config || [{}],
    preference: language_types.filter((i) => i?.value == data?.preference)[0]
      ?.value,
  };

  // defining the validations for each field
  const validationSchema = yup.object({
    full_name: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ0-9\s\-\`\_\'\"\,\.]+$/, "v4"),
    entity: yup
      .string()
      .max(40, "v51a")
      .matches(/^[aA-zZ0-9\s]+$/, "v4"),
    first_name: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ0-9\s\-\`\_\'\"\,\.]+$/, "v4"),
    last_name: yup.string().max(32, "v64"),
    task_number: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ0-9\s]+$/, "v9"),
    file: yup
      .string()
      .matches(/^[0-9\s]+$/, "v22")
      .max(32, "v64"),
    city: yup
      .string()
      .required("v74")
      .max(32, "v64")
      .matches(/^[aA-zZ0-9\s\-\`\_\'\"\,\.]+$/, "v4"),
    personal_number: yup
      .string()
      .max(15, "v51a")
      .matches(/^[0-9\s]+$/, "v33"),
    business_unit: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ\s]+$/, "v4"),
    iservice_header: yup.string(),
    address: yup.string().matches(/^[aA-zZ0-9\s\-\,\/\\.\,\`\_\-\']+$/, "v9"),
    cost_center: yup
      .string()
      .max(32, "v64")
      .matches(/^[0-9\s]+$/, "v22"),
    postcode: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ0-9\s]+$/, "v9"),
    telephone: yup
      .string()
      .max(15, "v51a")
      .matches(/^[0-9\s\-\(\)\+]+$/, "v38"),
    license_plate_current_lease_car: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ0-9\s\-]+$/, "v33"),
    lease_category_current: yup
      .string()
      .max(100, "v51a")
      .matches(/^[aA-zZ\/\s]+$/, "v4"),
    lease_category_new: yup
      .string()
      .max(100, "v51a")
      .matches(/^[aA-zZ\/\s]+$/, "v4"),
    year_mileage: yup
      .string()
      .max(32, "v64")
      .matches(/^[0-9\s\.]+$/, "v22"),
    part_time_percentage: yup
      .number()
      .required("v70")
      .max(100, "v75")
      .min(0, "v70"),
    reason_replacement: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ0-9\s]+$/, "v9"),
    license_plate_type: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ\s]+$/, "v4"),
    email_id_manager: yup.string().max(32, "v64").email("v32"),
    fuel_type: yup
      .string()
      .max(32, "v64")
      .matches(/^[aA-zZ\s]+$/, "v4"),
    // preference: yup.string().max(32, "v64"),
  });

  useEffect(() => {
    $("input[type=number]").bind("keyup input", function (e) {
      e.preventDefault();
    });
  }, []);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    await dispatch(editUser(values, history));
    setTimeout(() => {}, 5000);
  };

  // handle change for controlled component and setting the values to state
  const onSelect = (value, name) => {
    formikRef.current.setFieldValue(name, value);
  };

  if (!data?.id) {
    return <Redirect to="/" />;
  }

  const onChange = (label, value, regex) => {
    formikRef.current.setFieldValue(label, value.replace(regex, ""));
  };

  return (
    <section style={{ background: "#F1F4F3" }}>
      <div className="main-container">
        <div className="py-5">
          <div className="d-flex align-items-center  add-head">
            <button className="to-overview" onClick={history.goBack}>
              <img src={Backarrow} alt=""></img>
              <span>{t("language.part1")}</span>
            </button>
            <h2 className="overview-head">{t("language.part183")}</h2>
            {/* <Bilingual /> */}
          </div>
        </div>
        <>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => {
              return (
                <Form autoComplete="off">
                  <Card className="add-manual-detail">
                    <h2>Details</h2>
                    <div className="row">
                      <div className="col-md-4  form-floating mb-3 ">
                        <Field
                          id="full_name"
                          name="full_name"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "full_name",
                              e.target.value,
                              /[^a-z0-9\s\-\`\_\'\"\,\.]/gi
                            )
                          }
                        />
                        <label htmlFor="full_name">
                          {t("language.part159")}
                        </label>
                        {errors.full_name && touched.full_name && (
                          <small className="text-danger">
                            {t(`validation.${errors.full_name}`)}
                          </small>
                        )}
                      </div>
                      <div className="col-md-4  form-floating mb-3 ">
                        <Field
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          disabled
                        />
                        <label htmlFor="email">{t("language.part58")}</label>
                        {errors.email && touched.email && (
                          <small className="text-danger">
                            {t(`validation.${errors.email}`)}
                          </small>
                        )}
                      </div>
                      <div className="col-md-4 form-floating mb-3 ">
                        <Field
                          id="entity"
                          name="entity"
                          type="string"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) =>
                            onChange("entity", e.target.value, /[^a-z\s]/gi)
                          }
                        />
                        <label htmlFor="entity">{t("language.part161")} </label>
                        {errors.entity && touched.entity && (
                          <small className="text-danger">
                            {t(`validation.${errors.entity}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        {/* <label htmlFor="category">{t("language.part9")}</label> */}
                        <Select
                          isMulti={false}
                          classNamePrefix="user-category"
                          height="450px"
                          styles={{ height: "450px" }}
                          value={[{ label: values?.employee_type }]}
                          options={employee_types}
                          onChange={(e) => onSelect(e.label, "employee_type")}
                        />
                        <label
                          htmlFor="user-category"
                          className="user-category"
                        >
                          {t("language.part149")}
                        </label>
                      </div>
                      <div
                        className="col-md-4 form-floating mb-3"
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="first_name"
                          name="first_name"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "first_name",
                              e.target.value,
                              /[^a-z0-9\s\-\`\_\'\"\,\.]/gi
                            )
                          }
                        />
                        <label htmlFor="barnd_nl">
                          {t("language.part158")}
                        </label>
                        {errors.first_name && touched.first_name && (
                          <small className="text-danger">
                            {t(`validation.${errors.first_name}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4  form-floating mb-3"
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="last_name"
                          name="last_name"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "last_name",
                              e.target.value,
                              /[^a-z0-9\s\-\`\_\'\"\,\.]/gi
                            )
                          }
                        />
                        <label htmlFor="barnd_nl">
                          {t("language.part182")}
                        </label>
                        {errors.last_name && touched.last_name && (
                          <small className="text-danger">
                            {t(`validation.${errors.last_name}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        {/* <label htmlFor="category">{t("language.part9")}</label> */}
                        <Select
                          isMulti={false}
                          classNamePrefix="user-category"
                          height="450px"
                          styles={{ height: "450px" }}
                          value={[{ label: values?.user_config[0]?.category }]}
                          options={categories_value}
                          onChange={(e) =>
                            onSelect(e.label, "user_config[0].category")
                          }
                        />
                        <label
                          htmlFor="user-category"
                          className="user-category"
                        >
                          Category
                        </label>
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="task_number"
                          name="task_number"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "task_number",
                              e.target.value,
                              /[^a-z0-9]/gi
                            )
                          }
                        />
                        <label htmlFor="task_number">
                          {t("language.part157")}
                        </label>
                        {console.log(errors, touched)}
                        {errors.task_number && touched.task_number && (
                          <small className="text-danger">
                            {t(`validation.${errors.task_number}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="business_unit"
                          name="business_unit"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          // onChange={handleDigit}
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "business_unit",
                              e.target.value,
                              /[^a-z]/gi
                            )
                          }
                        />
                        <label htmlFor="business_unit">
                          {t("language.part162")}
                        </label>
                        {errors.business_unit && touched.business_unit && (
                          <small className="text-danger">
                            {t(`validation.${errors.business_unit}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-2 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="file"
                          name="file"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) =>
                            onChange("file", e.target.value, /[^0-9]/gi)
                          }
                        />
                        <label htmlFor="file">{t("language.part180")}</label>
                        {errors.file && touched.file && (
                          <small className="text-danger">
                            {t(`validation.${errors.file}`)}
                          </small>
                        )}
                      </div>

                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="personal_number"
                          name="personal_number"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          // onChange={handleDigit}
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "personal_number",
                              e.target.value,
                              /[^0-9]/gi
                            )
                          }
                        />
                        <label htmlFor="personal_number">
                          {t("language.part156")}
                        </label>
                        {errors.personal_number && touched.personal_number && (
                          <small className="text-danger">
                            {t(`validation.${errors.personal_number}`)}
                          </small>
                        )}
                      </div>
                      {(showStatus == "Active" || showStatus == "Inactive") && (
                        <div
                          className="col-md-4 col-sm-6 form-floating mb-3 "
                          style={{ marginTop: "1.5rem" }}
                        >
                          <Select
                            isMulti={false}
                            classNamePrefix="user-category"
                            height="450px"
                            styles={{ height: "450px" }}
                            // defaultValue={[{ label: 'Select' }]}
                            value={[{ label: values?.status }]}
                            options={showStatus == "Active" ? active_status : inactive_status}
                            onChange={(e) => onSelect(e.value, "status")}
                          />
                          <label
                            htmlFor="user-category"
                            className="user-category"
                          >
                            {t("language.part155")}
                          </label>
                        </div>
                      )}
                    </div>
                  </Card>
                  <Card
                    className="add-manual-detail"
                    style={{ marginTop: "40px" }}
                  >
                    <h2>Other details</h2>
                    <div className="row">
                      <div className="col-md-12  form-floating description mb-3 ">
                        <Field
                          component="textarea"
                          id="iservice_header"
                          name="iservice_header"
                          type="text"
                          className="form-control iservice-header"
                          autoComplete="off"
                          placeholder=" "
                        />
                        <label htmlFor="iservice">
                          {t("language.part160")}
                        </label>
                        {errors.iservice_header && touched.iservice_header && (
                          <small className="text-danger">
                            {t(`validation.${errors.iservice_header}`)}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-floating description mb-3 ">
                        <Field
                          component="textarea"
                          id="address"
                          name="address"
                          type="text"
                          className="form-control address"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "address",
                              e.target.value,
                              /[^a-z0-9\s\,\/\\.\,\`\_\-\']/gi
                            )
                          }
                        />
                        <label htmlFor="address">{t("language.part166")}</label>
                        {errors.address && touched.address && (
                          <small className="text-danger">
                            {t(`validation.${errors.address}`)}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className=" col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="cost_center"
                          name="cost_center"
                          type="text"
                          className="form-control numberInput"
                          placeholder=" "
                          onChange={(e) =>
                            onChange("cost_center", e.target.value, /[^0-9]/gi)
                          }
                        />
                        <label htmlFor="cost_center">
                          {t("language.part163")}
                        </label>

                        {errors.cost_center && touched.cost_center && (
                          <small className="text-danger">
                            {t(`validation.${errors.cost_center}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className=" col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="file_closed_date"
                          name="file_closed_date"
                          type="text"
                          className="form-control numberInput"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "file_closed_date",
                              e.target.value,
                              /[^a-z0-9\s\-\/\_]/gi
                            )
                          }
                        />
                        <label htmlFor="file_closed_date">
                          {t("language.part164")}
                        </label>

                        {errors.file_closed_date &&
                          touched.file_closed_date && (
                            <small className="text-danger">
                              {t(`validation.${errors.file_closed_date}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="postcode"
                          name="postcode"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "postcode",
                              e.target.value,
                              /[^a-z0-9\s]/gi
                            )
                          }
                        />
                        <label htmlFor="postcode">
                          {t("language.part167")}
                        </label>
                        {errors.postcode && touched.postcode && (
                          <small className="text-danger">
                            {t(`validation.${errors.postcode}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className=" col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="city"
                          name="city"
                          type="text"
                          className="form-control numberInput"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "city",
                              e.target.value,
                              /[^a-z0-9\s\-\`\_\'\"\,\.]/gi
                            )
                          }
                        />
                        <label htmlFor="city">{t("language.part184")}</label>
                        {errors.city && touched.city && (
                          <small className="text-danger">
                            {t(`validation.${errors.city}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className=" col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="telephone"
                          name="telephone"
                          type="text"
                          className="form-control"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "telephone",
                              e.target.value,
                              /[^0-9\s\(\)\-\+]/gi
                            )
                          }
                        />
                        <label htmlFor="telephone">
                          {t("language.part169")}
                        </label>
                        {errors.telephone && touched.telephone && (
                          <small className="text-danger">
                            {t(`validation.${errors.telephone}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className=" col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="license_plate_current_lease_car"
                          name="license_plate_current_lease_car"
                          type="text"
                          placeholder=" "
                          className="form-control"
                          onChange={(e) =>
                            onChange(
                              "license_plate_current_lease_car",
                              e.target.value,
                              /[^a-z0-9\s\-]/gi
                            )
                          }
                        />
                        <label htmlFor="license_plate_current_lease_car">
                          {t("language.part170")}
                        </label>
                        {errors.license_plate_current_lease_car &&
                          touched.license_plate_current_lease_car && (
                            <small className="text-danger">
                              {t(
                                `validation.${errors.license_plate_current_lease_car}`
                              )}
                            </small>
                          )}
                      </div>

                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="lease_category_current"
                          name="lease_category_current"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "lease_category_current",
                              e.target.value,
                              /[^a-z0-9\s\-\/]/gi
                            )
                          }
                        />
                        <label htmlFor="category">
                          {t("language.part171")}
                        </label>
                        {errors.lease_category_current &&
                          touched.lease_category_current && (
                            <small className="text-danger">
                              {t(`validation.${errors.lease_category_current}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="lease_category_new"
                          name="lease_category_new"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) =>
                            onChange(
                              "lease_category_new",
                              e.target.value,
                              /[^a-z0-9\s\-\/]/gi
                            )
                          }
                          placeholder=" "
                        />
                        <label htmlFor="lease_category_new">
                          {t("language.part172")}
                        </label>
                        {errors.lease_category_new &&
                          touched.lease_category_new && (
                            <small className="text-danger">
                              {t(`validation.${errors.lease_category_new}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="year_mileage"
                          name="year_mileage"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) =>
                            onChange(
                              "year_mileage",
                              e.target.value,
                              /[^0-9\s\.]/gi
                            )
                          }
                          placeholder=" "
                          pattern="^[0-9]\d{0,11}(\.\d{0,2})?$"
                          title={t("validation.v100")}
                        />
                        <label htmlFor="year_mileage">
                          {t("language.part173")}
                        </label>
                        {errors.year_mileage && touched.year_mileage && (
                          <small className="text-danger">
                            {t(`validation.${errors.year_mileage}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="part_time_percentage"
                          name="part_time_percentage"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          pattern="^\d*(\.\d{0,2})?$"
                          title="Please enter 2 digits after decimal"
                          placeholder=" "
                          onChange={(e) =>
                            onChange(
                              "part_time_percentage",
                              e.target.value,
                              /[^0-9\.{0,2}]/gi
                            )
                          }
                        />
                        <label htmlFor="part_time_percentage">
                          Part Time %
                        </label>
                        {errors.part_time_percentage &&
                          touched.part_time_percentage && (
                            <small className="text-danger">
                              {t(`validation.${errors.part_time_percentage}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="end_date_lease_contract"
                          name="end_date_lease_contract"
                          type="date"
                          max="2999-12-31"
                          autoComplete="off"
                          className="form-control"
                          // onChange={handleDigit}
                          placeholder=" "
                        />
                        <label htmlFor="end_date_lease_contract">
                          {t("language.part174")}
                        </label>
                        {errors.end_date_lease_contract &&
                          touched.end_date_lease_contract && (
                            <small className="text-danger">
                              {t(
                                `validation.${errors.end_date_lease_contract}`
                              )}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="reason_replacement"
                          name="reason_replacement"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) =>
                            onChange(
                              "reason_replacement",
                              e.target.value,
                              /[^a-z0-9\s]/gi
                            )
                          }
                          placeholder=" "
                        />
                        <label htmlFor="reason_replacement">
                          {t("language.part175")}
                        </label>
                        {errors.reason_replacement &&
                          touched.reason_replacement && (
                            <small className="text-danger">
                              {t(`validation.${errors.reason_replacement}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="license_plate_type"
                          name="license_plate_type"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) =>
                            onChange(
                              "license_plate_type",
                              e.target.value,
                              /[^a-z]/gi
                            )
                          }
                          placeholder=" "
                        />
                        <label htmlFor="license_plate_type">
                          {t("language.part176")}
                        </label>
                        {errors.license_plate_type &&
                          touched.license_plate_type && (
                            <small className="text-danger">
                              {t(`validation.${errors.license_plate_type}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="fuel_type"
                          name="fuel_type"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) =>
                            onChange("fuel_type", e.target.value, /[^a-z]/gi)
                          }
                          placeholder=" "
                        />
                        <label htmlFor="fuel_type">
                          {t("language.part177")}
                        </label>
                        {errors.fuel_type && touched.fuel_type && (
                          <small className="text-danger">
                            {t(`validation.${errors.fuel_type}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="email_id_manager"
                          name="email_id_manager"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          // onChange={handleDigit}
                          placeholder=" "
                        />
                        <label htmlFor="email_id_manager">
                          {t("language.part178")}
                        </label>
                        {errors.email_id_manager &&
                          touched.email_id_manager && (
                            <small className="text-danger">
                              {t(`validation.${errors.email_id_manager}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="approval_date"
                          name="approval_date"
                          type="date"
                          max="2999-12-31"
                          autoComplete="off"
                          className="form-control"
                          // onChange={handleDigit}
                          placeholder=" "
                        />
                        <label htmlFor="approval_date">
                          {t("language.part179")}
                        </label>
                        {errors.approval_date && touched.approval_date && (
                          <small className="text-danger">
                            {t(`validation.${errors.approval_date}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        {/* <label htmlFor="category">{t("language.part9")}</label> */}
                        <Select
                          isMulti={false}
                          classNamePrefix="user-category"
                          height="450px"
                          placeholder={"Select Language"}
                          styles={{ height: "450px" }}
                          value={[
                            {
                              label:
                                language_types.filter(
                                  (i) => i?.value == values?.preference
                                )[0]?.label || "Select",
                            },
                          ]}
                          options={language_types}
                          onChange={(e) => onSelect(e.value, "preference")}
                        />
                        <label
                          htmlFor="user-category"
                          className="user-category"
                        >
                          Language
                        </label>
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        {/* <label htmlFor="category">{t("language.part9")}</label> */}
                        <Select
                          isMulti={false}
                          classNamePrefix="user-category"
                          height="450px"
                          placeholder={"Select Language"}
                          styles={{ height: "450px" }}
                          value={[
                            {
                              label: values.user_config[0]?.role,
                            },
                          ]}
                          options={user_role}
                          onChange={(e) =>
                            onSelect(e.value, "user_config[0].role")
                          }
                        />
                        <label
                          htmlFor="user-category"
                          className="user-category"
                        >
                          Role
                        </label>
                      </div>
                    </div>
                  </Card>

                  <div className="form-action d-flex justify-content-end">
                    <button type="submit" id="save" className="save mr-3">
                      {t("language.part91")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      </div>
    </section>
  );
};

export default EditUser;
