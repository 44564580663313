import React, { useState, useRef, Fragment, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import * as yup from "yup";
import { Card } from "react-bootstrap";
import { capFirstLetter, long_range } from "../utils/commonUtils";
import { useTranslation, Trans } from "react-i18next";
import Backarrow from "../asset/back-arrow.svg";
import uploadImage from "../asset/img-upload.svg";
import AddCar from "./modals/AddCar";
import AddOption from "./modals/AddOption";
import cross from "../asset/cross.svg";
import "./i18n";
import Radio from "@material-ui/core/Radio";
import { useDispatch, useSelector } from "react-redux";
import { editCar, saveCar } from "../modules/AddCar";
import { s3Config } from "../utils/storageUtils";
import S3 from "react-aws-s3";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let color = [];
let arr = [];

const AddNewVehicle = (props) => {
  const formikRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [submitError, setSubmitError] = useState("");
  const [isEditMode, setIsEditMode] = useState(
    location?.state?.data ? true : false
  );
  const dispatch = useDispatch();
  const [imageCount, setImageCount] = useState(
    location?.state?.data?.color?.length || 1
  );

  const { userlng } = useSelector((state) => state.allCarsState);
  const [showCar, setShowCar] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [catErr, setCatErr] = useState("");

  const [imageErr, setImagesError] = useState("");

  // validating and saving the image to aws S3
  const handleImageUpload = async (files, index, errors) => {
    const ReactS3Client = new S3(s3Config);
    if (files.length + color[index].images.length > 5) {
      setImagesError("v30");
    } else {
      Object.keys(files).map(async (file) => {
        if (files[file].size > 1000000) {
          setImagesError("v31");
        } else {
          let img = await ReactS3Client.uploadFile(
            files[file],
            files[file].name
          );
          color[index].images.push(img.location);
          setImagesError(false);
        }
      });
    }
    formikRef.current.setFieldValue("color", color);
    setImageCount(imageCount + 1);
    formikRef.current.setErrors({ errors });
    setTimeout(() => {
      setImagesError("");
    }, 3000);
  };

  if (isEditMode) {
    color = location?.state?.data?.color;
    arr = location?.state?.data?.choosableoptions;
  }

  // remove respective image
  const removeImage = async (colorIndex, index, img) => {
    color[colorIndex].images.splice(index, 1);
    setImageCount(1);
    formikRef.current.setFieldValue("color", color);
  };

  const removeColor = (index) => {
    color.splice(index, 1);
    formikRef.current.setFieldValue("color", color);
  };

  const publish = () => {
    formikRef.current.setFieldValue("isPublished", true);
  };

  const handlePitch = (editor, name) => {
    const data = editor.getData();
    formikRef.current.setFieldValue(name, data);
  };

  // defining the initialstate of all the form and setting values from state
  // when edit mode is true
  const initialValues = {
    id: location?.state?.data?.id || "",
    brand_en: location?.state?.data?.brand_en || "",
    model_en: location?.state?.data?.model_en || "",
    brand_nl: location?.state?.data?.brand_nl || "",
    model_nl: location?.state?.data?.model_nl || "",
    car_type: location?.state?.data?.car_type || "",
    version: location?.state?.data?.version || "",
    wltp_range: location?.state?.data?.wltp_range || "",
    additional_percentage: location?.state?.data?.additional_percentage || "",
    battery_capacity: location?.state?.data?.battery_capacity || "",
    tax_value: location?.state?.data?.tax_value || "",
    expected_delivery_time: location?.state?.data?.expected_delivery_time || "",
    category:
      (location?.state?.data?.category &&
        long_range.filter((i) => i.value === location?.state?.data?.category)[0]
          ?.label) ||
      "",
    description_en: location?.state?.data?.description_en || "",
    description_nl: location?.state?.data?.description_nl || "",
    color: location?.state?.data?.color || [],
    isPublished: location?.state?.data?.isPublished || false,
    choosableoptions: location?.state?.data?.choosableoptions || [],
    long_range_vehicles_category:
      (location?.state?.data?.long_range_vehicles_category &&
        long_range.filter(
          (i) =>
            i.value ===
            parseInt(location?.state?.data?.long_range_vehicles_category)
        )[0]?.label) ||
      "",
  };

  // adding custom validation method for fields
  yup.addMethod(yup.array, "checkLength", function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (value) {
      const { path, createError } = this;
      return value.length > 0 || createError({ path, message: errorMessage });
    });
  });

  const { t, i18n } = useTranslation();

  // defining the validations for each field
  const validationSchema = yup.object({
    brand_en: yup.string().required("v2").max(32, "v5"),
    model_en: yup.string().required("v3").max(32, "v6"),
    brand_nl: yup.string().required("v2").max(32, "v5"),
    model_nl: yup.string().required("v3").max(32, "v6"),
    car_type: yup.string().required("v7"),
    version: yup
      .string()
      // .matches(/^[aA-zZ0-9\.\-\_\/\+\s]+$/, "v9")
      .min(1, "v10")
      .max(32, "v11"),
    wltp_range: yup.string().max(32, "v12"),
    additional_percentage: yup.string().max(32, "v14"),
    battery_capacity: yup.string().max(32, "v16"),
    tax_value: yup.string().max(32, "v18"),
    expected_delivery_time: yup.string().max(32, "v51"),
    category: yup.string(),
    long_range_vehicles_category: yup.string(),
    description_en: yup.string().max(500, "v25").min(2, "v26"),
    description_nl: yup.string().max(500, "v25").min(2, "v26"),
    color: yup
      .array()
      .of(
        yup.object().shape({
          images: yup.array().of(yup.string()),
          color_name: yup.string().required("v27").min(2, "v28"),
        })
      )
      .checkLength("v73"),
  });

  // add a new color values from modal
  const addColor = (value) => {
    color.push({
      color_name: capFirstLetter(value.color_name.trim()),
      images: [],
      r: value.color.r,
      g: value.color.g,
      b: value.color.b,
      hex_code: value.hexCode,
    });
    formikRef.current.setFieldValue("color", color);
  };

  const handleColorName = (e, index) => {
    let value = e.target.value;
    color[index]["color_name"] = value;

    formikRef.current.setFieldValue("color", color);
  };

  // add choosable option values entered from modal
  const addChoosableOptions = (data) => {
    arr.push({
      option_name_en: capFirstLetter(data.label_name.trim()),
      option_value: parseFloat(data.value),
      option_name_nl: capFirstLetter(data.label_name_nl.trim()),
    });
    formikRef.current.setFieldValue("choosableoptions", arr);
  };

  // remove the respective choosable option
  const removeChoosableOption = (index) => {
    arr.splice(index, 1);
    formikRef.current.setFieldValue("choosableoptions", arr);
  };

  // saving the values entered from options modal
  const addOptionsModal = (options) => {
    if (options.length > 5) {
      setSubmitError("v50");
    } else {
      setShowOption(true);
    }
    setTimeout(() => {
      setSubmitError("");
    }, 5000);
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!values.long_range_vehicles_category && !values.category) {
      setCatErr("v63");
    } else {
      if (isEditMode) {
        await dispatch(editCar({ values, history, resetForm }));
      } else {
        values.color.map((item) => {
          item.images.map((image, i) => {
            item[`img${i + 1}`] = image;
          });
        });
        await dispatch(saveCar({ values, history, resetForm }));
        // resetForm({});
      }
      color = [];
      arr = [];
    }
    setTimeout(() => {
      setCatErr("");
    }, 5000);
  };

  // handle change for controlled component and setting the values to state
  const handleChange = (e) => {
    let value = e.target.value.replace(/[^0-9\,]/, "").trim();
    if (!/^[0-9]/.test(value.charAt(0))) {
      value = value.replace(",", "");
    }
    formikRef.current.setFieldValue([e.target.name], value);
  };

  const handlePercentage = (e) => {
    let value = e.target.value.replace(/[^0-9\.]/, "").trim();
    formikRef.current.setFieldValue([e.target.name], value);
  };

  const validateOptionName = (name) => {
    return true;
  };

  const onLeaseCategory = (e) => {
    formikRef.current.setFieldValue("long_range_vehicles_category", e.label);
  };

  const onCategory = (e) => {
    formikRef.current.setFieldValue("category", e.label);
  };

  useEffect(() => {
    return () => {
      // empty values on un mount of component
      color = [];
      arr = [];
    };
  }, []);

  return (
    <section style={{ background: "#F1F4F3" }}>
      <div className="main-container">
        {/* calling the modals */}
        <AddCar
          show={showCar}
          handleClose={() => setShowCar(false)}
          fireData={(data) => addColor(data)}
        />
        <AddOption
          validateName={(name) => validateOptionName(name)}
          fireData={(data) => addChoosableOptions(data)}
          show={showOption}
          handleClose={() => setShowOption(false)}
        />
        <div className="py-5">
          <div className="d-flex align-items-center  add-head">
            <button className="to-overview" onClick={history.goBack}>
              <img src={Backarrow} alt=""></img>
              <span>{t("language.part1")}</span>
            </button>
            <h2 className="overview-head">
              {isEditMode ? t("language.part92") : t("language.part38")}
            </h2>
            {/* <Bilingual /> */}
          </div>
        </div>
        <>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => {
              console.log(errors, values);
              return (
                <Form autoComplete="off">
                  <Card className="add-manual-detail">
                    <h2>{t("language.part39")}</h2>
                    <div className="row">
                      <div className="col-md-4  form-floating mb-3 ">
                        <Field
                          id="brand_en"
                          name="brand_en"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                        />
                        <label htmlFor="brand_en">
                          {t("language.part24")} (in EN)
                        </label>
                        {errors.brand_en && touched.brand_en && (
                          <small className="text-danger">
                            {t(`validation.${errors.brand_en}`)}
                          </small>
                        )}
                      </div>
                      <div className="col-md-4  form-floating mb-3 ">
                        <Field
                          id="model_en"
                          name="model_en"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                        />
                        <label htmlFor="model_en">
                          {t("language.part25")} (in EN)
                        </label>
                        {errors.model_en && touched.model_en && (
                          <small className="text-danger">
                            {t(`validation.${errors.model_en}`)}
                          </small>
                        )}
                      </div>
                      <div className="col-md-4 form-floating mb-3 ">
                        <Field
                          id="version"
                          name="version"
                          type="string"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                        />
                        <label htmlFor="version">{t("language.part43")} </label>
                        {errors.version && touched.version && (
                          <small className="text-danger">
                            {t(`validation.${errors.version}`)}
                          </small>
                        )}
                      </div>

                      <div
                        className="col-md-4  form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="brand_nl"
                          name="brand_nl"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                        />
                        <label htmlFor="barnd_nl">
                          {t("language.part24")} (in NL)
                        </label>
                        {errors.brand_nl && touched.brand_nl && (
                          <small className="text-danger">
                            {t(`validation.${errors.brand_nl}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4  form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="model_nl"
                          name="model_nl"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          placeholder=" "
                        />
                        <label htmlFor="model_nl">
                          {t("language.part25")} (in NL){" "}
                        </label>
                        {errors.model_nl && touched.model_nl && (
                          <small className="text-danger">
                            {t(`validation.${errors.model_nl}`)}
                          </small>
                        )}
                      </div>
                      <div className=" col-md-4 col-sm-6 form-floating mb-3 ">
                        <label>{t("language.part26")}</label>
                        <div
                          // className="details"
                          className="detail-label car-type-checkbox"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <Radio
                                checked={values.car_type === "Best"}
                                onChange={() =>
                                  setFieldValue("car_type", "Best")
                                }
                                color="default"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "E" }}
                                size="small"
                                className="car-type"
                              />
                              <label
                                className="Best"
                                style={{ color: "#00475F" }}
                                htmlFor="test1"
                              >
                                {t("language.part33")}
                              </label>
                            </div>

                            <div className="col-md-6">
                              <Radio
                                checked={values.car_type === "Standard"}
                                onChange={() =>
                                  setFieldValue("car_type", "Standard")
                                }
                                color="default"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "E" }}
                                size="small"
                                className="car-type"
                              />
                              <label
                                style={{ color: "#00475F" }}
                                htmlFor="test2"
                                className="stand-label"
                              >
                                {t("language.part49")}
                              </label>
                            </div>
                          </div>
                          {errors.car_type && touched.car_type && (
                            <small
                              style={{
                                bottom: "-10px",
                                position: "relative",
                              }}
                              className="text-danger car-type-err"
                            >
                              {t(`validation.${errors.car_type}`)}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card
                    className="add-manual-detail"
                    style={{ marginTop: "40px" }}
                  >
                    <h2>{t("language.part35")}</h2>
                    <div className="row">
                      {catErr && (
                        <small className="text-danger">
                          {t(`validation.${catErr}`)}
                        </small>
                      )}
                      <div className="col-md-4 col-sm-6 form-floating mb-3 ">
                        <Field
                          id="wltp_range"
                          name="wltp_range"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder=" "
                          onChange={handleChange}
                        />
                        <label htmlFor="wltp_range">
                          {t("language.part4")}
                        </label>

                        {errors.wltp_range && touched.wltp_range && (
                          <small className="text-danger">
                            {t(`validation.${errors.wltp_range}`)}
                          </small>
                        )}
                      </div>
                      <div className=" col-md-4 col-sm-6 form-floating mb-3 ">
                        <Field
                          id="additional_percentage"
                          name="additional_percentage"
                          type="text"
                          placeholder=" "
                          className="form-control"
                          onChange={handlePercentage}
                        />
                        <label htmlFor="additional_percentage">
                          {t("language.part5")}
                        </label>
                        {errors.additional_percentage &&
                          touched.additional_percentage && (
                            <small className="text-danger">
                              {t(`validation.${errors.additional_percentage}`)}
                            </small>
                          )}
                      </div>
                      <div className=" col-md-4 col-sm-6 form-floating mb-3 ">
                        <Field
                          id="battery_capacity"
                          name="battery_capacity"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label htmlFor="battery_capacity">
                          {t("language.part6")}
                        </label>
                        {errors.battery_capacity &&
                          touched.battery_capacity && (
                            <small className="text-danger">
                              {t(`validation.${errors.battery_capacity}`)}
                            </small>
                          )}
                      </div>

                      <div
                        className=" col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="tax_value"
                          name="tax_value"
                          type="text"
                          className="form-control numberInput"
                          onChange={handleChange}
                          placeholder=" "
                        />
                        <label htmlFor="tax_value">{t("language.part7")}</label>

                        {errors.tax_value && touched.tax_value && (
                          <small className="text-danger">
                            {t(`validation.${errors.tax_value}`)}
                          </small>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Field
                          id="expected_delivery_time"
                          name="expected_delivery_time"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          // onChange={handleDigit}
                          placeholder=" "
                        />
                        <label htmlFor="expected_delivery_time">
                          {t("language.part8")}
                        </label>
                        {errors.expected_delivery_time &&
                          touched.expected_delivery_time && (
                            <small className="text-danger">
                              {t(`validation.${errors.expected_delivery_time}`)}
                            </small>
                          )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <label htmlFor="category">{t("language.part9")}</label>
                        <Select
                          isMulti={false}
                          classNamePrefix="Lease-select"
                          height="400px"
                          placeholder={t("language.part9a")}
                          styles={{ height: "400px" }}
                          value={
                            values.category
                              ? [{ label: values.category }]
                              : null
                          }
                          options={long_range}
                          onChange={(e) => onCategory(e)}
                        />
                        {values?.category && (
                          <label htmlFor="category" className="user-category">
                            {t("language.part9a")}
                          </label>
                        )}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 form-floating mb-3 "
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Select
                          isMulti={false}
                          classNamePrefix="Lease-select"
                          height="400px"
                          placeholder={t("language.part136")}
                          styles={{ height: "400px" }}
                          value={
                            values.long_range_vehicles_category
                              ? [
                                  {
                                    label: values.long_range_vehicles_category,
                                  },
                                ]
                              : null
                          }
                          options={long_range}
                          onChange={(e) => onLeaseCategory(e)}
                        />
                        {values?.long_range_vehicles_category && (
                          <label htmlFor="category" className="user-category">
                            {t("language.part136")}
                          </label>
                        )}
                      </div>
                    </div>
                  </Card>
                  <Card
                    className="add-manual-detail"
                    style={{ marginTop: "40px" }}
                  >
                    <h2>{t("language.part27")}</h2>
                    <div className="row">
                      <label htmlFor="expected_delivery_time">
                        {t("language.part23")} (EN)
                      </label>
                      <div className="col-md-12  form-floating description mb-3 ">
                        <CKEditor
                          editor={ClassicEditor}
                          data={values.description_en}
                          onChange={(event, editor) =>
                            handlePitch(editor, "description_en")
                          }
                        />
                        {errors.description_en && touched.description_en && (
                          <small className="text-danger">
                            {t(`validation.${errors.description_en}`)}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <label htmlFor="expected_delivery_time">
                        {t("language.part23")} (NL)
                      </label>
                      <div className="col-md-12  form-floating description  mb-3 mt-4">
                        <CKEditor
                          editor={ClassicEditor}
                          data={values.description_nl}
                          onChange={(event, editor) =>
                            handlePitch(editor, "description_nl")
                          }
                        />
                        {errors.description_nl && touched.description_nl && (
                          <small className="text-danger">
                            {t(`validation.${errors.description_nl}`)}
                          </small>
                        )}
                      </div>
                    </div>
                  </Card>
                  <Card
                    className="add-manual-detail"
                    style={{ marginTop: "40px" }}
                  >
                    {errors.color &&
                      touched.color &&
                      typeof errors.color === "string" && (
                        <small className="text-danger">
                          {t(`validation.${errors.color}`)}
                        </small>
                      )}
                    <h2>{t("language.part3")}</h2>
                    {values.color.map((color, index) => (
                      <Fragment>
                        <div className="row" key={index}>
                          <div className="col-md-4  form-floating mb-3 mt-4">
                            <Field
                              id="color_name"
                              name="color_name"
                              type="text"
                              placeholder=" "
                              className="form-control"
                              autoComplete="off"
                              value={color.color_name}
                              onChange={(e) => handleColorName(e, index)}
                            />
                            <label htmlFor="color_name">
                              {t("language.part79")}
                            </label>
                            {errors.color &&
                              errors?.color[index]?.color_name && (
                                <small className="text-danger">
                                  {t(
                                    `validation.${errors.color[index].color_name}`
                                  )}
                                </small>
                              )}
                          </div>
                          <div className="col-md-4  form-floating mb-3 mt-3">
                            <div
                              className="d-flex justify-content-between color-code"
                              style={{ padding: "0px" }}
                            >
                              <h1>{color.hexCode}</h1>
                              <h1 className="ml-4">R</h1>
                              <h1 className="mr-2">G</h1>
                              <h1 className="mr-4">B</h1>
                            </div>
                            <div className="d-flex justify-content-between color-code1">
                              <div
                                style={{
                                  width: "90px",
                                  height: "40px",
                                  backgroundColor: `rgb(${color.r},${color.g},${color.b})`,
                                  borderRadius: "6px",
                                }}
                              ></div>
                              <h1>{color.r}</h1>
                              <h1>{color.g}</h1>
                              <h1>{color.b}</h1>
                            </div>
                          </div>
                          <div className="col-md-4 form-floating mb-3 mt-4 Color-btn">
                            <button
                              type="button"
                              onClick={() => removeColor(index)}
                            >
                              {t("language.part80")}
                            </button>
                          </div>
                        </div>

                        {errors.color &&
                          errors?.color[index]?.images &&
                          touched.color && (
                            <small className="text-danger">
                              {t(`validation.${errors.color[index].images}`)}
                            </small>
                          )}
                        <div className=" col-md-12  form-floating mb-3 mt-4 pl-0 pr-0">
                          <FieldArray name="image">
                            <div
                              className="d-flex  flex-wrap align-items-center"
                              style={{
                                border: "1px dashed #00475F",
                                padding: "20px 20px 18px",
                              }}
                            >
                              <div
                                key={index}
                                className="d-flex up-img flex-column align-items-center m-auto"
                              >
                                <div className="d-flex  upload-sections align-items-baseline position-relative">
                                  <div className="form-floating upload-btn-wrapper">
                                    <button
                                      type="button"
                                      className="cust-btn d-flex flex-column align-items-center"
                                    >
                                      <input
                                        multiple
                                        type="file"
                                        name={`color`}
                                        onChange={(event) => {
                                          handleImageUpload(
                                            event.target.files,
                                            index,
                                            errors
                                          );
                                        }}
                                        accept="image/jpeg,image/gif,image/png,image/x-eps"
                                      />
                                      <img src={uploadImage} alt=""></img>
                                      <div
                                        className="d-flex"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <span
                                          style={{
                                            color: "#00475F",
                                            fontSize: "18px",
                                            marginLeft: "5px",
                                            fontFamily: "OpenSans-Bold",
                                          }}
                                        >
                                          {t("language.part40")}, or {"  "}
                                          <span style={{ color: "#009CDD" }}>
                                            {t("language.part84")}{" "}
                                          </span>
                                        </span>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                                <div className="upload-sections">
                                  <h6 className="sub-title mb-0 text-center">
                                    {" "}
                                    ({t("language.part41")})
                                  </h6>
                                </div>
                              </div>
                              <div className="row mt-3 justify-content-center">
                                {color.images.map((img, i) => (
                                  <div className="form-floating upload-img col-md-2">
                                    <img
                                      src={img}
                                      alt="image"
                                      className="img2"
                                      id={i}
                                    ></img>
                                    <img
                                      src={cross}
                                      alt="image"
                                      className="img1"
                                      onClick={() => removeImage(index, i, img)}
                                    ></img>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </FieldArray>
                        </div>
                      </Fragment>
                    ))}
                    <div className="row">
                      <div className="col-md-12 text-center form-floating mb-3 mt-4">
                        <button
                          type="button"
                          style={{
                            backgroundColor: "#f1f4f3",
                            width: "100%",
                            textAlign: "center",
                            height: "90px",
                            borderRadius: "6px",
                            border: "0px solid grey",
                            color: "#848F93",
                            fontSize: "20px",
                            fontFamily: "OpenSans-Bold",
                          }}
                          onClick={() => setShowCar(true)}
                        >
                          {t("language.part78")}
                        </button>
                        {imageErr && (
                          <small className="text-danger">
                            {t(`validation.${imageErr}`)}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="row"></div>
                  </Card>

                  <Card
                    className="add-manual-detail"
                    style={{ marginTop: "40px" }}
                  >
                    <h2>{t("language.part11")}</h2>

                    {values.choosableoptions.map((item, index) => (
                      <div className="row">
                        <div className="col-md-6 col-sm-6 form-floating mb-3 mt-3">
                          <h3>
                            {userlng === "en"
                              ? item.option_name_en
                              : item.option_name_nl}
                            : € {item.option_value}
                          </h3>
                        </div>
                        <div className="col-md-6 col-sm-6 form-floating mb-3  Color-btn">
                          <button
                            type="button"
                            onClick={() => removeChoosableOption(index)}
                          >
                            {t("language.part80")}
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-md-12 form-floating mb-3 mt-4 text-center">
                        <button
                          type="button"
                          style={{
                            backgroundColor: "#f1f4f3",
                            width: "100%",
                            textAlign: "center",
                            height: "90px",
                            borderRadius: "6px",
                            border: "0px solid grey",
                            color: "#848F93",
                            fontFamily: "OpenSans-Bold",
                            fontSize: "20px",
                          }}
                          onClick={() =>
                            addOptionsModal(values.choosableoptions)
                          }
                        >
                          {t("language.part81")}
                        </button>
                        {submitError && (
                          <small className="text-danger">
                            {t(`validation.${submitError}`)}
                          </small>
                        )}
                      </div>
                    </div>
                  </Card>
                  <div className="form-action d-flex justify-content-end">
                    {isEditMode ? (
                      <button type="submit" id="save" className="save mr-3">
                        {t("language.part91")}
                      </button>
                    ) : (
                      <>
                        <button type="submit" id="save" className="save mr-3">
                          {t("language.part36")}
                        </button>
                        <button
                          type="submit"
                          id="save"
                          className="save"
                          style={{ backgroundColor: "#FF7C1C" }}
                          onClick={publish}
                        >
                          {t("language.part37")}
                        </button>
                      </>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      </div>
    </section>
  );
};

export default AddNewVehicle;
