import React, { useState, useContext, useEffect } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Container, Row, Toast, Spinner } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { MY_CARS_PATH, ALL_CARS_PATH } from "../constants/routes";
import CloseModal from "./modals/CloseModal";
import Ask from "./modals/AskQuestionModal";

import RemoveCar from "./modals/RemoveCar";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Backarrow from "../asset/back-arrow.svg";
import notfound from "../asset/image_not_available.png";
import Radio from "@material-ui/core/Radio";
import { useSelector, useDispatch } from "react-redux";
import { fetchMyCarDetail } from "../modules/mycars";
import { getCategory } from "../utils/commonUtils";
import NotFound from "./NotFound";
import { setReserveCarModal, setThankyouModal } from "../modules/allCars";

const CarDetails = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState({
    basis: true,
    opties: false,
    fiscaleGegevens: false,
    contractgegevens: false,
  });

  // defining the state variables
  const [colorError, setColorError] = useState("");
  const [chargingErr, setChargingErr] = useState("");

  const closeCloseModal = async () => {
    history.push(ALL_CARS_PATH);
    await dispatch(setThankyouModal(false));
  };
  const [showRemoveConfirmationModal, setRemoveConfirmationModal] =
    useState(false);
  const [notFound, setNotFound] = useState(false);

  const dispatch = useDispatch();
  const { myCar, myCarSpinner } = useSelector((state) => state.myCarState);
  const { userlng, thankyoumodal, reserve_modal } = useSelector(
    (state) => state.allCarsState
  );

  // state variables created for functionality
  let [checkedState, setCheckedState] = useState(""); // checkbox for color option
  const [colorChosen, setcolorChosen] = useState([]);
  const [ischarging, setIscharging] = useState("");
  const [carRate, setcarRate] = useState(0);
  const [thumbnail, setThumbNail] = useState();
  let [filters, setFilters] = useState([]);

  const toggleModal = () => {
    // setShowAskQusModal(false);
    // setShowCloseModal(true);
  };

  const closeRemoveConfirmationModal = () => setRemoveConfirmationModal(false);
  const { t, i18n } = useTranslation();

  const reserveCar = async () => {
    console.log(colorChosen, checkedState, ischarging);
    if (checkedState && ischarging) {
      await dispatch(setReserveCarModal(true));
    } else if (!checkedState.length && !ischarging.length) {
      setColorError(t("validation.v72"));
      setChargingErr(t("validation.charging_err"));
    } else if (!checkedState.length) {
      setColorError(t("validation.v72"));
    } else if (!ischarging.length) {
      setChargingErr(t("validation.charging_err"));
    }
    setTimeout(() => {
      setColorError("");
      setChargingErr("");
    }, 5000);
  };

  const handleClicked = (e, position) => {
    let name = e.target.name;
    setCheckedState(name);
    setcolorChosen(myCar.color[position].images);
  };

  // saving the choosen options value to state
  const choosebleOption = (i) => {
    if (filters.some((j) => j.id === i.id)) {
      filters = filters.filter((j) => j.id !== i.id);
    } else {
      filters.push(i);
    }
    setFilters(filters);
    let amt = 0;
    const total =
      i.option_value &&
      filters.map((i) => (amt = amt + parseFloat(i.option_value)));
    setcarRate(amt.toFixed(2));
  };

  // changing the value of isCharging
  const onradioisCharging = (value) => {
    setIscharging(value);
  };

  // changing the value is towbar

  const editDetails = (car) => {
    history.push({
      pathname: `${MY_CARS_PATH}/edit/${car.id}`,
      state: {
        data: car,
        from: "my-cars",
      },
    });
  };

  let params = useParams();

  // fetching car details by Id
  useEffect(() => {
    dispatch(fetchMyCarDetail(params.car));
    return async () => {
      await dispatch(setReserveCarModal(false));
      await dispatch(setThankyouModal(false));
    };
  }, []);

  // set car image in state
  useEffect(() => {
    if (myCar && myCar.color && myCar.color.length) {
      setcolorChosen(myCar?.color[0]?.images);
    } else {
      setcolorChosen([]);
    }
  }, [myCar?.id]);

  let url = props.match.url.split("/")[1];

  // returning the function after checking the condition
  if (notFound) {
    return (
      <h1
        className="text-center"
        style={{ minHeight: "350px", lineHeight: "350px" }}
      >
        404 Not found
      </h1>
    );
  } else if (myCarSpinner) {
    return (
      <div
        style={{
          height: "400px",
        }}
      >
        <div class="spinner-border text-primary mycars-spinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        {/* <Spinner animation="border" variant="info" /> */}
      </div>
    );
  } else {
    let thumbail =
      myCar &&
      myCar.color &&
      myCar.color.filter((i) => i && i.images && i.images.length !== 0)[0]
        ?.images[0];

    return myCar?.id ? (
      <section className="main-container">
        <Container fluid>
          <div className="my-5">
            <button className="to-overview" onClick={()=>history.goBack()}>
              <img src={Backarrow}></img>
              <span>{t("language.part1")}</span>
            </button>
          </div>

          <Row>
            <div className="col-md-4">
              <Card>
                <Carousel swipeable={true}>
                  <div>
                    <img
                      className="d-block w-100"
                      src={
                        !colorChosen[0]
                          ? thumbail
                            ? thumbail
                            : notfound
                          : colorChosen[0]
                      }
                      alt={"image"}
                    ></img>{" "}
                  </div>
                  <div>
                    {colorChosen && (
                      <img
                        className="d-block w-100"
                        src={
                          colorChosen[1] === undefined
                            ? colorChosen[0]
                              ? colorChosen[0]
                              : thumbail
                            : colorChosen[1]
                        }
                        alt=""
                      ></img>
                    )}{" "}
                  </div>
                  <div>
                    {colorChosen && (
                      <img
                        className="d-block w-100"
                        src={
                          colorChosen[2] === undefined
                            ? colorChosen[0]
                              ? colorChosen[0]
                              : thumbail
                            : colorChosen[2]
                        }
                        alt={colorChosen[2]}
                      ></img>
                    )}{" "}
                  </div>
                  <div>
                    {colorChosen && (
                      <img
                        className="d-block w-100"
                        src={
                          colorChosen[3] === undefined
                            ? colorChosen[0]
                              ? colorChosen[0]
                              : thumbnail
                            : colorChosen[3]
                        }
                        alt={colorChosen[3]}
                      ></img>
                    )}{" "}
                  </div>
                  <div>
                    {colorChosen && (
                      <img
                        className="d-block w-100"
                        src={
                          colorChosen[4] === undefined
                            ? colorChosen[0]
                              ? colorChosen[0]
                              : thumbnail
                            : colorChosen[4]
                        }
                        alt={colorChosen[4]}
                      ></img>
                    )}{" "}
                  </div>
                </Carousel>
              </Card>
            </div>
            <div className="col-md-8  ">
              {reserve_modal && (
                <Ask
                  data={myCar}
                  show={reserve_modal}
                  handleClose={() => dispatch(setReserveCarModal(false))}
                  handleSubmit={toggleModal}
                  filters={filters}
                  choosableoptions={myCar.choosableoptions}
                  color={checkedState}
                  charging={ischarging}
                />
              )}
              {thankyoumodal && (
                <CloseModal
                  show={thankyoumodal}
                  handleClose={closeCloseModal}
                />
              )}

              {showRemoveConfirmationModal && (
                <RemoveCar
                  show={showRemoveConfirmationModal}
                  handleClose={closeRemoveConfirmationModal}
                  data={myCar}
                />
              )}

              <div className="detailed" style={{ paddingLeft: "15px" }}>
                <h2>
                  {userlng === "en" ? myCar.brand_en : myCar.brand_nl}{" "}
                  {userlng === "en" ? myCar.model_en : myCar.model_nl}{" "}
                  {myCar.version}
                </h2>
                <p className="car-info">
                  <div className="row car-detail">
                    <div className="col-md-4">
                      <div>
                        <span>{t("language.part4")}</span>
                        <p style={{ marginBottom: "0px" }}>
                          {myCar.wltp_range ? myCar.wltp_range + " km" : "NA"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex">
                        <span>{t("language.part5")}</span>
                        <div className="add_tooltip">
                          <img
                            style={{
                              width: "14px",
                              height: "12px",
                              marginLeft: "10px",
                              marginTop: "-15px",
                            }}
                            src="https://www.svgrepo.com/show/24584/info-icon.svg"
                          ></img>
                          <div className="add_tooltip_text">
                            <span>
                              {t("language.part1000")}{" "}
                              <a
                                target="_blank"
                                href="https://www.anwb.nl/auto/autobelastingen/bijtelling-elektrische-auto"
                                style={{
                                  color: "#FF7C1C",
                                  textDecoration: "underline",
                                }}
                              >
                                {t("language.part1001")}
                              </a>{" "}
                              {t("language.part1002")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p style={{ marginBottom: "0px" }}>
                        {myCar.additional_percentage
                          ? myCar.additional_percentage + " %"
                          : "n.t.b."}
                        {/* {" %"} */}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <span>{t("language.part6")}</span>
                        <p style={{ marginBottom: "0px" }}>
                          {myCar.battery_capacity || "NA"}{" "}
                          {myCar.battery_capacity && "kWh"}
                        </p>
                      </div>
                    </div>
                  </div>
                </p>
                <p className="car-info">
                  <div className="row car-detail">
                    <div className="col-md-4">
                      <div>
                        

                        <div className="d-flex">
                        <span>{t("language.part7")}</span>
                        <div className="add_tooltip">
                          <img
                            style={{
                              width: "14px",
                              height: "12px",
                              marginLeft: "10px",
                              marginTop: "-15px",
                            }}
                            src="https://www.svgrepo.com/show/24584/info-icon.svg"
                          ></img>
                          <div className="add_tooltip_text">
                            <span>
                            De fiscale waarde kan wijzigen door prijsverhogingen vanuit de automerken. Dit heeft dus effect op de hoogte van je bijtelling. 
                            </span>
                          </div>
                        </div>
                      </div>


                        <p style={{ marginBottom: "0px" }}>
                          {Number(myCar.tax_value) + (Number(carRate) * 48.0) || "NA"} €
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex">
                        <span>{t("language.part8")}</span>
                        <div className="add_tooltip">
                          <img
                            style={{
                              width: "14px",
                              height: "12px",
                              marginLeft: "10px",
                              marginTop: "-15px",
                            }}
                            src="https://www.svgrepo.com/show/24584/info-icon.svg"
                          ></img>
                          <div className="add_tooltip_text">
                            <span>
                            De levertijd geeft een indicatie aan van verwachte leverperiode. Ook na het bestellen kan deze nog wijzigen door o.a. chiptekorten.
                            </span>
                          </div>
                        </div>
                      </div>
                      {myCar.expected_delivery_time ? (
                          <p style={{ marginBottom: "0px" }}>
                            {myCar.expected_delivery_time}{" "}
                          </p>
                        ) : (
                          <p style={{ marginBottom: "0px" }}>{"N.N.B"}</p>
                        )}
                    </div>
                    <div className="col-md-4">
                      <div>
                        <span>{t("language.part9")}</span>
                        <p style={{ marginBottom: "0px" }}>
                          {getCategory(myCar.category) || "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                </p>
                <p className="car-info">
                  <div className="row car-detail">
                    <div className="col-md-8">
                      <div>
                        <span>{t("language.part45")}</span>
                        <p style={{ marginBottom: "0px" }}>
                          {myCar.version || "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                </p>
              </div>
              <div className="col-md-12 tab-details ">
                {show.basis && (
                  <div className="">
                    <div className="details">
                      <p
                        style={{
                          fontSize: "15px",
                          fontFamily: "OpenSans-SemiBold",
                        }}
                      >
                        {t("language.part2")}
                      </p>
                    </div>
                    <div className="details">
                      {myCar && myCar.color && myCar.color.length > 0 && (
                        <p>{t("language.part3")}</p>
                      )}
                      <div className="row details-page color-scroll">
                        {myCar &&
                          myCar.color &&
                          myCar.color.map((color, index) => (
                            <div key={index} className="col-1">
                              <label className="container" id="aftercolor">
                                <input
                                  type="checkbox"
                                  className={`custom-checkbox-${index}`}
                                  name={color.color_name}
                                  checked={checkedState === color.color_name}
                                  onChange={(e) => handleClicked(e, index)}
                                />
                                <span className="mark"></span>
                                <span
                                  className="mark"
                                  style={{
                                    border: "5px solid",
                                    borderColor: "rgb(195 191 191)",
                                    position: "absolute",
                                    top: "0px",
                                    left: "0",
                                    borderRadius: "50px",
                                    height: "40px",
                                    width: "40px",
                                    backgroundColor: color.hex_code,
                                  }}
                                ></span>
                              </label>
                              <span
                                className="color-span"
                                style={{
                                  color: "#00475F",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  fontFamily: "OpenSans-Bold",
                                  textAlign: "center",
                                }}
                              >
                                {color.color_name.slice(0, 15)}
                                {color.color_name.length > 15 && "..."}
                              </span>
                              <br></br>
                              {url === "my-cars" && (
                                <span
                                  className="color-span1"
                                  style={{
                                    color: "#00475F",
                                    fontSize: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    // width: "27px",
                                    marginTop: "-22px",
                                    fontFamily: "OpenSans-SemiBold",
                                  }}
                                >
                                  R {color.r} <br></br> G {color.g} <br></br>B{" "}
                                  {color.b}
                                </span>
                              )}
                            </div>
                          ))}
                      </div>
                      {colorError && (
                        <small className="text-danger">{colorError}</small>
                      )}
                    </div>

                    <div className="details">
                      <div className="d-flex ">
                        <p> {t("language.part10")}</p>
                        <div className="tool">
                          <img
                            style={{
                              width: "14px",
                              height: "12px",
                              marginLeft: "10px",
                              marginTop: "8px",
                            }}
                            src="https://www.svgrepo.com/show/24584/info-icon.svg"
                          ></img>
                          <div className="tooltiptext">
                            <span>
                              {t("language.part61")}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-2 pl-1">
                          <Radio
                            checked={ischarging === "part54"}
                            onChange={() => onradioisCharging("part54")}
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "E" }}
                            size="small"
                          />
                          <label htmlFor="test1">{t("language.part54")}</label>
                        </div>
                        <div className="col-md-2 pl-1">
                          <Radio
                            checked={ischarging === "part55"}
                            onChange={() => onradioisCharging("part55")}
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "E" }}
                            size="small"
                          />
                          <label htmlFor="test2">{t("language.part55")}</label>
                        </div>
                      </div>
                      {chargingErr && (
                        <small className="text-danger">{chargingErr}</small>
                      )}
                    </div>
                    {/* showing the contents on basis of location */}
                    {url === "all-cars" && (
                      <div className="details mt-4">
                        <p>{t("language.part11")}</p>
                        <div className="row">
                          <div className="detailed">
                            <div className="details car-detals ">
                              <div className="row details-page car-detail">
                                {myCar?.choosableoptions?.length > 0 ? (
                                  myCar?.choosableoptions.map((i, index) => (
                                    <div className="col-3 my-3" key={index}>
                                      <label className="container">
                                        <input
                                          type="checkbox"
                                          // checked={filters.some(
                                          //   (j) => j.id === i.id
                                          // )}
                                          name={i.id}
                                          id={i.id}
                                          onChange={(e) => choosebleOption(i)}
                                        ></input>
                                        <span
                                          className={
                                            filters.some((j) => j.id === i.id)
                                              ? "mark"
                                              : "notmark"
                                          }
                                        >
                                          {userlng === "de"
                                            ? i.option_name_nl
                                            : i.option_name_en}
                                        </span>
                                      </label>
                                    </div>
                                  ))
                                ) : (
                                  <p className="font-italic">
                                    {userlng === "en"
                                      ? "No options to choose"
                                      : "Geen keuze opties"}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* showing the contents on basis of location */}

                    {url === "my-cars" && (
                      <div className="mycars row details">
                        <p>{t("language.part11")}</p>
                        {myCar.choosableoptions &&
                        myCar.choosableoptions.length > 0 ? (
                          <>
                            {myCar.choosableoptions.map((i) => (
                              <div
                                className="col-md-12 col-sm-12 form-floating mb-2 mt-2"
                                key={i.option_name_en}
                              >
                                {i.option_name_en && i.option_value && (
                                  <h3>
                                    {i.option_name_en || ""}: €{" "}
                                    {i.option_value || ""}
                                  </h3>
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <p className="font-italic">
                            {userlng === "en"
                              ? "No options to choose"
                              : "Geen keuze opties"}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="details">
                      <div className="row">
                        <div
                          className="row"
                          style={{ margin: "25px 0px", paddingLeft: "0px" }}
                        >
                          <label
                            style={{
                              fontFamily: "OpenSans-SemiBold",
                            }}
                          >
                            {t("language.part46")}
                          </label>
                          <div className="col-md-5">
                            <h2
                              style={{
                                fontWeight: "bold",
                                fontSize: "44px",
                                marginBottom: "0px",
                              }}
                            >
                              € {carRate}
                            </h2>
                            <span
                              style={{
                                fontSize: "18px",
                                fontFamily: "OpenSans-SemiBold",
                              }}
                            >
                              {t("language.part17")}
                            </span>
                          </div>
                          {/* showing the contents on basis of location */}

                          {url === "all-cars" && (
                            <div className="col-md-4">
                              <div className="detailed">
                                <div className=" car-price">
                                  <>
                                    <button
                                      onClick={() => reserveCar()}
                                      className="ask"
                                      style={{ width: "340px !important" }}
                                    >
                                      {t("language.part16")}
                                    </button>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* showing the contents on basis of location */}

                          {url === "my-cars" && (
                            <div className="col-md-6">
                              <div className="d-flex  car-button">
                                <div className=" admin-section">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setRemoveConfirmationModal(true)
                                    }
                                    className="book2"
                                  >
                                    {t("language.part74")}
                                  </button>
                                </div>
                                <div className=" admin-section">
                                  <button
                                    onClick={() => editDetails(myCar)}
                                    className="book"
                                  >
                                    {/* {/ Bewerk /} */}
                                    {t("language.part75")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      {myCar?.description_en && (
                        <div className="row">
                          <p style={{ fontSize: "15px" }}>
                            {t("language.part23")}
                          </p>
                          <div className="col-md-12">
                            {userlng === "en" ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: myCar?.description_en,
                                }}
                              ></p>
                            ) : (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: myCar?.description_nl.trim(),
                                }}
                              ></p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Row>
        </Container>
      </section>
    ) : (
      <NotFound />
    );
  }
};

export default CarDetails;
