import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useLocation } from "react-router-dom";

const styles = makeStyles({
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#00475F",
  },
  subHeading: {
    fontSize: "10px",
    fontWeight: "600",
    color: "#00475F",
  },
});
export default function Filter(props) {
  const classes = styles();
  const { t } = useTranslation();
  const location = useLocation();
  const { userlng } = useSelector((state) => state.allCarsState);
  const { user_data } = useSelector((state) => state.authState);

  let {
    filters,
    filterOptions: { brand_data, model_data, version_data },
  } = props;

  const handleSearch = (pattern) => {};

  return (
    <>
      <div className="sidebar-inner">
        <h4>Filters</h4>
        {location.pathname == "/my-cars" && (
          <div className="search">
            <h4>{t("language.part72")}</h4>
            <input
              type="text"
              placeholder={t("language.part73")}
              onChange={(e) => handleSearch(e.target.value)}
            ></input>
          </div>
        )}
        <div>
          {userlng === "en" ? (
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {t("language.part24")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100">
                  <Select
                    isMulti={true}
                    isSearchable={false}
                    options={brand_data}
                    getOptionLabel={(option) => option.brand}
                    getOptionValue={(option) => option.brand}
                    // onChange={(e) => handleBrandChange(e)}
                    value={filters.brand}
                    onChange={(e) => {
                      props.handleFilterChange(e, "brand");
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {t("language.part9")}{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100">
                  <Select
                    isMulti={false}
                    isSearchable={false}
                    options={props?.categories_value}
                    value={props?.categories}
                    onChange={(e) => props.handleFilterChange(e, "category")}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        <div>
          {userlng !== "en" ? (
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {t("language.part24")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100">
                  <Select
                    isMulti={true}
                    isSearchable={false}
                    options={brand_data}
                    getOptionLabel={(option) => option.brand}
                    getOptionValue={(option) => option.brand}
                    value={filters?.brand}
                    onChange={(e) => {
                      props.handleFilterChange(e, "brand");
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {t("language.part9")}{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100">
                  <Select
                    isMulti={false}
                    isSearchable={false}
                    options={props?.categories_value.filter(
                      (i) => parseInt(i.label) <= user_data?.category
                    )}
                    value={props.categories}
                    onChange={(e) => props.handleFilterChange(e, "category")}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        <div>
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Model</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-100">
                {model_data && !model_data.length && (
                  <Typography className={classes.subHeading}>
                    {t("language.part76")}
                  </Typography>
                )}
                {model_data && model_data.length > 0 && (
                  <Select
                    isMulti={true}
                    isSearchable={false}
                    options={model_data && model_data}
                    getOptionLabel={(option) => option.model}
                    value={filters.model}
                    getOptionValue={(option) => option.model}
                    onChange={(e) => props.handleFilterChange(e, "model")}
                  />
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {t("language.part43")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-100">
                <Select
                  isMulti={true}
                  isSearchable={false}
                  options={version_data}
                  getOptionLabel={(option) => option.version}
                  getOptionValue={(option) => option.version}
                  onChange={(e) => props.handleFilterChange(e, "version")}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
}
