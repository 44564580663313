import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import Close from "../../asset/close-icon.svg";
import * as yup from "yup";

const Option = (props) => {
  const formikRef = useRef(null);

  let [error, setError] = useState("");

  const { t } = useTranslation();

  const initialValues = {
    label_name: "",
    label_name_nl: "",
    value: "",
  };

  const validationSchema = yup.object({
    label_name: yup
      .string()
      .required(t("validation.v43"))
      // .matches(/^[aA-zZ\s]+$/, t("validation.v4"))
      .max(32, t("validation.v44"))
      .min(2, t("validation.v28")),
    label_name_nl: yup
      .string()
      .required(t("validation.v43"))
      // .matches(/^[aA-zZ\s]+$/, t("validation.v4"))
      .max(32, t("validation.v44"))
      .min(2, t("validation.v28")),
    value: yup
      .string()
      .required(t("validation.v45"))
      .max(1000000, t("validation.v47")),
  });

  const onSubmit = (values) => {
    if (!props.validateName(values.label_name)) {
      setError(t("language.part106"));
    } else {
      props.fireData(values);
      props.handleClose();
      setError("");
    }
  };

  const handleDigit = (e) => {
    let value = e.target.value.replace(/[^0-9\.]/gi, "").trim();
    formikRef.current.setFieldValue([e.target.name], value);
  };

  return (
    <Modal
      className="modal3"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <div
        className="d-flex justify-content-end"
        style={{ padding: "21px 21px 0" }}
      >
        <img
          src={Close}
          onClick={props.handleClose}
          style={{ cursor: "pointer" }}
          alt=""
        ></img>
      </div>
      <div className="popup-content text-center">
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Modal.Body>
                <h4 style={{ fontSize: "26px", fontFamily: "OpenSans-Bold" }}>
                  {t("language.part82")}
                </h4>
                <div className="row">
                  <div className="col-md-12 form-floating mb-3 mt-4 text-left">
                    <Field
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder=" "
                      name="label_name"
                    />
                    <label htmlFor="color_name">
                      {t("language.part85")} (in EN)
                    </label>
                    {errors.label_name && touched.label_name && (
                      <small className="text-danger">{errors.label_name}</small>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 form-floating mb-3 mt-4 text-left">
                    <Field
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder=" "
                      name="label_name_nl"
                    />
                    <label htmlFor="color_name">
                      {t("language.part85")} (in NL)
                    </label>
                    {errors.label_name_nl && touched.label_name_nl && (
                      <small className="text-danger">
                        {errors.label_name_nl}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 form-floating mb-3 mt-4 text-left">
                    <Field
                      type="text"
                      className="form-control"
                      name="value"
                      autoComplete="off"
                      placeholder=" "
                      pattern="^[0-9]\d{0,5}(\.\d{0,2})?$"
                      title={t("validation.v100")}
                      onChange={handleDigit}
                    />
                    <label htmlFor="color_name">{t("language.part83")}</label>
                    {errors.value && touched.value && (
                      <small className="text-danger">{errors.value}</small>
                    )}
                  </div>
                </div>
                {error && <small className="text-danger">{error}</small>}
                <div className="row">
                  <div className="col-md-12 form-floating mb-3 mt-4">
                    <button
                      type="submit"
                      id="save"
                      className="save mr-3"
                      style={{ fontSize: "16px" }}
                    >
                      {t("language.part81")}
                    </button>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ padding: "0px" }}></Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default Option;
