import axios from "axios";
import auth from "../helpers/auth";

// creating the global axios intence
const instance = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL__,
  headers: {
    "Content-Type": "application/json",
    // "Accept": "*/*"
    // "language":"en"
  },
});

// modigying the headers
instance.interceptors.request.use(
  (config) => {
    const token = auth.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `${token}`; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// cheching for token expiration
instance.interceptors.response.use(async (res) => {
  const originalConfig = res;
  originalConfig.retry = false;
  console.log(res.data);
  if (res?.data?.status === "out_of_process") {
    auth.resetLoginStore();
  }
  if (
    res.data.status === "UnAuthorized" ||
    ("Unauthorized" && res.data.code === 401 && !originalConfig.retry)
  ) {
    originalConfig.retry = true;
    try {
      const rs = await axios.post(
        `${process.env.REACT_APP_GATEWAY_URL__}/refresh-token`,
        {
          refresh_token: auth.getRefreshToken(),
        }
      );
      if (rs.status == 200 && rs.data.code == 200) {
        auth.setTokens(rs.data.AccessToken);
      } else {
        auth.resetLoginStore();
      }
      return instance(originalConfig.config);
    } catch (_error) {
      return Promise.reject(_error);
    }
  } else {
    return res;
  }
});

export default instance;
