export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const capFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const sendEmail = async (serviceID, templateID, templateParams) => {
  console.log(templateParams);
  let response = window.emailjs.send(serviceID, templateID, templateParams);
  return response;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getStringToDisplay = (
  str,
  lengthToDisplay,
  appendDots = false
) => {
  // get specified length of substring from start index
  // append ... at end if required
  if (!str) return "";
  if (str.length <= lengthToDisplay) {
    return str;
  } else {
    let newString = str.substring(0, lengthToDisplay);
    return appendDots ? newString + " ..." : newString;
  }
};

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

export function getCategory(value) {
  // console.log(v);
  if (!value) {
    return null;
  } else {
    let cat = long_range.filter((i) => i.value === parseInt(value))[0]?.label;
    return cat || value;
  }
}

export const getDateFormat = (date) => {
  if (!date) {
    return null;
  }
  return new Date(date).toLocaleDateString().replaceAll("/", "-");
};

export const getYears = () => {
  var currentYear = new Date().getFullYear(),
    years = [];
  let startYear = new Date().getFullYear() - 10;
  for (let i = currentYear; i >= 1980; i--) {
    years.push(currentYear--);
  }
  return years;
};

export const categories_value = [
  { value: "dwe", label: "1" },
  { value: "dwd", label: "2" },
  { value: "njk", label: "3" },
  { value: "nnlk", label: "4" },
  { value: "ert", label: "5" },
  { value: "mko", label: "6" },
  { value: "ccr", label: "7" },
  { value: "qwe", label: "8" },
];

export const employee_types = [
  { value: "Indirect", label: "Indirect" },
  { value: "Direct", label: "Direct" },
];

export const language_types = [
  { value: "1", label: "English" },
  { value: "2", label: "Dutch" },
  // { value: null, label: "Select" },
];

export const user_role = [
  { value: "User", label: "User" },
  { value: "Admin", label: "Admin" },
  { value: "long_range_user", label: "long_range_user" },
];

export const long_range = [
  { value: 1, label: "1 and 2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5 and 6" },
  { value: 7, label: "7 and 8" },
];

export const active_status = [
  { value: "Active", label: "Active" },
  { value: "Out of process", label: "Out of process" },
];

export const inactive_status = [
  { value: "Inactive", label: "Inactive" },
  { value: "Out of process", label: "Out of process" },
];
