import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import auth from "../../helpers/auth";
import { forgotPassword, LoginUser } from "../../modules/login";
import { useTranslation } from "react-i18next";
import SHIFT from "../../asset/SHIFT-RGB.png";
import Input from "@material-ui/core/Input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import SetNewPassword from "./SetNewPassword";

function ForgotPassword(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  let { forgotPasswordErr } = useSelector((state) => state.authState);
  let { userlng } = useSelector((state) => state.allCarsState);
  const handleChange = (event) => {
    formikRef.current.setFieldValue(event.target.name, event.target.value.trim());
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("validation.v53"))
      .required(t("validation.v52"))
      .max(50, t("validation.v60")),
  });

  const initialState = { email: "" };

  return (
    <Modal
      show={props.show}
      keyboard={false}
      className="login-popup login-modal-white password"
      backdrop="static"
    >
      <div className="position-relative popup-inner">
        <div class="text-center">
          <img src={SHIFT} className="shift-img"></img>
        </div>
        <h3>Wachtwoord vergeten</h3>
        <p>Voer het e-mailadres in waar je mee inlogt bij Shift.</p>

        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await dispatch(forgotPassword(values));
            await resetForm({});
          }}
        >
          {({ errors, values, touched }) => (
            <Form autoComplete="off">
              <div className="invalid-feedback"></div>
              <div className="form-group">
                <label htmlFor="Username">E-mailadres</label>
                <Input
                  autoComplete="off"
                  placeholder="E-mailadres"
                  name="email"
                  id="email"
                  type="Username"
                  className="form-control mb-0"
                  onChange={(e) => handleChange(e)}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </div>
              {forgotPasswordErr.msg_en && forgotPasswordErr.msg_en && (
                <small className="text-danger text-center">
                  {userlng === "de"
                    ? forgotPasswordErr.msg_nl
                    : forgotPasswordErr.msg_en}
                </small>
              )}
              <div className="d-flex flex-column align-items-center mt-2">
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  class={values.email.length ? `login-btn` : `login-opacity`}
                >
                  wachtwoord opnieuw instellen
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="d-flex justify-content-center position-absolute popup-bottom bottom-popup">
          <h3 className="pointer" onClick={props.close}>
            Terug naar Inloggen
          </h3>
        </div>
      </div>
    </Modal>
  );
}

export default ForgotPassword;
