import { DropdownButton, Dropdown } from "react-bootstrap";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Bilingual = (props) => {
  const lngs = {
    en: { nativeName: "English" },
    de: { nativeName: "Dutch" },
  };

  const { t, i18n } = useTranslation();

  const handleLanguagechange = (lng) => {
    i18n.changeLanguage(lng);
    props.sendData(lng);
  };

  useEffect(() => {
    i18n.changeLanguage("de");
    let lng = localStorage.getItem("temp-lng") || null;
    if (lng) {
      i18n.changeLanguage(lng);
    }
    localStorage.removeItem("temp-lng");
  }, []);

  return (
    <Fragment>
      <DropdownButton
        id="dropdown-basic-button"
        title=""
        style={{ position: "absolute", top: "40px", right: "255px" }}
      >
        {Object.keys(lngs).map((lng, i) => (
          <Dropdown.Item
            key={i}
            eventKey={lngs[lng].nativeName}
            onClick={() => handleLanguagechange(lng)}
          >
            {lngs[lng].nativeName}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </Fragment>
  );
};

export default Bilingual;
