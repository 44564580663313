import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SketchPicker } from "react-color";
import Close from "../../asset/close-icon.svg";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as yup from "yup";

const Car = (props) => {
  const formikRef = useRef(null);

  const initialValues = {
    color_name: "",
    color: { r: "52", g: "85", b: "139" },
    hexCode: "#34558b",
  };

  const { t } = useTranslation();
  
  const validationSchema = yup.object({
    color_name: yup
      .string()
      .required(t('validation.v48'))
      .matches(/^[aA-zZ\s]+$/, t('validation.v4'))
      .max(32, t('validation.v49')),
  });

  const onSubmit = (values) => {
    props.fireData(values);
    props.handleClose()
  };

  const handleColorChange = (color) => {
    let color_rgb = { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b };
    formikRef.current.setFieldValue("color", color_rgb);
    formikRef.current.setFieldValue("hexCode", color.hex);
  };


  return (
    <Modal
      className="modal3"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <div
        className="d-flex justify-content-end"
        style={{ padding: "21px 21px 0" }}
      >
        <img
          src={Close}
          onClick={props.handleClose}
          style={{ cursor: "pointer" }}
          alt=""
        ></img>
      </div>
      <div className="popup-content text-center">
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Modal.Body>
                <h4 style={{ fontSize: "28px" }}>{t('language.part78')}</h4>
                <p style={{ fontSize: "12px" }}>{t('language.part3')}</p>
                <SketchPicker
                  color={values.color}
                  onChange={handleColorChange}
                  presetColors={[]}
                />
                <div className="row ">
                  <div className="col-md-12 form-floating mb-3 mt-4 text-left">
                    <Field
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      name="color_name"
                      placeholder=" "
                    />
                    <label htmlFor="color_name">{t('language.part79')}</label>
                    {errors.color_name && touched.color_name && (
                      <small className="text-danger">{errors.color_name}</small>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 form-floating mb-3 mt-4">
                    <button type="submit" id="save" className="save mr-3">
                    {t('language.part78')}
                    </button>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ padding: "0px" }}></Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default Car;