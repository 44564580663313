import React from "react";

import Banner from '../asset/banner-tint.png'
import Mail from '../asset/mail-icon.svg'
import Phone from '../asset/phone-icon.svg'
const ContactUs = (props) => {
  return (
    <div>
      <div className="position-relative page-banner contact-banner">
        <img src={Banner}></img>
        <div className="position-absolute banner-content">
          <div>
            <h2>Heb je vragen, tips of ideeën laat het ons weten.</h2>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="row no-gutters contact-section">
          <div className="col-md-4 offset-md-1 d-flex contact-card">
            <img src={Mail}></img>
            <div className="d-flex flex-column">
              <h3>Mail ons op:</h3>
              <h5>rgn.wagenparkbeheer@fleetsupport.com</h5>
            </div>
          </div>
          <div className="col-md-5 offset-md-2 d-flex contact-card">
            <img src={Phone}></img>
            <div className="d-flex flex-column">
              <h3>Of bel ons op:</h3>
              <h5>+31 026- 7511 462</h5>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
};

export default ContactUs;