import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import icon from "../asset/icon.svg";
import icon1 from "../asset/icon1.svg";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../modules/myusers";
import RemoveUser from "./modals/RemoveUser";
import { param } from "jquery";
import NotFound from "./NotFound";
import {getDateFormat}from "../utils/commonUtils"
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const User = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  let { myUserDetails, myUserDetailSpinner } = useSelector(
    (state) => state.myUserState
  );

  const [removeModal, setRemoveModal] = useState(false);
  const [removableUser, setRemovabelUser] = useState(false);

  useEffect(async () => {
    await dispatch(fetchUser(params.id));
  }, []);

  const getCreatedDate = (date) => {
    if (!date) {
      return "NA";
    }
    let val = new Date(date);
    return (
      monthNames[val.getMonth()] +
      " " +
      val.getUTCDate() +
      ", " +
      val.getUTCFullYear()
    );
  };

  if (myUserDetailSpinner) {
    return (
      <div class="text-center myuser-spinner">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (!myUserDetails?.id) {
    return <NotFound />;
  }
  return (
    <>
      <div className="myuser_main myuser_main1">
        <RemoveUser
          show={removeModal}
          handleClose={() => setRemoveModal(false)}
          data={removableUser}
        />
        <div className="active_user">
          <button
            type="button"
            className="active_btn"
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={icon} className="pr-2 arrow-icon"></img>
            {t("language.part1")}
          </button>
          <div className="active_user1">
            <button
              type="button"
              className="active_btn1"
              onClick={() =>
                history.push({
                  pathname: "/my-user/user/edit",
                  state: { data: myUserDetails },
                })
              }
            >
              {t("language.part75")}
            </button>
            <button
              type="button"
              className="active_btn1 active_btn2"
              onClick={() => {
                setRemoveModal(true);
                setRemovabelUser(myUserDetails);
              }}
            >
              {t("language.part80")}
            </button>
          </div>
        </div>

        <div className="table_main">
          <div className="">
            <div className="row activeuser">
              <div className="col-3">
                <div className="activeuser1">
                  <span> {t("language.part159")}</span>
                  <p style={{ marginBottom: "0px" }}>
                    {myUserDetails?.full_name || "NA"}
                  </p>
                </div>
              </div>
              <div className="col-3">
                <div className="activeuser1">
                  <span>Email address</span>
                  <p style={{ marginBottom: "0px" }}>{myUserDetails?.email}</p>
                </div>
              </div>
              <div className="col-3">
                <div className="activeuser1">
                  <span>{t("language.part161")}</span>
                  <p style={{ marginBottom: "0px" }}>
                    {myUserDetails?.entity || "NA"}
                  </p>
                </div>
              </div>
              <div className="col-3">
                <div className="activeuser1">
                  <span>{t("language.part155")}</span>
                  <p style={{ marginBottom: "0px" }}>
                    {myUserDetails?.status}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row activeuser">
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part149")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.employee_type
                    ? myUserDetails?.employee_type
                    : "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part153")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.agreement=='NA' ? 'NA': t(`language.${myUserDetails?.agreement}`)}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part154")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {getCreatedDate(myUserDetails?.created_at)}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part158")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.first_name || "NA"}
                </p>
              </div>
            </div>
          </div>
          <div className="row activeuser">
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part182")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.last_name || "NA"}
                </p>
              </div>
            </div>

            <div className="col-3">
              <div className="activeuser1">
                <span>Category</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.user_config[0]?.category || "NA"}{" "}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part157")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.task_number || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part162")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.business_unit || "NA"}
                </p>
              </div>
            </div>
          </div>
          <div className="row activeuser">
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part180")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.file || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part160")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.iservice_header || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part57")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.personal_number || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part166")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.address || "NA"}
                </p>
              </div>
            </div>
          </div>
          <div className="row activeuser">
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part163")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.cost_center || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part164")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {(myUserDetails?.file_closed_date) || "NA"}
                </p>
              </div>
            </div>

            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part167")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.postcode || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part184")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.city || "NA"}
                </p>
              </div>
            </div>
            <div className="row activeuser"></div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part169")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.telephone || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part170")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.license_plate_current_lease_car || "NA"}{" "}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part171")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.lease_category_current || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part172")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.lease_category_new || "NA"}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row activeuser">
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part173")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {" "}
                  {myUserDetails?.year_mileage || "NA"}{" "}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>Part time %</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.part_time_percentage || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part174")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {getDateFormat(myUserDetails?.end_date_lease_contract) || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part175")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.reason_replacement || "NA"}{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row activeuser">
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part176")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.license_plate_type || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part177")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.fuel_type || "NA"}{" "}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part178")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.email_id_manager || "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="activeuser1">
                <span>{t("language.part179")}</span>
                <p style={{ marginBottom: "0px" }}>
                  {getDateFormat(myUserDetails?.approval_date) || "NA"}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row activeuser">
            <div className="col-3">
              <div className="activeuser1">
                <span>Language</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.preference == 1
                    ? "English"
                    : myUserDetails.preference == 2
                    ? "Dutch"
                    : "NA"}
                </p>
              </div>
            </div>
            <div className="col-3">
              {myUserDetails?.user_config && (
                <div className="activeuser1">
                  <span>Role</span>
                  <p style={{ marginBottom: "0px" }}>
                    {myUserDetails?.user_config[0]?.role || "NA"}
                  </p>
                </div>
              )}
            </div>
            <div className="col-3">
              {myUserDetails?.user_config && (
                <div className="activeuser1">
                  <span>Long range user</span>
                  <p style={{ marginBottom: "0px" }}>
                    {myUserDetails?.user_config[0]?.role === "long_range_user"
                      ? "TRUE"
                      : "FALSE"}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="row activeuser">
            {/* <div className="col-3">
              <div className="activeuser1">
                <span>Category</span>
                <p style={{ marginBottom: "0px" }}>
                  {myUserDetails?.category || "NA"}{" "}
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
