import { combineReducers } from "redux";
import loginReducer from "../modules/login";
import allCarsReducer from "../modules/allCars"
import myCarsReducer from "../modules/mycars";
import myUsersReducer from "../modules/myusers";
import dashboardReducer from "../modules/dashboard"

// combining all the individual reducers to the root reducers
const rootReducer =(history)=> combineReducers({
  authState: loginReducer,
  allCarsState: allCarsReducer,
  myCarState: myCarsReducer,
  myUserState: myUsersReducer,
  dashboardState: dashboardReducer
});

export default rootReducer;