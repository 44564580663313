import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";

import RemoveIcon from "../../asset/close-icon.svg";
import { removeCar } from "../../modules/mycars";
import { removeUser } from "../../modules/myusers";

const RemoveUser = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const keepUser = () => {
    props.handleClose();
  };

  const { t, i18n } = useTranslation();

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <div
        className="d-flex justify-content-end"
        style={{ padding: "21px 21px 0" }}
      >
        <img
          src={RemoveIcon}
          onClick={props.handleClose}
          style={{ cursor: "pointer" }}
          alt=""
        ></img>
      </div>
      <div className="popup-content text-center">
        <h2 className="popup-title">{t("language.part80")}</h2>
        <p>{t("language.part86a")}</p>

        <div className="d-flex justify-content-center mt-5 confirmation">
          <button
            onClick={() => {
              props.handleClose();
              dispatch(removeUser(props.data.id, history));
            }}
            className="button-orange"
          >
            {t("language.part54")}
          </button>
          <button onClick={keepUser} className="ml-4 button-blue">
            {t("language.part55")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveUser;