import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./styles/index.css";
import App from "./App";
import history from "./history";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./components/i18n";
import { Provider } from "react-redux";
import store from "./Store/store";


// redering the whole App wrapped in a redux store provider into the root div
ReactDOM.render(
  <Provider store={store}>
    <Router basename="/" history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
