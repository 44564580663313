import React, { useState, useEffect } from "react";
import { Dropdown, Navbar, Nav } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Bilingual from "./Bilingual";
import { useTranslation, Trans } from "react-i18next";
import ForgotPassword from "./modals/ForgotPassword";
import OnBoardModal from "./modals/OnBoardModal";
import {
  ROOT_PATH,
  FAQ_PATH,
  CONTACT_PATH,
  ABOUT_US_PATH,
  MY_CARS_PATH,
  MY_USERS,
  MY_DASHBOARD_PATH,
} from "../constants/routes";

import Login from "./modals/login";
import { Toast } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

import SHIFT from "../asset/SHIFT-RGB.png";
import Phone from "../asset/phone.png";
import Mail from "../asset/mail.png";
import Nl from "../asset/nl.png";
import UserIcon from "../asset/userIcon.png";
import Home from "../asset/home.png";
import En from "../asset/en.png";
import Changepassword from "./modals/Changepassword";
import auth from "../helpers/auth";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoginModal,
  setChangePwdModal,
  getFiltersOption,
  fetchStandardCar,
} from "../modules/allCars";
import { setLng } from "../modules/allCars";
import { fetchMyStdCars } from "../modules/mycars";
import { getUserDetails, setOnBoardErrModal } from "../modules/login";
import onBoardModal from "./modals/OnBoardModal";

const Header = (props) => {
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const username = auth.getUsername() || "";
  const [forgotPassword, setForgotPassword] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const Language = useSelector((state) => state.allCarsState.userlng);
  const { user_data, onBoardErrModal } = useSelector(
    (state) => state.authState
  );
  const { loginModal, changePwdModal, alertMessage, nl_message } = useSelector(
    (state) => state.allCarsState
  );

  const showFAQ = () => {
    window.location.href = process.env.REACT_APP_FAQ_PAGE__;
  };

  const showContact = () => {
    history.push({
      pathname: CONTACT_PATH,
    });
  };

  const showAbout = () => {
    history.push({
      pathname: ABOUT_US_PATH,
    });
  };

  const showMyCars = () => {
    history.push(MY_CARS_PATH);
    // props.handleClose()
  };

  const showMyUsers = () => {
    history.push({ pathname: MY_USERS, search: "?page=1" });
  };

  const { t } = useTranslation();

  const handleLogOut = () => {
    auth.resetLoginStore();
  };

  const flagChange = (lng) => {
    dispatch(setLng(lng));
  };

  const gotoDashboard = () => {
    history.push({ pathname: MY_DASHBOARD_PATH, search: "" });
  };

  useEffect(() => {
    let val = localStorage.getItem("i18nextLng").slice(0, 2);
    dispatch(setLng(val));
  }, [Language]);

  useEffect(async () => {
    if (auth.isAuthenticated) {
      dispatch(getUserDetails());
    }
    dispatch(getFiltersOption({ brand: [] }));
    if (!auth.isAuthenticated && location.pathname == "/forgot-password") {
      history.push(location.pathname + location.search);
    } else if (!auth.isAuthenticated) {
      await dispatch(setLoginModal(true));
    }
  }, []);

  return (
    <>
      <nav className="d-flex flex-column main-container main-head">
        <div className="d-flex  pt-3 main" style={{ position: "relative" }}>
          <div className="d-flex align-items-center logo-head">
            <div className="phone-head">
              <img
                src={Phone}
                // src="../asset/phone.png"
                alt=""
              ></img>
              <span className="ml-2">026 – 7511 462</span>
            </div>
            <div className=" email-head ">
              <img src={Mail} alt=""></img>

              <span className="ml-2">rgn.wagenparkbeheer@fleetsupport.com</span>
            </div>
            <div className="flag-head d-flex">
              <span>
                <img
                  src={Language === "en" ? En : Language === "en-US" ? En : Nl}
                  alt=""
                ></img>{" "}
                {user_data && <Bilingual sendData={flagChange} />}
              </span>
            </div>
          </div>

          <div className=" pl-3 dropdown drop">
            <Dropdown
              onClick={
                auth.isAuthenticated ? () => setShowDropdown(true) : null
              }
            >
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className="header-user"
              >
                <div
                  className="user"
                  style={{
                    paddingTop: "10px",
                    width: "90px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {!auth.isAuthenticated
                    ? "User"
                    : user_data.full_name
                    ? user_data.full_name.split(",").slice(0, 8)
                    : username
                    ? username.split(",")[0]
                    : ""}
                </div>
                <div>
                  <img src={UserIcon} alt=""></img>
                </div>
              </Dropdown.Toggle>
              {showDropdown && (
                <Dropdown.Menu
                  align="left"
                  className="custom-loggedin-dropdown"
                >
                  <div className="user-welcome">
                    {t("language.part130")}{" "}
                    {user_data?.full_name?.split(",")[0] ||
                      username?.split(",")[0]}
                  </div>
                  {auth.getUserType() === "Admin" && (
                    <>
                      <Dropdown.Item onClick={showMyCars}>
                        {t("language.part145")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={showMyUsers}>
                        {t("language.part144")}
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={gotoDashboard}>
                        {t("dashboard.my")} Dashboard
                      </Dropdown.Item> */}
                    </>
                  )}
                  <Dropdown.Item
                    onClick={() => dispatch(setChangePwdModal(true))}
                  >
                    {t("language.part1011")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogOut}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              )}
            </Dropdown>
          </div>
        </div>
        <Navbar>
          <Navbar.Brand
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: `${ROOT_PATH}`,
              })
            }
          >
            <img
              src={SHIFT}
              alt=""
              style={{ width: "200px", height: "65px" }}
            ></img>
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>
              <img
                src={Home}
                onClick={() => {
                  history.push({
                    pathname: `${ROOT_PATH}`,
                  });
                }}
                alt=""
              ></img>
            </Nav.Link>
            <Nav.Link onClick={showAbout}>{t("language.part47")}</Nav.Link>
            <Nav.Link onClick={showFAQ}>FAQ</Nav.Link>
            <Nav.Link onClick={showContact}>Contact</Nav.Link>
          </Nav>
        </Navbar>
      </nav>
      {alertMessage.message && Language === "en" && (
        <Toast
          className={`toast ${alertMessage.status}-toast`}
          // onClose={}
          show={true}
          delay={3000}
          autohide
        >
          <Toast.Body>{alertMessage.message}</Toast.Body>
        </Toast>
      )}
      {nl_message.message && Language === "de" && (
        <Toast
          className={`toast ${nl_message.status}-toast`}
          // onClose={}
          show={true}
          delay={3000}
          autohide
        >
          <Toast.Body>{nl_message.message}</Toast.Body>
        </Toast>
      )}
      {loginModal && (
        <Login
          show={loginModal}
          handleClose={() => dispatch(setLoginModal(false))}
          showForgotPassword={() => {
            dispatch(setLoginModal(false));
            setForgotPassword(true);
          }}
        />
      )}
      {changePwdModal && (
        <Changepassword
          openLogin={() => {
            dispatch(setChangePwdModal(false));
          }}
          show={changePwdModal}
          handleClose={() => dispatch(setChangePwdModal(false))}
        />
      )}
      {forgotPassword && (
        <ForgotPassword
          show={forgotPassword}
          close={() => {
            setForgotPassword(false);
            dispatch(setLoginModal(true));
          }}
        />
      )}
      {onBoardErrModal && (
        <OnBoardModal
          show={onBoardErrModal}
          close={() => {
            dispatch(setOnBoardErrModal(false));
          }}
        />
      )}
    </>
  );
};

export default Header;
